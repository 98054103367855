import {
  all,
  takeEvery,
  call,
  fork,
  put,
  takeLatest,
} from 'redux-saga/effects';
import {
  getRewardsSuccess,
  getRewardByIdSuccess,
  createRewardSuccess,
  updateRewardSuccess,
  deleteRewardSuccess,
  hideLoaderGetRewards,
  hideLoaderGetRewardById,
  hideLoaderCreateReward,
  hideLoaderUpdateReward,
  hideLoaderDeleteReward,
  showMessageReward,
} from 'actions/Reward';
import {
  GET_REWARDS_REQUEST,
  GET_REWARD_BY_ID_REQUEST,
  CREATE_REWARD_REQUEST,
  UPDATE_REWARD_REQUEST,
  DELETE_REWARD_REQUEST,
} from 'constants/ActionTypes';
import moment from 'moment-timezone';
import { rsf, firebase } from '../firebase/firebase';
// import { rsf, db } from '../firebase/firebase';
// import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";


function* getReward() {
  try {
    const colRef = firebase.firestore().collection('RewardsThailand');
    // const colRef = query(collection(db, 'RewardsThailand'));
    const snapshot = yield call(rsf.firestore.getCollection, colRef);
    //colRef.where('active', '==', true);
    //console.log('items:' + snapshot);
    let items = [];
    snapshot.forEach((doc) => {
      //console.log(doc.data());
      var data = doc.data();
      data['id'] = data.rewardId;
      //console.log('T:' + data.friends.length);
      items.push(data);

      // datas = {
      //   id: doc.id,
      //   ...doc.data()
      // };
    });
    items.sort((a, b) => {
      return a.order > b.order ? 1 : -1; //ascending
      //return a.order > b.order ? -1 : 1; //descending
    });
    console.log('To:' + items.length);
    yield put(getRewardsSuccess(items));
  } catch (error) {
    console.log(error);
    yield put(hideLoaderGetRewards());
  }
}

function* getRewardById({ payload }) {
  const { rewardid } = payload;
  //console.log('get rewardId:' + rewardid);
  try {
    const colRef = firebase.firestore().collection('RewardsThailand');
    // const colRef = query(collection(db, 'RewardsThailand'));
    const snapshot = yield call(
      rsf.firestore.getCollection,
      colRef.where('rewardId', '==', rewardid)
    );
    //colRef.where('active', '==', true);
    //console.log('items:' + snapshot);
    let items = [];
    snapshot.forEach((doc) => {
      //console.log(doc.data());
      var data = doc.data();
      data['id'] = data.rewardId;
      //console.log('T:' + data.friends.length);
      items.push(data);

      // datas = {
      //   id: doc.id,
      //   ...doc.data()
      // };
    });
    var item = null;
    if (items.length > 0) {
      item = items[0];
    }
    console.log('To:' + items.length);
    yield put(getRewardByIdSuccess(item));
  } catch (error) {
    console.log(error);
    yield put(hideLoaderGetRewardById());
  }
}

function* createReward({ payload }) {
  const {
    imgSrc,
    order,
    partNumber,
    titleEn,
    titleTh,
    detailEn,
    detailTh,
    points,
    pointsPerBaht,
    isActive,
  } = payload;
  //console.log('imgSrc:' + imgSrc);

  var currentDateTime = getCurrentDateTime();

  var path =
    'RewardPhotoThailand/' +
    partNumber.toLowerCase() +
    '-' +
    currentDateTime +
    '.png';
  var metadata = {
    contentType: 'image/png',
  };
  if (imgSrc.indexOf('image/png') !== -1) {
    console.log('png file');
    path =
      'RewardPhotoThailand/' +
      partNumber.toLowerCase() +
      '-' +
      currentDateTime +
      '.png';
    metadata = {
      contentType: 'image/png',
    };
  }
  if (imgSrc.indexOf('image/jpg') !== -1) {
    console.log('jpg file');
    path =
      'RewardPhotoThailand/' +
      partNumber.toLowerCase() +
      '-' +
      currentDateTime +
      '.jpg';
    metadata = {
      contentType: 'image/jpeg',
    };
  }
  if (imgSrc.indexOf('image/jpeg') !== -1) {
    console.log('jpeg file');
    path =
      'RewardPhotoThailand/' +
      partNumber.toLowerCase() +
      '-' +
      currentDateTime +
      '.jpg';
    metadata = {
      contentType: 'image/jpeg',
    };
  }
  //console.log('path:' + path);
  try {
    //const task = rsf.storage.uploadFile(path, imgSrc);  // This one for blob or file
    const task = rsf.storage.uploadString(path, imgSrc, 'data_url', metadata);
    yield task;
    const url = yield call(rsf.storage.getDownloadURL, path);
    console.log('url:' + url);

    const colRef = firebase.firestore().collection('RewardsThailand');
    // const colRef = query(collection(db, 'RewardsThailand'));
    const rewardId = colRef.doc().id;
    const doc = yield call(
      rsf.firestore.setDocument,
      'RewardsThailand/' + rewardId,
      {
        rewardId: rewardId,
        order: Number(order),
        partNumber: partNumber,
        titleEn: titleEn,
        titleTh: titleTh,
        detailEn: detailEn,
        detailTh: detailTh,
        points: Number(points),
        pointsPerBaht: Number(pointsPerBaht),
        isActive: isActive,
        photo: url,
        createdAt: currentDateTime,
        updatedAt: currentDateTime,
      }
    );
    //console.log('docId:' + doc.id);  //Fro add
    yield put(createRewardSuccess(rewardId));
    yield put(showMessageReward('Success'));
    //yield put(hideLoaderCreateReward());
  } catch (error) {
    console.log(error);
    yield put(hideLoaderCreateReward());
  }
}

function* updateReward({ payload }) {
  const {
    rewardid,
    imgSrc,
    order,
    partNumber,
    titleEn,
    titleTh,
    detailEn,
    detailTh,
    points,
    pointsPerBaht,
    isActive,
  } = payload;
  // console.log('update id:' + rewardid);
  // console.log('order:', order);
  // console.log('partNumber:' + partNumber);
  // console.log('detailEn:', detailEn);
  // console.log('detailTh:', detailTh);

  var currentDateTime = getCurrentDateTime();

  if (imgSrc !== null) {
    var path =
      'RewardPhotoThailand/' +
      partNumber.toLowerCase() +
      '-' +
      currentDateTime +
      '.png';
    var metadata = {
      contentType: 'image/png',
    };
    if (imgSrc.indexOf('image/png') !== -1) {
      console.log('png file');
      path =
        'RewardPhotoThailand/' +
        partNumber.toLowerCase() +
        '-' +
        currentDateTime +
        '.png';
      metadata = {
        contentType: 'image/png',
      };
    }
    if (imgSrc.indexOf('image/jpg') !== -1) {
      console.log('jpg file');
      path =
        'RewardPhotoThailand/' +
        partNumber.toLowerCase() +
        '-' +
        currentDateTime +
        '.jpg';
      metadata = {
        contentType: 'image/jpeg',
      };
    }
    if (imgSrc.indexOf('image/jpeg') !== -1) {
      console.log('jpeg file');
      path =
        'RewardPhotoThailand/' +
        partNumber.toLowerCase() +
        '-' +
        currentDateTime +
        '.jpg';
      metadata = {
        contentType: 'image/jpeg',
      };
    }
    console.log('path:' + path);
    try {
      //const task = rsf.storage.uploadFile(path, imgSrc);  // This one for blob or file
      const task = rsf.storage.uploadString(path, imgSrc, 'data_url', metadata);
      yield task;
      const url = yield call(rsf.storage.getDownloadURL, path);
      console.log('url:' + url);

      //const colRef = firebase.firestore().collection('RewardsThailand');
      const updateData = {
        order: Number(order),
        partNumber: partNumber,
        titleEn: titleEn,
        titleTh: titleTh,
        detailEn: detailEn,
        detailTh: detailTh,
        points: Number(points),
        pointsPerBaht: Number(pointsPerBaht),
        isActive: isActive,
        photo: url,
        updatedAt: currentDateTime,
      };
      yield call(
        rsf.firestore.updateDocument,
        'RewardsThailand/' + rewardid,
        updateData
      );

      yield put(updateRewardSuccess());
      yield put(showMessageReward('Success'));
    } catch (error) {
      console.log(error);
      yield put(hideLoaderUpdateReward());
    }
  } else {
    try {
      //var currentDateTime = getCurrentDateTime();
      //const colRef = firebase.firestore().collection('RewardsThailand');
      const updateData = {
        order: Number(order),
        partNumber: partNumber,
        titleEn: titleEn,
        titleTh: titleTh,
        detailEn: detailEn,
        detailTh: detailTh,
        points: Number(points),
        pointsPerBaht: Number(pointsPerBaht),
        isActive: isActive,
        updatedAt: currentDateTime,
      };
      yield call(
        rsf.firestore.updateDocument,
        'RewardsThailand/' + rewardid,
        updateData
      );

      yield put(updateRewardSuccess());
      yield put(showMessageReward('Success'));
    } catch (error) {
      console.log(error);
      yield put(hideLoaderUpdateReward());
    }
  }
}

function* deleteReward({ payload }) {
  const { rewardid, deletePath } = payload;
  //console.log('delete id:' + rewardid);
  //console.log('deletePath:' + deletePath);
  var path = deletePath.replace(
    'https://firebasestorage.googleapis.com/v0/b/hello-suzuki.appspot.com/o/',
    ''
  );
  path = path.replace('%2F', '/');
  path = path.substring(0, path.indexOf('?'));
  //console.log('path:' + path);

  try {
    yield call(rsf.firestore.deleteDocument, 'RewardsThailand/' + rewardid);
    //yield call(rsf.storage.deleteFile, path);  // Delete Photo
    yield put(deleteRewardSuccess());
  } catch (error) {
    console.log(error);
    yield put(hideLoaderDeleteReward());
  }
}

function getCurrentDateTime() {
  let dd = moment()
    .tz('Asia/Bangkok')
    .format('YYYYMMDD HH:mm:ss'); //LTS
  //console.log('time1:' + dd);
  let ee = dd.replace(/:/g, '');
  //console.log('time2:' + ee);
  ee = ee.replace(/ /g, '');
  let str = ee.trim();
  //console.log('final:' + str);
  return str;
}

export function* watchGetRewardsRequest() {
  yield takeEvery(GET_REWARDS_REQUEST, getReward);
}
export function* watchGetRewardByIdRequest() {
  yield takeEvery(GET_REWARD_BY_ID_REQUEST, getRewardById);
}
export function* watchCreateRewardRequest() {
  yield takeLatest(CREATE_REWARD_REQUEST, createReward);
}
export function* watchUpdateRewardRequest() {
  yield takeLatest(UPDATE_REWARD_REQUEST, updateReward);
}
export function* watchDeleteRewardRequest() {
  yield takeEvery(DELETE_REWARD_REQUEST, deleteReward);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetRewardsRequest),
    fork(watchGetRewardByIdRequest),
    fork(watchCreateRewardRequest),
    fork(watchUpdateRewardRequest),
    fork(watchDeleteRewardRequest),
  ]);
}

/*
var metadata = {
  contentType: 'image/png',
  metadata: {
    firebaseStorageDownloadTokens: '9d225721-aa4f-473c-a3c9-25d33384a9ec'
  }
};
*/
