import React, { useState, useEffect } from "react";
import ContainerHeader from "components/ContainerHeader/index";
import IntlMessages from "util/IntlMessages";
import { connect } from "react-redux";
import {
  getUserByVinRequest,
  showLoaderUserByVin,
  getCarsByUserRequest,
  showLoaderCarsByUser,
  resetSearchUserItems,
} from "actions/SearchUser";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CardBox from "components/CardBox";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";
import { Card, CardBody, CardText } from "reactstrap";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import { Redirect } from "react-router-dom";

class SearchUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vinNumber: "",
      userId: "",
      lastPointExpiredDate: "",
    };

    // useEffect(() => {
    //   console.log('useEffect Call');
    //   if (this.props.userByVinItem.objectId) {
    //     console.log('getCars Call');
    //     this.props.showLoaderCarsByUser();
    //     this.props.getCarsByUserRequest( this.props.userByVinItem.objectId );
    //   }
    // }, [this.props.userByVinItem]);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.clearPress = this.clearPress.bind(this);
    //this.toThaiDateString = this.toThaiDateString(this);
    //this.toEnglishDateString = this.toEnglishDateString(this);
    this.baseState = this.state;
  }

  componentWillReceiveProps(nextProps) {
    //console.log('componentWillReceiveProps CALL');
    if (
      nextProps.userByVinItem !== undefined &&
      nextProps.userByVinItem !== null
    ) {
      //console.log('uuu:' + nextProps.userByVinItem.objectId);
      let lastPointExpiredDateStr = "";
      if (nextProps.userByVinItem.lastPointExpiredDate !== undefined) {
        lastPointExpiredDateStr = nextProps.userByVinItem.lastPointExpiredDate.substring(
          0,
          8
        );
      } else {
        lastPointExpiredDateStr = nextProps.userByVinItem.createdAt.substring(
          0,
          8
        );
      }
      this.setState({
        userId: nextProps.userByVinItem.objectId,
        lastPointExpiredDate: lastPointExpiredDateStr,
      });

      if (
        this.props.carItemsByUser !== undefined &&
        this.props.carItemsByUser !== null
      ) {
        if (this.props.carItemsByUser.length <= 0) {
          //console.log('Get Cars CALL');
          //console.log('next:' + nextProps.carItemsByUser.length);
          this.props.showLoaderCarsByUser();
          this.props.getCarsByUserRequest(nextProps.userByVinItem.objectId);
        }
      }

      // console.log('c:' + this.props.carItemsByUser);
      // if (this.props.carItemsByUser === null) {
      //   console.log('Get Cars CALL');
      //   this.props.showLoaderCarsByUser();
      //   this.props.getCarsByUserRequest( nextProps.userByVinItem.objectId );
      // }
    }
    // if (nextProps.userSecondHandCarItem !== this.props.userSecondHandCarItem) {
    //   //var vinNumber = this.state.vinNumber;

    //   if (nextProps.userSecondHandCarItem !== undefined && nextProps.userSecondHandCarItem !== null) {

    //     if (nextProps.userSecondHandCarItem.length === 1) {
    //       //this.setState({ userId: u.objectId });
    //       nextProps.userSecondHandCarItem.map(u => {
    //         //console.log('userId2:' + u.objectId);
    //         //onsold.log('uChassis:' + u.chassisNumber)
    //         this.setState({ userId: u.objectId });
    //       });
    //     }
    //   }
    // }
  }

  //When change language and want state to prolong
  componentDidMount() {
    const { vinNumber, userId, lastPointExpiredDate } = this.state;
    const {
      loaderUserByVin,
      userByVinItem,
      loaderCarsByUser,
      carItemsByUser,
    } = this.props;

    //console.log('componentDidMount CALL:' + lastPointExpiredDate);

    if (userByVinItem !== undefined && userByVinItem !== null) {
      //console.log('userByVinItem not null');
      let lastPointExpiredDateStr = "";
      if (userByVinItem.lastPointExpiredDate !== undefined) {
        lastPointExpiredDateStr = userByVinItem.lastPointExpiredDate.substring(
          0,
          8
        );
      } else {
        lastPointExpiredDateStr = userByVinItem.createdAt.substring(0, 8);
      }
      this.setState({
        userId: userByVinItem.objectId,
        lastPointExpiredDate: lastPointExpiredDateStr,
      });

      this.props.showLoaderCarsByUser();
      this.props.getCarsByUserRequest(userByVinItem.objectId);
    }

    if (carItemsByUser !== undefined && carItemsByUser !== null) {
      //console.log('carItemsByUser not null');
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    e.target.reset();
  }

  dateToString = (dateData) => {
    let year = dateData.substring(0, 4);
    let month = dateData.substring(4, 6);
    let day = dateData.substring(6, 8);
    let hr = dateData.substring(8, 10);
    let mi = dateData.substring(10, 12);
    let sec = dateData.substring(12, 14);
    return day + "/" + month + "/" + year + " " + hr + ":" + mi + ":" + sec;
  };

  numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  toThaiDateString = (date) => {
    //console.log('dat:' + date);
    let monthNames = [
      "มกราคม",
      "กุมภาพันธ์",
      "มีนาคม",
      "เมษายน",
      "พฤษภาคม",
      "มิถุนายน",
      "กรกฎาคม",
      "สิงหาคม",
      "กันยายน",
      "ตุลาคม",
      "พฤศจิกายน",
      "ธันวาคม",
    ];

    let year = date.getFullYear() + 543;
    let month = monthNames[date.getMonth()];
    let numOfDay = date.getDate();

    return `${numOfDay} ${month} ${year} `;
  };

  toEnglishDateString = (date) => {
    let monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    let year = date.getFullYear();
    let month = monthNames[date.getMonth()];
    let numOfDay = date.getDate();

    return `${numOfDay} ${month} ${year} `;
  };

  // useEffect = (() => {
  //   console.log('useEffect Call');
  //   if (this.props.userByVinItem.objectId) {
  //     console.log('getCars Call');
  //     this.props.showLoaderCarsByUser();
  //     this.props.getCarsByUserRequest( this.props.userByVinItem.objectId );
  //   }
  // }, [this.props.userByVinItem]);

  //   componentDidUpdate() {
  //     //const { navigate,goBack } = this.props.navigation;

  //     // if (this.props.isMessageSecondHandCar) {
  //     //   this.displayMessage(this.props.secondHandCarCode);
  //     // }
  //   }

  clearPress() {
    //e.preventDefault();
    document.getElementById("search_user").reset();
    this.setState({
      vinNumber: "",
      userId: "",
      lastPointExpiredDate: "",
    });

    //this.setState(this.baseState);
    this.props.resetSearchUserItems();
  }

  //ServiceReminder
  //SaleSurvey

  showUser = (userItem) => {
    if (userItem !== undefined && userItem !== null) {
      // if (this.props.carItemsByUser === null) {
      //   console.log('YES');
      //   this.props.showLoaderCarsByUser();
      //   this.props.getCarsByUserRequest( userItem.objectId );
      // } else {
      //   console.log('NO');
      // }

      //console.log('userCount:' + userItem.length);
      let phoneNumber = "0xx-xxx-xx" + userItem.phone.substring(8, 10);
      let registerDateStr =
        userItem.createdAt.substring(0, 4) +
        "/" +
        userItem.createdAt.substring(4, 6) +
        "/" +
        userItem.createdAt.substring(6, 8);
      //console.log('da:' + registerDateStr);
      let registerDate = new Date(registerDateStr);
      let registerDateDisplay = "";

      let lastPointExpiredDateStr = "";
      if (userItem.lastPointExpiredDate !== undefined) {
        lastPointExpiredDateStr =
          userItem.lastPointExpiredDate.substring(0, 4) +
          "/" +
          userItem.lastPointExpiredDate.substring(4, 6) +
          "/" +
          userItem.lastPointExpiredDate.substring(6, 8);
      } else {
        lastPointExpiredDateStr =
          userItem.createdAt.substring(0, 4) +
          "/" +
          userItem.createdAt.substring(4, 6) +
          "/" +
          userItem.createdAt.substring(6, 8);
      }
      let lastPointExpiredDate = new Date(lastPointExpiredDateStr);
      let lastPointExpiredDateDisplay = "";

      let nextPointExpiredDateStr = "";
      if (userItem.nextPointExpiredDate !== undefined) {
        nextPointExpiredDateStr =
          userItem.nextPointExpiredDate.substring(0, 4) +
          "/" +
          userItem.nextPointExpiredDate.substring(4, 6) +
          "/" +
          userItem.nextPointExpiredDate.substring(6, 8);
      } else {
        nextPointExpiredDateStr =
          (parseInt(userItem.createdAt.substring(0, 4), 0) + 2).toString() +
          "/" +
          userItem.createdAt.substring(4, 6) +
          "/" +
          userItem.createdAt.substring(6, 8);
      }
      let nextPointExpiredDate = new Date(nextPointExpiredDateStr);
      let nextPointExpiredDateDisplay = "";

      let lastOnlineDateStr =
        userItem.lastOnline.substring(0, 4) +
        "/" +
        userItem.lastOnline.substring(4, 6) +
        "/" +
        userItem.lastOnline.substring(6, 8);
      //console.log('last:' + lastOnlineDateStr);
      let lastOnlineDate = new Date(lastOnlineDateStr);
      let lastOnlineDateDisplay = "";
      let defaultDealer = "";

      if (this.props.locale.locale === "th") {
        registerDateDisplay = this.toThaiDateString(registerDate);
        lastPointExpiredDateDisplay = this.toThaiDateString(
          lastPointExpiredDate
        );
        nextPointExpiredDateDisplay = this.toThaiDateString(
          nextPointExpiredDate
        );
        lastOnlineDateDisplay = this.toThaiDateString(lastOnlineDate);
        defaultDealer = userItem.dealerCode + " " + userItem.dealerNameTh;
      } else {
        registerDateDisplay = this.toEnglishDateString(registerDate);
        lastPointExpiredDateDisplay = this.toEnglishDateString(
          lastPointExpiredDate
        );
        nextPointExpiredDateDisplay = this.toEnglishDateString(
          nextPointExpiredDate
        );
        lastOnlineDateDisplay = this.toEnglishDateString(lastOnlineDate);
        defaultDealer = userItem.dealerCode + " " + userItem.dealerNameEn;
      }

      let vinNumber = "";
      for (let i = 0; i < userItem.chassisNumber.length; i++) {
        vinNumber += userItem.chassisNumber[i];
        if (i < userItem.chassisNumber.length - 1) {
          vinNumber += ", ";
        }
      }

      let pdpaDisplay = "";
      if (userItem.isPdpaConsent === true) {
        if (this.props.locale.locale === "th") {
          pdpaDisplay = "ยินยอม";
        } else {
          pdpaDisplay = "Accept";
        }
      } else {
        if (this.props.locale.locale === "th") {
          pdpaDisplay = "ไม่ยินยอม";
        } else {
          pdpaDisplay = "Reject";
        }
      }

      let birthdayPoint = 0;
      if (userItem.birthdayPoint !== undefined) {
        birthdayPoint = userItem.birthdayPoint;
      }

      let saleSurveyPoint = 0;
      if (userItem.saleSurveyPoint !== undefined) {
        saleSurveyPoint = userItem.saleSurveyPoint;
      }

      let registerPoint = 0;
      if (registerDateStr === lastPointExpiredDateStr) {
        registerPoint = 500;
      }

      let sumPoints =
        userItem.servicePoint +
        userItem.dailyPoint +
        userItem.surveyPoint +
        birthdayPoint +
        saleSurveyPoint +
        registerPoint;
      let totalPoints =
        userItem.servicePoint +
        userItem.dailyPoint +
        userItem.surveyPoint +
        birthdayPoint +
        saleSurveyPoint +
        registerPoint -
        userItem.redeemPoint;

      return (
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <div className="d-flex justify-content-center">
              <CardBox styleName="col-8" cardStyle="p-0" heading="">
                <div className="table-responsive-material">
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <b>
                            {
                              <IntlMessages id="pages.searchuser.table.fullname" />
                            }
                          </b>
                        </TableCell>
                        <TableCell>{userItem.fullname}</TableCell>
                      </TableRow>
                      {/* <TableRow>
                          <TableCell><b>{<IntlMessages id="pages.searchuser.table.email" />}</b></TableCell>
                          <TableCell>{ userItem.email.length > 0 ? userItem.email : '-' }</TableCell>
                        </TableRow> */}
                      <TableRow>
                        <TableCell>
                          <b>
                            {<IntlMessages id="pages.searchuser.table.phone" />}
                          </b>
                        </TableCell>
                        <TableCell>{phoneNumber}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>
                            {<IntlMessages id="pages.searchuser.table.pdpa" />}
                          </b>
                        </TableCell>
                        <TableCell>{pdpaDisplay}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>
                            {
                              <IntlMessages id="pages.searchuser.table.vinnumber" />
                            }
                          </b>
                        </TableCell>
                        <TableCell>{vinNumber}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>
                            {
                              <IntlMessages id="pages.searchuser.table.dealer" />
                            }
                          </b>
                        </TableCell>
                        <TableCell>{defaultDealer}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>
                            {
                              <IntlMessages id="pages.searchuser.table.registerdate" />
                            }
                          </b>
                        </TableCell>
                        <TableCell>{registerDateDisplay}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>
                            {
                              <IntlMessages id="pages.searchuser.table.lastpointexpireddate" />
                            }
                          </b>
                        </TableCell>
                        <TableCell>{lastPointExpiredDateDisplay}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>
                            {
                              <IntlMessages id="pages.searchuser.table.nextpointexpireddate" />
                            }
                          </b>
                        </TableCell>
                        <TableCell>{nextPointExpiredDateDisplay}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>
                            {
                              <IntlMessages id="pages.searchuser.table.lastonlinedate" />
                            }
                          </b>
                        </TableCell>
                        <TableCell>{lastOnlineDateDisplay}</TableCell>
                      </TableRow>
                      {/* <TableRow>
                          <TableCell>{<IntlMessages id="pages.searchuser.table.totalpoints" />}</TableCell>
                          <TableCell>{ this.numberWithCommas(userItem.totalPoint) }</TableCell>
                        </TableRow>  */}
                    </TableBody>
                  </Table>
                </div>
              </CardBox>

              {/* </div>
                <div className="d-flex justify-content-center"> */}

              <CardBox styleName="col-4" cardStyle="p-0" heading="">
                <div className="table-responsive-material">
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <b>
                            {
                              <IntlMessages id="pages.searchuser.table.servicepoints" />
                            }
                          </b>
                        </TableCell>
                        <TableCell align="right">
                          {this.numberWithCommas(userItem.servicePoint)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>
                            {
                              <IntlMessages id="pages.searchuser.table.dailypoints" />
                            }
                          </b>
                        </TableCell>
                        <TableCell align="right">
                          {this.numberWithCommas(userItem.dailyPoint)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>
                            {
                              <IntlMessages id="pages.searchuser.table.surveypoints" />
                            }
                          </b>
                        </TableCell>
                        <TableCell align="right">
                          {this.numberWithCommas(userItem.surveyPoint)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>
                            {
                              <IntlMessages id="pages.searchuser.table.birthdaypoints" />
                            }
                          </b>
                        </TableCell>
                        <TableCell align="right">
                          {this.numberWithCommas(birthdayPoint)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>
                            {
                              <IntlMessages id="pages.searchuser.table.salesurveypoints" />
                            }
                          </b>
                        </TableCell>
                        <TableCell align="right">
                          {this.numberWithCommas(saleSurveyPoint)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>
                            {
                              <IntlMessages id="pages.searchuser.table.registerpoints" />
                            }
                          </b>
                        </TableCell>
                        <TableCell align="right">
                          {this.numberWithCommas(registerPoint)}
                        </TableCell>
                      </TableRow>
                      <TableRow className="rowhighlight">
                        <TableCell>
                          <b>
                            {
                              <IntlMessages id="pages.searchuser.table.sumpoints" />
                            }
                          </b>
                        </TableCell>
                        <TableCell align="right">
                          <b>{this.numberWithCommas(sumPoints)}</b>
                        </TableCell>
                      </TableRow>
                      {/* <TableRow>
                          <TableCell><b><span className="text-white">|</span></b></TableCell>
                          <TableCell align="right"> </TableCell>
                        </TableRow> */}
                      <TableRow>
                        <TableCell>
                          <b>
                            {
                              <IntlMessages id="pages.searchuser.table.redeempoints" />
                            }
                          </b>
                        </TableCell>
                        <TableCell align="right">
                          <span className="text-red">
                            {this.numberWithCommas(userItem.redeemPoint)}
                          </span>
                        </TableCell>
                      </TableRow>
                      <TableRow className="rowhighlight">
                        <TableCell>
                          <b>
                            {
                              <IntlMessages id="pages.searchuser.table.totalpoints" />
                            }
                          </b>
                        </TableCell>
                        <TableCell align="right">
                          <b>{this.numberWithCommas(userItem.totalPoint)}</b>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </CardBox>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  showTaxInvoices = (carItems) => {
    //console.log('AA:' + this.state.lastPointExpiredDate);

    if (carItems !== undefined && carItems !== null) {
      //console.log('Render Tax Invoices');
      //console.log('car count:' + carItems.length);

      if (carItems.length > 0) {
        //console.log('car > 0');

        let taxInvoicesArray = [];
        let totalServicePoints = 0;

        for (let i = 0; i < carItems.length; i++) {
          let carObj = carItems[i];
          if (carObj.services !== undefined) {
            for (let j = 0; j < carObj.services.length; j++) {
              let serviceObj = carObj.services[j];
              //console.log('vinNumber:' + carObj.chassisNumber + ' serviceDate:' + serviceObj.serviceDate);

              let dealerName = "";
              if (this.props.locale.locale === "th") {
                dealerName = serviceObj.serviceDealerNameTh;
              } else {
                dealerName = serviceObj.serviceDealerNameEn;
              }

              if (serviceObj.serviceDate >= this.state.lastPointExpiredDate) {
                totalServicePoints += serviceObj.servicePoint;
                let taxInvoiceObj = {
                  id: serviceObj.serviceTaxDate + "-" + serviceObj.serviceTaxNo,
                  vinNumber: carObj.chassisNumber,
                  dealerCode: serviceObj.serviceDealerCode,
                  dealerName: dealerName,
                  taxDate: serviceObj.serviceTaxDate,
                  taxNo: serviceObj.serviceTaxNo,
                  price: serviceObj.servicePriceH,
                  point: serviceObj.servicePoint,
                };
                taxInvoicesArray.push(taxInvoiceObj);
              }
            }
          }
        }

        return (
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
              <div className="d-flex justify-content-center">
                <CardBox styleName="col-12" cardStyle="p-0" heading="">
                  <div className="table-responsive-material">
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">
                            {
                              <IntlMessages id="pages.searchuser.table.vinnumber" />
                            }
                          </TableCell>
                          <TableCell align="center">
                            {
                              <IntlMessages id="pages.searchuser.table.dealercode" />
                            }
                          </TableCell>
                          <TableCell align="center">
                            {
                              <IntlMessages id="pages.searchuser.table.dealername" />
                            }
                          </TableCell>
                          <TableCell align="center">
                            {
                              <IntlMessages id="pages.searchuser.table.taxdate" />
                            }
                          </TableCell>
                          <TableCell align="center">
                            {<IntlMessages id="pages.searchuser.table.taxno" />}
                          </TableCell>
                          <TableCell align="center">
                            {<IntlMessages id="pages.searchuser.table.price" />}
                          </TableCell>
                          <TableCell align="center">
                            {<IntlMessages id="pages.searchuser.table.point" />}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {taxInvoicesArray.map((t) => {
                          return (
                            <TableRow key={t.id}>
                              <TableCell align="center">
                                {t.vinNumber}
                              </TableCell>
                              <TableCell align="center">
                                {t.dealerCode}
                              </TableCell>
                              <TableCell align="center">
                                {t.dealerName}
                              </TableCell>
                              <TableCell align="center">{t.taxDate}</TableCell>
                              <TableCell align="center">{t.taxNo}</TableCell>
                              <TableCell align="right">
                                {this.numberWithCommas(t.price)}
                              </TableCell>
                              <TableCell align="right">
                                {this.numberWithCommas(t.point)}
                              </TableCell>
                            </TableRow>
                          );
                        })}

                        <TableRow className="rowhighlight">
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell align="right">
                            <b>
                              {
                                <IntlMessages id="pages.searchuser.table.totalservicepoints" />
                              }
                            </b>
                          </TableCell>
                          <TableCell align="right">
                            <b>{this.numberWithCommas(totalServicePoints)}</b>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </CardBox>
              </div>
            </div>
          </div>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  render() {
    let role = localStorage.getItem("role");
    if (role !== "smt" && role !== "superAdmin" && role.length !== 6) {
      return <Redirect to={"/app/customers/request-delete"} />;
    }

    const { vinNumber } = this.state;
    const {
      loaderUserByVin,
      userByVinItem,
      loaderCarsByUser,
      carItemsByUser,
    } = this.props;

    return (
      <div className="app-wrapper">
        <ContainerHeader
          match={this.props.match}
          title={<IntlMessages id="pages.searchuser" />}
        />
        <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3"></div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <div className="d-flex justify-content-center">
              <CardBox
                styleName="col-7"
                cardStyle="p-5"
                heading={<IntlMessages id="pages.searchuser.label.title" />}
              >
                <form
                  id="search_user"
                  method="post"
                  //onSubmit={this.handleSubmit.bind(this)}
                  action="/"
                >
                  <TextField
                    type="text"
                    label={
                      <IntlMessages id="pages.searchuser.textfield.vinnumber" />
                    }
                    onChange={(event) =>
                      this.setState({ vinNumber: event.target.value })
                    }
                    onInput={(event) =>
                      this.setState({ vinNumber: event.target.value })
                    }
                    onPaste={(event) =>
                      this.setState({ vinNumber: event.target.value })
                    }
                    value={this.state.vinNumber}
                    fullWidth
                    margin="normal"
                    className="mt-0 mb-2"
                    inputProps={{ maxLength: 17 }}
                  />
                  <br />
                  <br />
                  <section>
                    <Button
                      variant="contained"
                      onClick={(event) => {
                        if (event) event.preventDefault();

                        if (this.state.vinNumber === "") {
                          NotificationManager.warning(
                            <IntlMessages id="notification.warning" />,
                            <IntlMessages id="pages.searchuser.message.vinnumberempty" />,
                            3000
                          );
                          return;
                        }

                        this.props.resetSearchUserItems();
                        this.props.showLoaderUserByVin();
                        this.props.getUserByVinRequest(vinNumber);

                        // this.props.showLoaderCarsByUser();
                        // this.props.getCarsByUserRequest('lwdgDJfandfZ0abUD4EbhyDZHPn2');
                      }}
                      color="primary"
                    >
                      <IntlMessages id="pages.searchuser.button.getdata" />
                    </Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button
                      variant="contained"
                      onClick={(event) => {
                        if (event) event.preventDefault();

                        this.clearPress();
                      }}
                      color="secondary"
                    >
                      <IntlMessages id="pages.searchuser.button.reset" />
                    </Button>
                  </section>
                </form>
              </CardBox>
            </div>
          </div>
        </div>
        {loaderUserByVin && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}

        <NotificationContainer />
        {/* { this.state.userId !== '' ? this.displayProcessButton(this.props) : null }  */}
        {this.showUser(this.props.userByVinItem)}

        {loaderCarsByUser && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}

        {this.showTaxInvoices(this.props.carItemsByUser)}
      </div>
    );
  }
}

const mapStateToProps = ({ searchUser, settings }) => {
  const {
    loaderUserByVin,
    userByVinItem,
    loaderCarsByUser,
    carItemsByUser,
  } = searchUser;
  const { locale } = settings;
  return {
    loaderUserByVin,
    userByVinItem,
    loaderCarsByUser,
    carItemsByUser,
    locale: settings.locale,
  };
};

export default connect(mapStateToProps, {
  getUserByVinRequest,
  showLoaderUserByVin,
  getCarsByUserRequest,
  showLoaderCarsByUser,
  resetSearchUserItems,
})(SearchUser);
