import {
    GET_RANKING_RESULT_SERVICE_SURVEY_REQUEST,
    GET_RANKING_RESULT_SERVICE_SURVEY_SUCCESS,
    GET_RANKING_RESULT_DEALER_SERVICE_SURVEY_REQUEST,
    GET_RANKING_RESULT_DEALER_SERVICE_SURVEY_SUCCESS,
    GET_RANKING_RESULT_AREA_SERVICE_SURVEY_REQUEST,
    GET_RANKING_RESULT_AREA_SERVICE_SURVEY_SUCCESS,
    ON_SHOW_LOADER_RANKING,
    ON_HIDE_LOADER_RANKING
  } from 'constants/ActionTypes';
  
  export const getRankingResultServiceSurveyRequest = (month, year) => {
    return {
      type: GET_RANKING_RESULT_SERVICE_SURVEY_REQUEST,
      payload: {
        month: month,
        year: year
      }
    };
  };
  export const getRankingResultServiceSurveySuccess = items => {
    return {
      type: GET_RANKING_RESULT_SERVICE_SURVEY_SUCCESS,
      payload: items
    };
  };
  export const getRankingResultDealerServiceSurveyRequest = (month, year) => {
    return {
      type: GET_RANKING_RESULT_DEALER_SERVICE_SURVEY_REQUEST,
      payload: {
        month: month,
        year: year
      }
    };
  };
  export const getRankingResultDealerServiceSurveySuccess = dealerItems => {
    return {
      type: GET_RANKING_RESULT_DEALER_SERVICE_SURVEY_SUCCESS,
      payload: dealerItems
    };
  };
  export const getRankingResultAreaServiceSurveyRequest = (month, year) => {
    return {
      type: GET_RANKING_RESULT_AREA_SERVICE_SURVEY_REQUEST,
      payload: {
        month: month,
        year: year
      }
    };
  };
  export const getRankingResultAreaServiceSurveySuccess = areaItems => {
    return {
      type: GET_RANKING_RESULT_AREA_SERVICE_SURVEY_SUCCESS,
      payload: areaItems
    };
  };
  
  export const showLoaderRanking = () => {
    return {
      type: ON_SHOW_LOADER_RANKING
    };
  };
  export const hideLoaderRanking = () => {
    return {
      type: ON_HIDE_LOADER_RANKING
    };
  };
  