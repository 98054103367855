import {
    GET_RANKING_RESULT_SERVICE_SURVEY_SUCCESS,
    GET_RANKING_RESULT_DEALER_SERVICE_SURVEY_SUCCESS,
    GET_RANKING_RESULT_AREA_SERVICE_SURVEY_SUCCESS,
    ON_SHOW_LOADER_RANKING,
    ON_HIDE_LOADER_RANKING
  } from 'constants/ActionTypes';
  
  const INIT_STATE = {
    loader: false,
    dealerLoader: false,
    areaLoader: false,    
    items: [],
    dealerItems: [],
    areaItems: []
  };
  
  export default function serviceSurvey(state = INIT_STATE, action) {
    switch (action.type) {
      case GET_RANKING_RESULT_SERVICE_SURVEY_SUCCESS: {
        return {
          ...state,
          loader: false,
          items: action.payload
        };
      }
      case GET_RANKING_RESULT_DEALER_SERVICE_SURVEY_SUCCESS: {
        return {
          ...state,
          dealerLoader: false,
          dealerItems: action.payload
        };
      }
      case GET_RANKING_RESULT_AREA_SERVICE_SURVEY_SUCCESS: {
        return {
          ...state,
          areaLoader: false,
          areaItems: action.payload
        };
      }
      
      case ON_SHOW_LOADER_RANKING: {
        return {
          ...state,
          loader: true,
          dealerLoader: true,
          areaLoader: true
        };
      }
      case ON_HIDE_LOADER_RANKING: {
        return {
          ...state,
          loader: false,
          dealerLoader: false,
          areaLoader: false
        };
      }
      
      default:
        return state;
    }
  }
  