import {
    GET_USER_BY_VIN_REQUEST,
    GET_USER_BY_VIN_SUCCESS,    
    ON_SHOW_LOADER_USER_BY_VIN,
    ON_HIDE_LOADER_USER_BY_VIN,    
    GET_CARS_BY_USER_REQUEST,
    GET_CARS_BY_USER_SUCCESS,
    ON_SHOW_LOADER_CARS_BY_USER,
    ON_HIDE_LOADER_CARS_BY_USER,
    RESET_SEARCH_USER_ITEMS,
  } from 'constants/ActionTypes';

  export const getUserByVinRequest = (vinNumber) => ({
    type: GET_USER_BY_VIN_REQUEST,
    payload: {
      vinNumber: vinNumber
    }
  });

  export const getUserByVinSuccess = userItem => ({
    type: GET_USER_BY_VIN_SUCCESS,
    payload: userItem
  });

  export const showLoaderUserByVin = () => {
    return {
      type: ON_SHOW_LOADER_USER_BY_VIN
    };
  };
  export const hideLoaderUserByVin = () => {
    return {
      type: ON_HIDE_LOADER_USER_BY_VIN
    };
  };

  export const getCarsByUserRequest = (userId) => ({
    type: GET_CARS_BY_USER_REQUEST,
    payload: {
      userId: userId
    }
  });

  export const getCarsByUserSuccess = carItems => ({
    type: GET_CARS_BY_USER_SUCCESS,
    payload: carItems
  });

  export const showLoaderCarsByUser = () => {
    return {
      type: ON_SHOW_LOADER_CARS_BY_USER
    };
  };
  export const hideLoaderCarsByUser = () => {
    return {
      type: ON_HIDE_LOADER_CARS_BY_USER
    };
  };

  export const resetSearchUserItems = () => {
    return {
      type: RESET_SEARCH_USER_ITEMS
    };
  };