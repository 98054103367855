import {
    GET_API_USER_REQUEST,
    GET_API_USER_SUCCESS,
    GET_USER_BY_OLD_PHONE_REQUEST,
    GET_USER_BY_OLD_PHONE_SUCCESS,
    GET_USER_BY_NEW_PHONE_REQUEST,
    GET_USER_BY_NEW_PHONE_SUCCESS,
    ON_SHOW_LOADER_API_USER,
    ON_HIDE_LOADER_API_USER,
    ON_SHOW_LOADER_OLD_PHONE,
    ON_HIDE_LOADER_OLD_PHONE,
    ON_SHOW_LOADER_NEW_PHONE,
    ON_HIDE_LOADER_NEW_PHONE,
    CHANGE_PHONE_REQUEST,
    CHANGE_PHONE_SUCCESS,
    ON_SHOW_LOADER_CHANGE_PHONE,
    ON_HIDE_LOADER_CHANGE_PHONE,
    SHOW_MESSAGE_CHANGE_PHONE,
    HIDE_MESSAGE_CHANGE_PHONE,
    RESET_CHANGE_PHONE
} from 'constants/ActionTypes';

export const getApiUserRequest = (newPhone, vinNumber) => ({
    type: GET_API_USER_REQUEST,
    payload: {
        newPhone: newPhone,
        vinNumber: vinNumber,
      },
});
export const getApiUserSuccess = items => ({
    type: GET_API_USER_SUCCESS,
    payload: items
});
export const getUserByOldPhoneRequest = (oldPhone) => ({
    type: GET_USER_BY_OLD_PHONE_REQUEST,
    payload: {
        oldPhone: oldPhone,
      },
});
export const getUserByOldPhoneSuccess = payload => ({
    type: GET_USER_BY_OLD_PHONE_SUCCESS,
    payload: payload
});
export const getUserByNewPhoneRequest = (newPhone) => ({
    type: GET_USER_BY_NEW_PHONE_REQUEST,
    payload: {
        newPhone: newPhone,
      },
});
export const getUserByNewPhoneSuccess = payload => ({
    type: GET_USER_BY_NEW_PHONE_SUCCESS,
    payload: payload
});
export const showApiUserLoader = () => {
    return {
      type: ON_SHOW_LOADER_API_USER
    };
};
export const hideApiUserLoader = () => {
    return {
      type: ON_HIDE_LOADER_API_USER
    };
};
export const showOldPhoneLoader = () => {
    return {
      type: ON_SHOW_LOADER_OLD_PHONE
    };
};
export const hideOldPhoneLoader = () => {
    return {
      type: ON_HIDE_LOADER_OLD_PHONE
    };
};
export const showNewPhoneLoader = () => {
    return {
      type: ON_SHOW_LOADER_NEW_PHONE
    };
};
export const hideNewPhoneLoader = () => {
    return {
      type: ON_HIDE_LOADER_NEW_PHONE
    };
};
export const changePhoneRequest = (userId, newPhone) => ({
  type: CHANGE_PHONE_REQUEST,
  payload: {
      userId: userId,
      newPhone: newPhone,
    },
});
export const changePhoneSuccess = payload => ({
  type: CHANGE_PHONE_SUCCESS,
  payload: payload
});
export const showChangePhoneLoader = () => {
  return {
    type: ON_SHOW_LOADER_CHANGE_PHONE
  };
};
export const hideChangePhoneLoader = () => {
  return {
    type: ON_HIDE_LOADER_CHANGE_PHONE
  };
};
export const showMessageChangePhone = message => {
  return {
    type: SHOW_MESSAGE_CHANGE_PHONE,
    payload: message
  };
};
export const hideMessageChangePhone = () => {
  return {
    type: HIDE_MESSAGE_CHANGE_PHONE
  };
};
export const resetChangePhone = () => {
  return {
    type: RESET_CHANGE_PHONE
  };
};
  