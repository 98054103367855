import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import {
  NotificationContainer,
  NotificationManager
} from 'react-notifications';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userSignIn,
  userTwitterSignIn
} from 'actions/Auth';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { switchLanguage } from 'actions/Setting';
import LanguageSwitcher from 'components/LanguageSwitcher/index';
import { Redirect } from "react-router-dom";

class SignIn extends React.Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      flag: 'gb',
      langSwitcher: false,
      anchorEl: undefined
    };
  }
  onLangSwitcherSelect = event => {
    this.setState({
      langSwitcher: !this.state.langSwitcher,
      anchorEl: event.currentTarget
    });
  };
  handleRequestClose = () => {
    // console.log('state flag:' + this.state.flag);
    // console.log('local:' + this.props.locale.icon);

    // var currentFlag = this.state.flag;
    // if (this.state.flag === 'th') {
    //   currentFlag = 'gb';
    // }
    // if (this.state.flag === 'gb') {
    //   currentFlag = 'th';
    // }
    // console.log('flag:' + currentFlag);
    this.setState({
      langSwitcher: false
      //flag: currentFlag
    });
  };

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
    if (this.props.authUser !== null) {
      this.props.history.push('/');
    }
  }

  render() {
    return <Redirect to={'/login'} />;

    const { email, password } = this.state;
    const { showMessage, loader, alertMessage, locale } = this.props;
    return (
      <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">
          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <Link className="logo-lg" to="/" title="Hello Suzuki">
              <img
                src={require('assets/images/suzuki-logo.png')}
                alt="Hello Suzuki"
                title="Hello Suzuki"
              />
            </Link>
          </div>

          <div className="app-login-content">
            <div className="row">
              <ul className="header-notifications list-inline ml-auto">
                <IconButton
                  className="icon-btn"
                  language="gb"
                  onClick={() => {
                    const language = {
                      languageId: 'english',
                      locale: 'en',
                      name: 'English',
                      icon: 'gb'
                    };
                    this.props.switchLanguage(language);
                  }}
                >
                  <i className={`flag flag-24 flag-gb`} />
                </IconButton>
                <IconButton
                  className="icon-btn"
                  language="th"
                  onClick={() => {
                    const language = {
                      languageId: 'thai',
                      locale: 'th',
                      name: 'Thai',
                      icon: 'th'
                    };
                    this.props.switchLanguage(language);
                  }}
                >
                  <i className={`flag flag-24 flag-th`} />
                </IconButton>
                {/* <Dropdown
                  className="quick-menu"
                  isOpen={this.state.langSwitcher}
                  toggle={this.onLangSwitcherSelect.bind(this)}
                >
                  <DropdownToggle
                    className="d-inline-block"
                    tag="span"
                    data-toggle="dropdown"
                  >
                    <IconButton className="icon-btn size-24">
                      <i className={`flag flag-24 flag-${locale.icon}`} />
                    </IconButton>
                  </DropdownToggle>

                  <DropdownMenu right className="w-50">
                    <LanguageSwitcher
                      switchLanguage={this.props.switchLanguage}
                      handleRequestClose={this.handleRequestClose}
                    />
                  </DropdownMenu>
                </Dropdown> */}

                {/* <div
                  className="app-language-flag-button"
                  onClick={this.props.handleRequestClose}
                >
                  <img src={require('assets/images/flags/th.svg')}></img>
                </div>
                <div
                  className="app-language-flag-button"
                  onClick={this.props.switchLanguage}
                >
                  <img src={require('assets/images/flags/en.svg')}></img>
                </div> */}
              </ul>
            </div>
            <div className="app-login-header mb-4">
              <h1>
                <IntlMessages id="appModule.email" />
              </h1>
            </div>
            <div className="app-login-form">
              <form>
                <fieldset>
                  <TextField
                    label={<IntlMessages id="appModule.email" />}
                    fullWidth
                    onChange={event =>
                      this.setState({ email: event.target.value })
                    }
                    defaultValue={email}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />
                  <TextField
                    type="password"
                    label={<IntlMessages id="appModule.password" />}
                    fullWidth
                    onChange={event =>
                      this.setState({ password: event.target.value })
                    }
                    defaultValue={password}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />

                  <div className="mb-3 d-flex align-items-center justify-content-between">
                    <Button
                      onClick={() => {
                        this.props.showAuthLoader();
                        this.props.userSignIn({ email, password });
                      }}
                      variant="contained"
                      color="primary"
                    >
                      <IntlMessages id="appModule.signIn" />
                    </Button>

                    {/* <Link to="/signup">
                      <IntlMessages id="signIn.signUp" />
                    </Link> */}
                  </div>

                  <div className="app-social-block my-1 my-sm-3">
                    {/* <IntlMessages id="signIn.connectWith" />
                    <ul className="social-link">
                      <li>
                        <IconButton
                          className="icon"
                          onClick={() => {
                            this.props.showAuthLoader();
                            this.props.userFacebookSignIn();
                          }}
                        >
                          <i className="zmdi zmdi-facebook" />
                        </IconButton>
                      </li>

                      <li>
                        <IconButton
                          className="icon"
                          onClick={() => {
                            this.props.showAuthLoader();
                            this.props.userTwitterSignIn();
                          }}
                        >
                          <i className="zmdi zmdi-twitter" />
                        </IconButton>
                      </li>

                      <li>
                        <IconButton
                          className="icon"
                          onClick={() => {
                            this.props.showAuthLoader();
                            this.props.userGoogleSignIn();
                          }}
                        >
                          <i className="zmdi zmdi-google-plus" />
                        </IconButton>
                      </li>

                      <li>
                        <IconButton
                          className="icon"
                          onClick={() => {
                            this.props.showAuthLoader();
                            this.props.userGithubSignIn();
                          }}
                        >
                          <i className="zmdi zmdi-github" />
                        </IconButton>
                      </li>
                    </ul> */}
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
        {loader && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}
        {showMessage && NotificationManager.error(alertMessage)}
        <NotificationContainer />
      </div>
    );
  }
}

const mapStateToProps = ({ auth, settings }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  const { locale } = settings;
  return {
    loader,
    alertMessage,
    showMessage,
    authUser,
    locale: settings.locale
  };
};

export default connect(mapStateToProps, {
  userSignIn,
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGoogleSignIn,
  userGithubSignIn,
  userTwitterSignIn,
  switchLanguage
})(SignIn);
