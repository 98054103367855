import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';

const Analytics = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Redirect
        exact
        from={`${match.url}/`}
        to={`${match.url}/user-analytics`}
      />
      <Route
        path={`${match.url}/user-analytics`}
        component={asyncComponent(() => import('./routes/AnalyticUsers'))}
      />
      <Route
        component={asyncComponent(() =>
          import('app/routes/extraPages/routes/404')
        )}
      />
    </Switch>
  </div>
);

export default Analytics;