import { all, takeEvery, call, fork, put } from 'redux-saga/effects';
import {
  getRedeemSuccess,
  getRedeemByDealerSuccess,
  getRedeemDealerListSuccess,
  hideLoaderGetRedeem,
  hideLoaderGetRedeemByDealer,
  hideLoaderGetRedeemDealerList,
} from 'actions/Redeem';
import {
  GET_REDEEM_REQUEST,
  GET_REDEEM_BY_DEALER_REQUEST,
  GET_REDEEM_DELAERLIST_REQUEST,
} from 'constants/ActionTypes';
import { rsf, firebase } from '../firebase/firebase';
// import { rsf, db } from '../firebase/firebase';
// import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";

function* getRedeem({ payload }) {
  const { dealerCode, startDate, endDate } = payload;
  const startDateStr = startDate + '000000';
  const endDateStr = endDate + '595959';
  //console.log('redeem dealerCode:' + dealerCode);
  if (dealerCode !== '000000') {
    //console.log('redeem AAA');
    try {
      const colRef = firebase.firestore().collection('RedeemsThailand');
      // const colRef = query(collection(db, 'RedeemsThailand'));
      const snapshot = yield call(
        rsf.firestore.getCollection,
        colRef
          .where('createdAt', '>=', startDateStr)
          .where('createdAt', '<=', endDateStr)
          .where('dealerCode', '==', dealerCode)
      );
      //console.log('items:' + snapshot);
      let items = [];
      snapshot.forEach((doc) => {
        //console.log(doc.data());
        var data = doc.data();
        data['id'] = doc.id;
        //console.log('T:' + data.friends.length);
        items.push(data);

        // datas = {
        //   id: doc.id,
        //   ...doc.data()
        // };
      });
      items.sort((a, b) => {
        return a.createdAt > b.createdAt ? 1 : -1; //ascending
        //return a.createdAt > b.createdAt ? -1 : 1; //descending
      });
      console.log('To:' + items.length);
      yield put(getRedeemSuccess(items));
    } catch (error) {
      console.log(error);
      yield put(hideLoaderGetRedeem());
    }
  } else {
    //console.log('redeem BBB');
    try {
      const colRef = firebase.firestore().collection('RedeemsThailand');
      // const colRef = query(collection(db, 'RedeemsThailand'));
      const snapshot = yield call(
        rsf.firestore.getCollection,
        colRef
          .where('createdAt', '>=', startDateStr)
          .where('createdAt', '<=', endDateStr)
      );
      //console.log('items:' + snapshot);
      let items = [];
      snapshot.forEach((doc) => {
        //console.log(doc.data());
        var data = doc.data();
        data['id'] = doc.id;
        //console.log('T:' + data.friends.length);
        items.push(data);

        // datas = {
        //   id: doc.id,
        //   ...doc.data()
        // };
      });
      items.sort((a, b) => {
        return a.createdAt > b.createdAt ? 1 : -1; //ascending
        //return a.createdAt > b.createdAt ? -1 : 1; //descending
      });
      console.log('To:' + items.length);
      yield put(getRedeemSuccess(items));
    } catch (error) {
      console.log(error);
      yield put(hideLoaderGetRedeem());
    }
  }
}

function* getRedeemByDealer({ payload }) {
  const { startDate, endDate, dealerCode } = payload;
  const startDateStr = startDate + '000000';
  const endDateStr = endDate + '595959';
  //console.log('redeem dealerCode2:' + dealerCode);
  try {
    const colRef = firebase.firestore().collection('RedeemsThailand');
    // const colRef = query(collection(db, 'RedeemsThailand'));
    const snapshot = yield call(
      rsf.firestore.getCollection,
      colRef
        .where('createdAt', '>=', startDateStr)
        .where('createdAt', '<=', endDateStr)
        .where('dealerCode', '==', dealerCode)
    );
    //console.log('items:' + snapshot);
    let items = [];
    snapshot.forEach((doc) => {
      //console.log(doc.data());
      var data = doc.data();
      data['id'] = doc.id;
      //console.log('T:' + data.friends.length);
      items.push(data);

      // datas = {
      //   id: doc.id,
      //   ...doc.data()
      // };
    });
    items.sort((a, b) => {
      return a.createdAt > b.createdAt ? 1 : -1; //ascending
      //return a.createdAt > b.createdAt ? -1 : 1; //descending
    });
    console.log('To:' + items.length);
    yield put(getRedeemByDealerSuccess(items));
  } catch (error) {
    console.log(error);
    yield put(hideLoaderGetRedeemByDealer());
  }
}

function* getRedeemDealerList({ payload }) {
  try {
    const colRef = firebase.firestore().collection('DealersThailand');
    // const colRef = query(collection(db, 'DealersThailand'));
    const snapshot = yield call(
      rsf.firestore.getCollection,
      colRef.where('active', '==', true)
      // rsf.firestore.getCollection,
      // colRef
    );
    //console.log('items:' + snapshot);
    let items = [];
    // var allDealerData;
    // allDealerData['id'] = '000000';
    // allDealerData['dealerId'] = 0;
    // allDealerData['dealerCode'] = '';
    // allDealerData['dealerNameEn'] = 'All';
    // allDealerData['dealerNameTh'] = 'ทั้งหมด';
    //items.push(allDealerData);
    var allDealerData = {
      id: '000000',
      dealerId: 0,
      dealerCode: '000000',
      dealerNameEn: 'All',
      dealerNameTh: 'ทั้งหมด',
    };
    items.push(allDealerData);
    snapshot.forEach((doc) => {
      //console.log(doc.data());
      var data = doc.data();
      data['id'] = data.dealerCode;
      //console.log('T:' + data.friends.length);
      items.push(data);

      // datas = {
      //   id: doc.id,
      //   ...doc.data()
      // };
    });
    //console.log('To:' + items[1].friendsCount);
    yield put(getRedeemDealerListSuccess(items));
  } catch (error) {
    console.log(error);
    yield put(hideLoaderGetRedeemDealerList());
  }
}

export function* watchGetRedeemRequest() {
  yield takeEvery(GET_REDEEM_REQUEST, getRedeem);
}
export function* watchGetRedeemByDealerRequest() {
  yield takeEvery(GET_REDEEM_BY_DEALER_REQUEST, getRedeemByDealer);
}
export function* watchGetRedeemDealerListRequest() {
  yield takeEvery(GET_REDEEM_DELAERLIST_REQUEST, getRedeemDealerList);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetRedeemRequest),
    fork(watchGetRedeemByDealerRequest),
    fork(watchGetRedeemDealerListRequest),
  ]);
}
