import {
  GET_REDEEM_REQUEST,
  GET_REDEEM_SUCCESS,
  GET_REDEEM_BY_DEALER_REQUEST,
  GET_REDEEM_BY_DEALER_SUCCESS,
  GET_REDEEM_DELAERLIST_REQUEST,
  GET_REDEEM_DEALERLIST_SUCCESS,
  ON_SHOW_LOADER_GET_REDEEM,
  ON_HIDE_LOADER_GET_REDEEM,
  ON_SHOW_LOADER_GET_REDEEM_BY_DEALER,
  ON_HIDE_LOADER_GET_REDEEM_BY_DEALER,
  ON_SHOW_LOADER_GET_REDEEM_DEALERLIST,
  ON_HIDE_LOADER_GET_REDEEM_DEALERLIST,
} from 'constants/ActionTypes';

export const getRedeemRequest = (dealerCode, startDate, endDate) => ({
  type: GET_REDEEM_REQUEST,
  payload: {
    dealerCode: dealerCode,
    startDate: startDate,
    endDate: endDate,
  },
});
export const getRedeemSuccess = (items) => ({
  type: GET_REDEEM_SUCCESS,
  payload: items,
});

export const getRedeemByDealerRequest = (dealerCode, startDate, endDate) => ({
  type: GET_REDEEM_BY_DEALER_REQUEST,
  payload: {
    dealerCode: dealerCode,
    startDate: startDate,
    endDate: endDate,
  },
});
export const getRedeemByDealerSuccess = (dealerItems) => ({
  type: GET_REDEEM_BY_DEALER_SUCCESS,
  payload: dealerItems,
});

export const getRedeemDealerListRequest = () => ({
  type: GET_REDEEM_DELAERLIST_REQUEST,
});
export const getRedeemDealerListSuccess = (dealerList) => ({
  type: GET_REDEEM_DEALERLIST_SUCCESS,
  payload: dealerList,
});

export const showLoaderGetRedeem = () => {
  return {
    type: ON_SHOW_LOADER_GET_REDEEM,
  };
};
export const hideLoaderGetRedeem = () => {
  return {
    type: ON_HIDE_LOADER_GET_REDEEM,
  };
};

export const showLoaderGetRedeemByDealer = () => {
  return {
    type: ON_SHOW_LOADER_GET_REDEEM_BY_DEALER,
  };
};
export const hideLoaderGetRedeemByDealer = () => {
  return {
    type: ON_HIDE_LOADER_GET_REDEEM_BY_DEALER,
  };
};

export const showLoaderGetRedeemDealerList = () => {
  return {
    type: ON_SHOW_LOADER_GET_REDEEM_DEALERLIST,
  };
};
export const hideLoaderGetRedeemDealerList = () => {
  return {
    type: ON_HIDE_LOADER_GET_REDEEM_DEALERLIST,
  };
};
