import { all, takeEvery, call, fork, put } from 'redux-saga/effects';
import {
  getRecentsByDealerSuccess,
  getChatHistoryByChatroomidSuccess,
  hideLoaderRecentsByDealer,
  hideLoaderChatHistoryByChatroomid
} from 'actions/Chats';
import {
  GET_RECENTS_BY_DEALER_REQUEST,
  GET_CHAT_HISTORY_BY_CHATROOMID_REQUEST
} from 'constants/ActionTypes';
import { rsf, firebase } from '../firebase/firebase';
// import { rsf, db } from '../firebase/firebase';
// import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";

function* getRecentsByDealer({ payload }) {
  const { dealerCode } = payload;
  try {
    const colRef = firebase.firestore().collection('RecentThailand');
    //const colRef = query(collection(db, 'RecentThailand'));
    const snapshot = yield call(
      rsf.firestore.getCollection,
      colRef.where('userId', '==', dealerCode)
    );
    //console.log('items:' + snapshot);
    let items = [];
    snapshot.forEach(doc => {
      //console.log(doc.data());
      var data = doc.data();
      data['id'] = data.recentId;
      items.push(data);

      // datas = {
      //   id: doc.id,
      //   ...doc.data()
      // };
    });
    items.sort((a, b) => {
      return a.withUserOriginNameTh > b.withUserOriginNameTh ? 1 : -1; //ascending
      //return a.dealerCode > b.dealerCode ? -1 : 1; //descending
    });
    console.log('To:' + items.length);
    yield put(getRecentsByDealerSuccess(items));
  } catch (error) {
    console.log(error);
    yield put(hideLoaderRecentsByDealer());
  }
}

function* getChatHistoryByChatroomid({ payload }) {
  const { dealercode, userid } = payload;

  var chatRoomId = '';
  if (dealercode < userid) {
    chatRoomId = dealercode + userid;
  } else {
    chatRoomId = userid + dealercode;
  }

  //console.log('dec:' + dealercode + ' chtId:' + userid);
  try {
    const colRef = firebase
      .firestore()
      .collection('MessageThailand')
      .doc(dealercode)
      .collection(chatRoomId);
    //const colRef = query(collection(db, 'MessageThailand', dealercode, chatRoomId));

    const snapshot = yield call(rsf.firestore.getCollection, colRef);
    //console.log('items:' + snapshot);
    let items = [];
    snapshot.forEach(doc => {
      //console.log(doc.data());
      var data = doc.data();
      data['id'] = data.messageId;
      items.push(data);

      // datas = {
      //   id: doc.id,
      //   ...doc.data()
      // };
    });
    items.sort((a, b) => {
      //return a.date > b.date ? 1 : -1; //ascending
      return a.date > b.date ? -1 : 1; //descending
    });
    console.log('To:' + items.length);
    yield put(getChatHistoryByChatroomidSuccess(items));
  } catch (error) {
    console.log(error);
    yield put(hideLoaderChatHistoryByChatroomid());
  }
}

export function* watchGetRecentsByDealerRequest() {
  yield takeEvery(GET_RECENTS_BY_DEALER_REQUEST, getRecentsByDealer);
}
export function* watchGetChatHistoryByChatroomidRequest() {
  yield takeEvery(
    GET_CHAT_HISTORY_BY_CHATROOMID_REQUEST,
    getChatHistoryByChatroomid
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchGetRecentsByDealerRequest),
    fork(watchGetChatHistoryByChatroomidRequest)
  ]);
}
