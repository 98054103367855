import React from "react";
import ContainerHeader from "components/ContainerHeader/index";
import IntlMessages from "util/IntlMessages";
import { connect } from "react-redux";
import {
  getApiUserRequest,
  getUserByOldPhoneRequest,
  getUserByNewPhoneRequest,
  changePhoneRequest,
  resetChangePhone,
  showApiUserLoader,
  showOldPhoneLoader,
  showNewPhoneLoader,
  showChangePhoneLoader,
  showMessageChangePhone,
  hideMessageChangePhone,
} from "actions/ChangePhone";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import CardBox from "components/CardBox";
import ListDividers from "app/routes/components/routes/dividers/list/ListDividers";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";
import {
  ContactsOutlined,
  FilterCenterFocusOutlined,
} from "@material-ui/icons";
import { Redirect } from "react-router-dom";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";


class ChangePhone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPhone: "",
      newPhone: "",
      vinNumber: "",
      userId: "",
    };
    //let passwordinput;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.cancelPress = this.cancelPress.bind(this);
    // preserve the initial state in a new object
    this.baseState = this.state;
  }

  handleSubmit(e) {
    e.preventDefault();
    e.target.reset();
  }

  changePhonePress() {
    //console.log('userId:' + this.state.userId);

    this.props.showChangePhoneLoader();
    this.props.changePhoneRequest(this.state.userId, this.state.newPhone);

    document.getElementById("change-phone").reset();
    this.setState({
      oldPhone: "",
      newPhone: "",
      vinNumber: "",
      userId: "",
    });

    // this.setState(this.baseState);
    // this.props.resetChangePhone();
  }

  cancelPress() {
    //e.preventDefault();
    document.getElementById("change-phone").reset();
    this.setState({
      oldPhone: "",
      newPhone: "",
      vinNumber: "",
      userId: "",
    });

    this.setState(this.baseState);
    this.props.resetChangePhone();

    //this.displayAlert();


    // const childProps = { ...this.props };
    // delete childProps.apiUserItems;
    // delete childProps.oldPhoneUser;
    // delete childProps.isPhoneUsing;
    //window.location.reload(false);
  }

  

  // displayAlert() {
  //   <Dialog
  //       // open={open}
  //       // onClose={handleClose}
  //       aria-labelledby="alert-dialog-title"
  //       aria-describedby="alert-dialog-description"
  //     >
  //       <DialogTitle id="alert-dialog-title">
  //         {"Use Google's location service?"}
  //       </DialogTitle>
  //       <DialogContent>
  //         <DialogContentText id="alert-dialog-description">
  //           Let Google help apps determine location. This means sending anonymous
  //           location data to Google, even when no apps are running.
  //         </DialogContentText>
  //       </DialogContent>
  //       <DialogActions>
  //         <Button onClick={this.displaySuccessMessage()}>Disagree</Button>
  //         <Button onClick={this.displaySuccessMessage()} autoFocus>
  //           Agree
  //         </Button>
  //       </DialogActions>
  //     </Dialog>
  // }

  displayMessage(code) {
    if (code === 100) {
      NotificationManager.success(
        <IntlMessages id="pages.changephone.message.notification.success" />,
        <IntlMessages id="pages.changephone.message.notification.done" />,
        3000
      );
    } else {
      NotificationManager.error(
        <IntlMessages id="pages.changephone.message.notification.failure" />,
        <IntlMessages id="pages.changephone.message.notification.fail" />,
        3000
      );
    }
    if (this.props.isMessageChangePhone) {
      this.props.hideMessageChangePhone();
      // setTimeout(() => {
      //   this.props.hideMessageReward();
      // }, 3000);
    }
  }

  displaySuccessMessage() {
    // NotificationManager.success(
    //   this.props.locale === 'en' ? 'Success' : 'สำเร็จ',
    //   this.props.locale === 'en'
    //     ? 'Phone number has been changed.'
    //     : 'เปลี่ยนเบอร์โทรเรียบร้อยแล้ว'
    //     , 3000);
    //console.log('DD:' + changePhoneCode);
    NotificationManager.success(
      <IntlMessages id="pages.changephone.message.notification.success" />,
      <IntlMessages id="pages.changephone.message.notification.done" />,
      3000
    );
    // if (this.props.isMessageReward) {
    //   this.props.hideMessageReward();
    //   setTimeout(() => {
    //     this.props.hideMessageReward();
    //   }, 3000);
    // }
  }

  displayErrorMessage() {
    NotificationManager.error(
      this.props.locale === "en" ? "Failure" : "เกิดข้อผิดพลาด",
      this.props.locale === "en"
        ? "Unable to change phone number!"
        : "ไม่สามารถเปลี่ยนเบอร์โทร!",
      3000
    );
    // if (this.props.isMessageReward) {
    //   this.props.hideMessageReward();
    //   setTimeout(() => {
    //     this.props.hideMessageReward();
    //   }, 3000);
    // }
  }

  componentWillReceiveProps(nextProps) {
    //console.log('componentWillReceiveProps');
    if (nextProps.oldPhoneUser !== this.props.oldPhoneUser) {
      //var vinNumber = this.state.vinNumber;

      if (
        nextProps.oldPhoneUser !== undefined &&
        nextProps.oldPhoneUser !== null
      ) {
        nextProps.oldPhoneUser.map((u) => {
          //console.log('userId2:' + u.objectId);
          //onsold.log('uChassis:' + u.chassisNumber)
          u.chassisNumber.map((c) => {
            if (c === this.state.vinNumber) {
              this.setState({ userId: u.objectId });
              //console.log('userId9:' + u.objectId);
              // return {
              //   userId: u.objectId
              // }
            }
          });
        });
      }
    }
  }

  // componentDidUpdate() {
  //   console.log('UUU:' + this.state.userId);
  //   console.log('aaa:' + this.props.oldPhoneUser);

  //   if (this.props.oldPhoneUser !== undefined && this.props.oldPhoneUser !== null) {
  //     this.props.oldPhoneUser.map((u) => {
  //       //console.log('userId2:' + u.objectId);
  //       //onsold.log('uChassis:' + u.chassisNumber)
  //       u.chassisNumber.map((c) => {
  //         if (c === this.state.vinNumber) {
  //           this.setState({ userId: u.objectId });
  //           console.log('userId3:' + u.objectId);
  //           // return {
  //           //   userId: u.objectId
  //           // }
  //         }
  //       });
  //     });
  //   }
  // };

  // static getDerivedStateFromProps(props, state) {
  //   const { oldPhoneUser } = props;
  //   const {vinNumber, userId } = state;

  //   console.log('getDerived');

  //   return null;
  // }

  // static getDerivedStateFromProps(props, state) {
  //   const { oldPhoneUser } = props;
  //   const {vinNumber, userId } = state;

  //   //console.log('getDerived');

  //   if (oldPhoneUser !== undefined && oldPhoneUser !== null) {
  //     oldPhoneUser.map(u => {
  //       //console.log('userId2:' + u.objectId);
  //       //onsold.log('uChassis:' + u.chassisNumber)
  //       u.chassisNumber.map(c => {
  //         if (c === vinNumber) {
  //           //this.setState({ userId: u.objectId });
  //           console.log('userId3:' + u.objectId);
  //           return {
  //             userId: u.objectId
  //           }
  //         }
  //       });
  //     });
  //   }
  //   return null;
  // };

  
  componentDidUpdate() {
    //console.log('componentDidUpdate');
    if (this.props.oldPhoneUser !== undefined && this.props.oldPhoneUser !== null) {
      // setTimeout(() => {
      //   this.props.hideMessage();
      // }, 3000);
      //document.getElementById('create-reward').reset();
      //console.log("OLDPHONEUSER:" + this.props.oldPhoneUser.length);
      this.props.oldPhoneUser.map(u => {
        //console.log('userId2:' + u.objectId);
        //onsold.log('uChassis:' + u.chassisNumber)
        u.chassisNumber.map(c => {
          if (c === this.state.vinNumber) {
            this.setState({ userId: u.objectId });
            //console.log('userId4:' + this.state.userId);
          }
        });
      });
    }
  };

  myCarDisplay = (apiItems) => {
    if (apiItems.STATUS_CODE === "100") {
      return (
        <ListItem>
          <ListItemIcon>
            <i className="zmdi zmdi-circle text-green" />
          </ListItemIcon>
          <ListItemText>
            {apiItems.CUSTOMER_NAME +
              " " +
              apiItems.CUSTOMER_LAST_NAME +
              " " +
              apiItems.DEALER_ID}
          </ListItemText>
        </ListItem>
      );
    } else {
      return (
        <ListItem>
          <ListItemIcon>
            <i className="zmdi zmdi-close-circle text-red" />
          </ListItemIcon>
          <ListItemText>
            <IntlMessages id="pages.changephone.label.dealernotchangedata" />
          </ListItemText>
        </ListItem>
      );
    }
  };

  oldPhoneDisplay = (oldItems, oldname) => {
    if (oldItems !== undefined && oldItems !== null) {
      if (oldItems.length > 0) {
        // console.log('userId1:' + this.state.userId);

        // oldItems.map(u => {
        //   console.log('userId2:' + u.objectId);
        //   //onsold.log('uChassis:' + u.chassisNumber)
        //   u.chassisNumber.map(c => {
        //     if (c === this.state.vinNumber) {
        //       this.setState({ userId: u.objectId });
        //       console.log('userId3:' + this.state.userId);
        //     }
        //   });
        // });
        return (
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
              <div className="d-flex justify-content-center">
                <CardBox
                  styleName="col-7"
                  cardStyle="p-5"
                  heading={
                    <IntlMessages id="pages.changephone.label.getuseroldphone" />
                  }
                >
                  <List>
                    {/* <IntlMessages id="pages.changephone.label.getuseroldphone" /> */}
                    {oldItems.map((u) => {
                      return (
                        <ListItem key={u.objectId}>
                          <ListItemIcon>
                            {oldname === u.fullname ? (
                              <i className="zmdi zmdi-circle text-green" />
                            ) : (
                              <i className="zmdi zmdi-close-circle text-red" />
                            )}
                          </ListItemIcon>
                          <ListItemText key={u.objectId}>
                            {u.fullname + " " + u.dealerCode}
                          </ListItemText>
                          <List>
                            {u.chassisNumber.map((c) => {
                              return (
                                <ListItem key={c}>
                                  <ListItemIcon>
                                    {c === this.state.vinNumber ? (
                                      <i className="zmdi zmdi-circle text-green" />
                                    ) : (
                                      <i className="zmdi zmdi-close-circle text-red" />
                                    )}
                                  </ListItemIcon>
                                  <ListItemText key={c}>{c}</ListItemText>
                                </ListItem>
                              );
                            })}
                          </List>
                        </ListItem>
                      );
                    })}
                  </List>
                </CardBox>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
              <div className="d-flex justify-content-center">
                <CardBox
                  styleName="col-7"
                  cardStyle="p-5"
                  heading={
                    <IntlMessages id="pages.changephone.label.getuseroldphone" />
                  }
                >
                  <ListItem>
                    <ListItemIcon>
                      <i className="zmdi zmdi-close-circle text-red" />
                    </ListItemIcon>
                    <ListItemText key="1">
                      <IntlMessages id="pages.changephone.label.nooldphonedata" />
                    </ListItemText>
                  </ListItem>
                </CardBox>
              </div>
            </div>
          </div>
        );
      }
    } else {
      return null;
    }
  };

  newPhoneDisplay = (newItems) => {
    if (newItems !== undefined && newItems !== null) {
      //console.log('newItems:' + newItems.length);
      if (newItems.length > 0) {
        let newObj = newItems[0];
        if (newObj.objectId === '1') {
          return (
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                <div className="d-flex justify-content-center">
                  <CardBox
                    styleName="col-7"
                    cardStyle="p-5"
                    heading={
                      <IntlMessages id="pages.changephone.label.getusernewphone" />
                    }
                  >
                    <List>
                      {/* <IntlMessages id="pages.changephone.label.getusernewphone" /> */}
                      {newItems.map((u) => {
                        return (
                          <ListItem key={u.objectId}>
                            <ListItemIcon>
                              <i className="zmdi zmdi-close-circle text-red" />
                            </ListItemIcon>
                            <ListItemText key={u.objectId}>
                              <IntlMessages id="pages.changephone.label.newphoneisusing" />
                            </ListItemText>
                          </ListItem>
                        );
                      })}
                    </List>
                  </CardBox>
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                <div className="d-flex justify-content-center">
                  <CardBox
                    styleName="col-7"
                    cardStyle="p-5"
                    heading={
                      <IntlMessages id="pages.changephone.label.getusernewphone" />
                    }
                  >
                    <List>
                      {/* <IntlMessages id="pages.changephone.label.getusernewphone" /> */}
                      {newItems.map((u) => {
                        return (
                          <ListItem key={u.objectId}>
                            <ListItemIcon>
                              <i className="zmdi zmdi-close-circle text-red" />
                            </ListItemIcon>
                            <ListItemText key={u.objectId}>
                              {u.fullname +
                                " " +
                                u.dealerCode +
                                " " +
                                u.chassisNumber}
                            </ListItemText>
                          </ListItem>
                        );
                      })}
                    </List>
                  </CardBox>
                </div>
              </div>
            </div>
          );
        }        
      } else {
        return (
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
              <div className="d-flex justify-content-center">
                <CardBox
                  styleName="col-7"
                  cardStyle="p-5"
                  heading={
                    <IntlMessages id="pages.changephone.label.getusernewphone" />
                  }
                >
                  <ListItem>
                    <ListItemIcon>
                      <i className="zmdi zmdi-circle text-green" />
                    </ListItemIcon>
                    <ListItemText key="2">
                      <IntlMessages id="pages.changephone.label.nonewphonedata" />
                    </ListItemText>
                  </ListItem>
                </CardBox>
              </div>
            </div>
          </div>
        );
      }
    } else {
      return null;
    }
  };

  showUser = ({ apiUserItems, oldPhoneUser, newPhoneUser, isPhoneUsing }) => {
    //const nodata = <div></div>;
    //console.log('items2:' + apiUserItems);

    if (
      apiUserItems !== undefined &&
      apiUserItems !== null &&
      apiUserItems.CUSTOMER_NAME !== undefined
    ) {
      //console.log('Items is not null:' + apiUserItems.CUSTOMER_NAME);

      //var obj = JSON.parse(apiUserItems);
      //console.log(JSON.stringify(apiUserItems, null, "  "));
      //console.log(JSON.stringify(oldPhoneUser, null, "  "));

      //console.log('aa:' + aa.CUSTOMER_NAME);
      //const dataSet = this.dataForExportToExcel(items);
      return (
        <div>
          {/* {this.displayChangeButton(apiUserItems, oldPhoneUser, isPhoneUsing)} */}
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
              <div className="d-flex justify-content-center">
                <CardBox
                  styleName="col-7"
                  cardStyle="p-5"
                  heading={
                    <IntlMessages id="pages.changephone.label.getuserfromapi" />
                  }
                >
                  {/* {apiUserItems.map(u => u.CUSTOMER_NAME)} */}

                  {this.myCarDisplay(apiUserItems)}
                </CardBox>
              </div>
            </div>
          </div>
          {/* <Divider inset='true'/> */}
          {this.oldPhoneDisplay(
            oldPhoneUser,
            apiUserItems.CUSTOMER_NAME + " " + apiUserItems.CUSTOMER_LAST_NAME
          )}
          {/* {oldPhoneUser[0].fullname} */}

          {/* <Divider inset='true'/> */}
          {this.newPhoneDisplay(newPhoneUser)}

        </div>
      );
    }
  };

  displayChangeButton = ({ apiUserItems, oldPhoneUser, newPhoneUser }) => {
    if (
      apiUserItems !== undefined &&
      apiUserItems !== null &&
      apiUserItems.CUSTOMER_NAME !== undefined
    ) {
      if (oldPhoneUser !== undefined && oldPhoneUser !== null) {
        if (oldPhoneUser.length > 0) {
          // console.log('userId1:' + this.state.userId);

          oldPhoneUser.map((u) => {
            // console.log('userId6:' + u.objectId);
            // console.log('uChassis:' + u.chassisNumber);
            // console.log('state:' + this.state.vinNumber);
            u.chassisNumber.map((c) => {
              if (c === this.state.vinNumber) {
                //this.setState({ userId: u.objectId });
                //console.log("userId7:" + this.state.userId);
                return (
                  <div>
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                        <div className="d-flex justify-content-center">
                          <CardBox styleName="col-7" cardStyle="p-5">
                            <Button
                              variant="contained"
                              className="jr-btn bg-green text-white"
                              onClick={(event) => {
                                if (event) event.preventDefault();
                              }}
                            >
                              <IntlMessages id="pages.changephone.button.change" />
                            </Button>
                          </CardBox>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            });
          });
        }
      }
    }
  };

  displayChangeButton2 = ({ apiUserItems, oldPhoneUser, newPhoneUser }) => {
    //console.log('oldPhoneUser:' + JSON.stringify(oldPhoneUser));
    //console.log('newPhoneUser:' + JSON.stringify(newPhoneUser));
    if (
      oldPhoneUser !== undefined &&
      oldPhoneUser !== null &&
      newPhoneUser !== null && !newPhoneUser.length
    ) {
      if (oldPhoneUser.length > 0) {
        return (
          <div>
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                <div className="d-flex justify-content-center">
                  <CardBox styleName="col-7" cardStyle="p-5">
                    <center>
                      <Button
                        variant="contained"
                        className="jr-btn bg-green text-white"
                        onClick={(event) => {
                          if (event) event.preventDefault();

                          this.changePhonePress();
                        }}
                      >
                        <span>
                          <IntlMessages id="pages.changephone.button.change" />
                        </span>
                      </Button>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <Button
                        variant="contained"
                        className="jr-btn bg-red text-white"
                        onClick={(event) => {
                          if (event) event.preventDefault();

                          this.cancelPress();
                        }}
                      >
                        <IntlMessages id="pages.changephone.button.cancel" />
                      </Button>
                    </center>
                  </CardBox>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  componentDidUpdate() {
    //const { navigate,goBack } = this.props.navigation;

    if (this.props.isMessageChangePhone) {
      this.displayMessage(this.props.changePhoneCode);
    }
  }

  render() {
    let role = localStorage.getItem("role");
    if (role !== "smt" && role !== "superAdmin") {
      if (role.length === 6) {
        return <Redirect to={"/app/dashboard/register-users"} />;
      } else {
        return <Redirect to={"/app/customers/request-delete"} />;
      }
    }

    const { oldPhone, newPhone, vinNumber, userId } = this.state;
    const {
      loaderApiUser,
      loaderUserByOldPhone,
      loaderUserByNewPhone,
      loaderChangePhone,
      apiUserItems,
      oldPhoneUser,
      newPhoneUser,
      isPhoneUsing,
      changePhoneCode,
      isMessageChangePhone,
    } = this.props;

    return (
      <div className="app-wrapper">
        <ContainerHeader
          match={this.props.match}
          title={<IntlMessages id="pages.changephone" />}
        />
        {/* <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
            sfds
        </div> */}
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <div className="d-flex justify-content-center">
              <CardBox styleName="col-7" cardStyle="p-5">
                <form
                  id="change-phone"
                  method="post"
                  //onSubmit={this.handleSubmit.bind(this)}
                  action="/"
                >
                  <TextField
                    type="tel"
                    label={
                      <IntlMessages id="pages.changephone.textfield.oldphone" />
                    }
                    onChange={(event) =>
                      this.setState({ oldPhone: event.target.value })
                    }
                    fullWidth
                    margin="normal"
                    className="mt-0 mb-2"
                    inputProps={{ maxLength: 10 }}
                    // onInput={(e) => {
                    //   e.target.value = Math.max(0, parseInt(e.target.value))
                    //     .toString()
                    //     .slice(0, 10);
                    // }}
                  />

                  <TextField
                    type="tel"
                    label={
                      <IntlMessages id="pages.changephone.textfield.newphone" />
                    }
                    onChange={(event) =>
                      this.setState({ newPhone: event.target.value })
                    }
                    fullWidth
                    margin="normal"
                    className="mt-0 mb-2"
                    //pattern= "\d*"
                    inputProps={{ maxLength: 10 }}
                    //oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    // onInput={(e) => {
                    //   e.target.value = parseInt(e.target.value)
                    //     .toString()
                    //     .slice(0, 10);
                    // }}
                  />

                  <TextField
                    type="text"
                    label={
                      <IntlMessages id="pages.changephone.textfield.vinnumber" />
                    }
                    onChange={(event) =>
                      this.setState({ vinNumber: event.target.value })
                    }
                    fullWidth
                    margin="normal"
                    className="mt-0 mb-2"
                    inputProps={{ maxLength: 17 }}
                  />
                  <section>
                    <Button
                      variant="contained"
                      onClick={(event) => {
                        if (event) event.preventDefault();

                        if (this.state.oldPhone === "") {
                          NotificationManager.warning(
                            <IntlMessages id="notification.warning" />,
                            <IntlMessages id="pages.changephone.message.oldphoneempty" />,
                            3000
                          );
                          return;
                        }

                        if (this.state.newPhone === "") {
                          // NotificationManager.warning(
                          //   <IntlMessages id="pages.createreward.message.orderempty" />
                          // );
                          NotificationManager.warning(
                            <IntlMessages id="notification.warning" />,
                            <IntlMessages id="pages.changephone.message.newphoneempty" />,
                            3000
                          );
                          return;
                        }
                        if (this.state.vinNumber === "") {
                          NotificationManager.warning(
                            <IntlMessages id="notification.warning" />,
                            <IntlMessages id="pages.changephone.message.vinnumberempty" />,
                            3000
                          );
                          return;
                        }

                        this.props.showApiUserLoader();
                        this.props.getApiUserRequest(newPhone, vinNumber);

                        this.props.showOldPhoneLoader();
                        this.props.getUserByOldPhoneRequest(oldPhone);

                        this.props.showNewPhoneLoader();
                        this.props.getUserByNewPhoneRequest(newPhone);
                      }}
                      color="primary"
                    >
                      <IntlMessages id="pages.changephone.button.verify" />
                    </Button>
                    {/* {'userId:' + userId}    */}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button
                      variant="contained"
                      onClick={(event) => {
                        if (event) event.preventDefault();

                        this.cancelPress();
                      }}
                      color="secondary"
                    >
                      <IntlMessages id="pages.changephone.button.reset" />
                    </Button>
                    {/* {this.displayChangeButton(this.props)}  */}
                    {/* <Button
                      variant="contained"
                      className="jr-btn bg-green text-white"
                      onClick={(event) => {
                        if (event) event.preventDefault();
                      }}                        
                    >
                      <IntlMessages id="pages.changephone.button.change" />
                    </Button> */}
                  </section>
                </form>
              </CardBox>
            </div>
          </div>
        </div>
        {loaderApiUser && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}
        {loaderChangePhone && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}
        <NotificationContainer />
        {/* {this.state.userId !== ''
          ? this.displayChangeButton2(this.props)
          : null} */}
          {this.displayChangeButton2(this.props)}
        {this.showUser(this.props)}

        {/* {isMessageChangePhone && this.displayMessage(this.props.changePhoneCode)} */}
        {/* CODE: {changePhoneCode} */}
        {/* {console.log('CHCODE:' + changePhoneCode)}
        {console.log('CHCODE2:' + this.props.changePhoneCode)} */}
        {/* { changePhoneCode === 100 && this.displaySuccessMessage() }
        { changePhoneCode === 400 && this.displayErrorMessage() } */}
        {/* { this.displaySuccessMessage(this.props.changePhoneCode)} */}
      </div>
    );
  }
}

const mapStateToProps = ({ changePhone, settings }) => {
  const {
    loaderApiUser,
    loaderUserByOldPhone,
    loaderUserByNewPhone,
    loaderChangePhone,
    apiUserItems,
    oldPhoneUser,
    newPhoneUser,
    isPhoneUsing,
    changePhoneCode,
    isMessageChangePhone,
  } = changePhone;
  const { locale } = settings;
  return {
    loaderApiUser,
    loaderUserByOldPhone,
    loaderUserByNewPhone,
    loaderChangePhone,
    apiUserItems,
    oldPhoneUser,
    newPhoneUser,
    isPhoneUsing,
    changePhoneCode,
    isMessageChangePhone,
    locale: settings.locale,
  };
};

export default connect(mapStateToProps, {
  getApiUserRequest,
  getUserByOldPhoneRequest,
  getUserByNewPhoneRequest,
  changePhoneRequest,
  resetChangePhone,
  showApiUserLoader,
  showOldPhoneLoader,
  showNewPhoneLoader,
  showChangePhoneLoader,
  showMessageChangePhone,
  hideMessageChangePhone,
})(ChangePhone);

//export default ChangePhone;
