import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { connect } from 'react-redux';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { userSignOut } from 'actions/Auth';
import IntlMessages from 'util/IntlMessages';

class UserInfo extends React.Component {
  state = {
    anchorEl: null,
    open: false
  };

  handleClick = event => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  displayUserName = () => {
    var role = localStorage.getItem('role');
    //console.log('SDS:' + localStorage.getItem('role') + 'ccL:' + role === 'smt');
    //console.log('AAAA:' + role + ':AAAA');
    //var username = '';
    if (role === 'superAdmin') {      
      return 'Administrator';
    } else if (role === 'smt') {
      let name_en = localStorage.getItem('name_en');
      return name_en;
      //return 'Suzuki Motor Thailand';
    } else if (role.length === 6) {
      return role;
    } else {
      let shorth_name = localStorage.getItem('shorth_name');
      if (shorth_name.length > 0) {
        //return 'Epoch & Unix Timestamp Conversion Tools'
        return shorth_name;
      } else {
        return role;
      }
    }
    //return role;

    // if (role === 'smt') {
    //   return 'Suzuki Motor Thailand';
    // }
  };

  render() {
    const profilePhoto = localStorage.getItem('avatar');
    
    return (
      <div className="user-profile d-flex flex-row align-items-center">
        <Avatar
          alt="..."
          src={ profilePhoto !== null ? profilePhoto : require('assets/images/suzuki-logo-top.png') }
          // src={require('assets/images/suzuki-logo-top.png')}
          className="user-avatar "
        />
        <div className="user-detail">
          <h4 className="user-name">{this.displayUserName()}</h4>
        </div>
        {/* <div className="user-detail">
          <h4 className="user-name" onClick={this.handleClick}>
            {this.displayUserName()}
            <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
          </h4>
        </div> */}
        <Menu
          className="user-info"
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={this.handleRequestClose}
          PaperProps={{
            style: {
              minWidth: 120,
              paddingTop: 0,
              paddingBottom: 0
            }
          }}
        >
          {/* <MenuItem onClick={this.handleRequestClose}>
            <i className="zmdi zmdi-account zmdi-hc-fw mr-2" />
            <IntlMessages id="popup.profile" />
          </MenuItem>
          <MenuItem onClick={this.handleRequestClose}>
            <i className="zmdi zmdi-settings zmdi-hc-fw mr-2" />
            <IntlMessages id="popup.setting" />
          </MenuItem> */}
          <MenuItem
            onClick={() => {
              this.handleRequestClose();
              this.props.userSignOut();
            }}
          >
            <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />

            <IntlMessages id="popup.logout" />
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { locale } = settings;
  return { locale };
};
export default connect(mapStateToProps, { userSignOut })(UserInfo);
