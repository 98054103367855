import {
  GET_RESULT_SERVICE_SURVEY_REQUEST,
  GET_RESULT_SERVICE_SURVEY_SUCCESS,
  GET_RESULT_DEALER_SERVICE_SURVEY_REQUEST,
  GET_RESULT_DEALER_SERVICE_SURVEY_SUCCESS,
  GET_RESULT_AREA_SERVICE_SURVEY_REQUEST,
  GET_RESULT_AREA_SERVICE_SURVEY_SUCCESS,
  GET_RESULT_BY_DEALER_SERVICE_SURVEY_REQUEST,
  GET_RESULT_BY_DEALER_SERVICE_SURVEY_SUCCESS,
  GET_SERVICE_SURVEY_REQUEST,
  GET_SERVICE_SURVEY_SUCCESS,
  GET_SERVICE_SURVEY_BY_DATE_REQUEST,
  GET_SERVICE_SURVEY_BY_DATE_SUCCESS,
  ON_SHOW_LOADER,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER_SERVICE_SURVEY,
  ON_HIDE_LOADER_SERVICE_SURVEY
} from 'constants/ActionTypes';

export const getResultServiceSurveyRequest = (month, year) => {
  return {
    type: GET_RESULT_SERVICE_SURVEY_REQUEST,
    payload: {
      month: month,
      year: year
    }
  };
};
export const getResultServiceSurveySuccess = items => {
  return {
    type: GET_RESULT_SERVICE_SURVEY_SUCCESS,
    payload: items
  };
};
export const getResultDealerServiceSurveyRequest = (month, year) => {
  return {
    type: GET_RESULT_DEALER_SERVICE_SURVEY_REQUEST,
    payload: {
      month: month,
      year: year
    }
  };
};
export const getResultDealerServiceSurveySuccess = dealerItems => {
  return {
    type: GET_RESULT_DEALER_SERVICE_SURVEY_SUCCESS,
    payload: dealerItems
  };
};
export const getResultAreaServiceSurveyRequest = (month, year) => {
  return {
    type: GET_RESULT_AREA_SERVICE_SURVEY_REQUEST,
    payload: {
      month: month,
      year: year
    }
  };
};
export const getResultAreaServiceSurveySuccess = areaItems => {
  return {
    type: GET_RESULT_AREA_SERVICE_SURVEY_SUCCESS,
    payload: areaItems
  };
};
export const getResultByDealerServiceSurveyRequest = (dealerCode, month, year) => {
  return {
    type: GET_RESULT_BY_DEALER_SERVICE_SURVEY_REQUEST,
    payload: {
      dealerCode: dealerCode,
      month: month,
      year: year
    }
  };
};
export const getResultByDealerServiceSurveySuccess = byDealerItems => {
  return {
    type: GET_RESULT_BY_DEALER_SERVICE_SURVEY_SUCCESS,
    payload: byDealerItems
  };
};

export const getServiceSurveyRequest = (dealerCode, month, year) => {
  return {
    type: GET_SERVICE_SURVEY_REQUEST,
    payload: {
      dealerCode: dealerCode,
      month: month,
      year: year
    }
  };
};
export const getServiceSurveySuccess = items => {
  return {
    type: GET_SERVICE_SURVEY_SUCCESS,
    payload: items
  };
};
export const getServiceSurveyByDateRequest = (
  dealerCode,
  startDate,
  endDate
) => {
  return {
    type: GET_SERVICE_SURVEY_BY_DATE_REQUEST,
    payload: {
      dealerCode: dealerCode,
      startDate: startDate,
      endDate: endDate
    }
  };
};
export const getServiceSurveyByDateSuccess = items => {
  return {
    type: GET_SERVICE_SURVEY_BY_DATE_SUCCESS,
    payload: items
  };
};
export const showLoader = () => {
  return {
    type: ON_SHOW_LOADER
  };
};
export const hideLoader = () => {
  return {
    type: ON_HIDE_LOADER
  };
};

export const showLoaderServiceSurvey = () => {
  return {
    type: ON_SHOW_LOADER_SERVICE_SURVEY
  };
};
export const hideLoaderServiceSurvey = () => {
  return {
    type: ON_HIDE_LOADER_SERVICE_SURVEY
  };
};
