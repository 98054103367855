import {
    GET_USER_BY_VIN_SUCCESS,    
    ON_SHOW_LOADER_USER_BY_VIN,
    ON_HIDE_LOADER_USER_BY_VIN,    
    GET_CARS_BY_USER_SUCCESS,
    ON_SHOW_LOADER_CARS_BY_USER,
    ON_HIDE_LOADER_CARS_BY_USER,
    RESET_SEARCH_USER_ITEMS,
  } from 'constants/ActionTypes';

  const INIT_STATE = {
    loaderUserByVin: false,
    userByVinItem: null,
    loaderCarsByUser: false,
    carItemsByUser: []
  };

  export default function user(state = INIT_STATE, action) {
    switch (action.type) {
      case GET_USER_BY_VIN_SUCCESS: {
        return {
          ...state,
          loaderUserByVin: false,
          userByVinItem: action.payload
        };
      }      
      case ON_SHOW_LOADER_USER_BY_VIN: {
        return {
          ...state,
          loaderUserByVin: true
        };
      }
      case ON_HIDE_LOADER_USER_BY_VIN: {
        return {
          ...state,
          loaderUserByVin: false
        };
      }

      case GET_CARS_BY_USER_SUCCESS: {
        return {
          ...state,
          loaderCarsByUser: false,
          carItemsByUser: action.payload
        };
      }      
      case ON_SHOW_LOADER_CARS_BY_USER: {
        return {
          ...state,
          loaderCarsByUser: true
        };
      }
      case ON_HIDE_LOADER_CARS_BY_USER: {
        return {
          ...state,
          loaderCarsByUser: false
        };
      }
      case RESET_SEARCH_USER_ITEMS: {
        return {
          ...state,
          loaderUserByVin: false,
          userByVinItem: null,
          loaderCarsByUser: false,
          carItemsByUser: []
        };
      }
      default:
      return state;
  }
}