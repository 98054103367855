import React from "react";
import ContainerHeader from "components/ContainerHeader/index";
import IntlMessages from "util/IntlMessages";
import { connect } from "react-redux";
import {
  getUserSecondCarRequest,
  secondHandCarRequest,
  showGetUserSecondHandCarLoader,
  showSecondHandCarLoader,
  resetSecondHandCar,
  showMessageSecondHandCar,
  hideMessageSecondHandCar,
} from "actions/SecondHandCar";
import TextField from "@material-ui/core/TextField";
//import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import CardBox from "components/CardBox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import CircularProgress from "@material-ui/core/CircularProgress";
//import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";

class SecondHandCar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: "",
      vinNumber: "",
      userId: "",
      isActive: false,
      alertOpen: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.cancelPress = this.cancelPress.bind(this);
    //this.handleRequestClose = this.handleRequestClose.bind(this);

    this.baseState = this.state;
  }

  handleSubmit(e) {
    e.preventDefault();
    e.target.reset();
  }

  handleChange = (name) => (event, checked) => {
    this.setState({ [name]: checked });
    //console.log("isActive:" + this.state.isActive);
  };

  handleRequestOpen = () => {
    this.setState({alertOpen: true});
    //console.log("OPEN")
  };

  handleRequestClose = () => {
    this.setState({alertOpen: false});
    //console.log("CLOSE")
  };

  secondHandCarPress() {
    this.props.showSecondHandCarLoader();
    this.props.secondHandCarRequest(
      this.state.userId,
      this.state.vinNumber,
      this.state.isActive
    );

    document.getElementById("second-hand-car").reset();
    this.setState({
      phoneNumber: "",
      vinNumber: "",
      userId: "",
      isActive: false,
    });
  }

  cancelPress() {
    //e.preventDefault();
    document.getElementById("second-hand-car").reset();
    this.setState({
      phoneNumber: "",
      vinNumber: "",
      userId: "",
      isActive: false,
    });

    this.setState(this.baseState);
    this.props.resetSecondHandCar();    
  }

  // selectAgree() {
  //   console.log("AAA")
  // }

  displayMessage(code) {
    if (code === 100) {
      NotificationManager.success(
        <IntlMessages id="pages.secondhandcar.message.notification.success" />,
        <IntlMessages id="pages.secondhandcar.message.notification.done" />,
        3000
      );
    } else {
      NotificationManager.error(
        <IntlMessages id="pages.secondhandcar.message.notification.failure" />,
        <IntlMessages id="pages.secondhandcar.message.notification.fail" />,
        3000
      );
    }
    if (this.props.isMessageSecondHandCar) {
      this.props.hideMessageSecondHandCar();
      // setTimeout(() => {
      //   this.props.hideMessageReward();
      // }, 3000);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.userSecondHandCarItem !== this.props.userSecondHandCarItem) {
      //var vinNumber = this.state.vinNumber;

      if (
        nextProps.userSecondHandCarItem !== undefined &&
        nextProps.userSecondHandCarItem !== null
      ) {
        if (nextProps.userSecondHandCarItem.length === 1) {
          //this.setState({ userId: u.objectId });
          nextProps.userSecondHandCarItem.map((u) => {
            //console.log('userId2:' + u.objectId);
            //onsold.log('uChassis:' + u.chassisNumber)
            this.setState({ userId: u.objectId });
          });
        }
      }
    }
  }

  displayProcessButton = ({ userSecondHandCarItem }) => {
    if (userSecondHandCarItem !== undefined && userSecondHandCarItem !== null) {
      if (userSecondHandCarItem.length === 1) {
        return (
          <div>
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                <div className="d-flex justify-content-center">
                  <CardBox styleName="col-7" cardStyle="p-5">
                    <section>
                      <center>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={this.state.isActive}
                              onChange={this.handleChange("isActive")}
                              value="isActive"
                            />
                          }
                          label={
                            <IntlMessages id="pages.secondhandcar.checkbox.active" />
                          }
                        />
                      </center>
                      <br />

                      <center>
                        <Button
                          variant="contained"
                          className="jr-btn bg-green text-white"
                          onClick={(event) => {
                            if (event) event.preventDefault();

                            //this.secondHandCarPress();
                            this.handleRequestOpen();
                            
                          }}
                        >
                          <span>
                            <IntlMessages id="pages.secondhandcar.button.process" />
                          </span>
                        </Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Button
                          variant="contained"
                          className="jr-btn bg-red text-white"
                          onClick={(event) => {
                            if (event) event.preventDefault();

                            this.cancelPress();
                          }}
                        >
                          <IntlMessages id="pages.secondhandcar.button.cancel" />
                        </Button>
                      </center>
                    </section>
                  </CardBox>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  showUser = (userItems) => {
    if (userItems !== undefined && userItems !== null) {
      if (userItems.length > 0) {
        // console.log('userId1:' + this.state.userId);

        // oldItems.map(u => {
        //   console.log('userId2:' + u.objectId);
        //   //onsold.log('uChassis:' + u.chassisNumber)
        //   u.chassisNumber.map(c => {
        //     if (c === this.state.vinNumber) {
        //       this.setState({ userId: u.objectId });
        //       console.log('userId3:' + this.state.userId);
        //     }
        //   });
        // });
        return (
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
              <div className="d-flex justify-content-center">
                <CardBox
                  styleName="col-7"
                  cardStyle="p-5"
                  heading={
                    <IntlMessages id="pages.secondhandcar.label.getuserheader" />
                  }
                >
                  <List>
                    {/* <IntlMessages id="pages.changephone.label.getuseroldphone" /> */}
                    {userItems.map((u) => {
                      return (
                        <ListItem key={u.objectId}>
                          <ListItemIcon>
                            {this.state.phoneNumber === u.phone ? (
                              <i className="zmdi zmdi-circle text-green" />
                            ) : (
                              <i className="zmdi zmdi-close-circle text-red" />
                            )}
                          </ListItemIcon>
                          <ListItemText key={u.objectId}>
                            {u.phone + " " + u.fullname + " " + u.dealerCode}
                          </ListItemText>
                          <List>
                            {u.chassisNumber.map((c) => {
                              return (
                                <ListItem key={c}>
                                  <ListItemIcon>
                                    {c === this.state.vinNumber ? (
                                      <i className="zmdi zmdi-circle text-green" />
                                    ) : (
                                      <i className="zmdi zmdi-close-circle text-red" />
                                    )}
                                  </ListItemIcon>
                                  <ListItemText key={c}>{c}</ListItemText>
                                </ListItem>
                              );
                            })}
                          </List>
                        </ListItem>
                      );
                    })}
                  </List>
                </CardBox>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
              <div className="d-flex justify-content-center">
                <CardBox
                  styleName="col-7"
                  cardStyle="p-5"
                  heading={
                    <IntlMessages id="pages.secondhandcar.label.getuserheader" />
                  }
                >
                  <ListItem>
                    <ListItemIcon>
                      <i className="zmdi zmdi-close-circle text-red" />
                    </ListItemIcon>
                    <ListItemText key="1">
                      <IntlMessages id="pages.secondhandcar.label.nouserdata" />
                    </ListItemText>
                  </ListItem>
                </CardBox>
              </div>
            </div>
          </div>
        );
      }
    } else {
      return null;
    }
  };

  componentDidUpdate() {
    //const { navigate,goBack } = this.props.navigation;

    if (this.props.isMessageSecondHandCar) {
      this.displayMessage(this.props.secondHandCarCode);
    }
  }

  render() {
    let role = localStorage.getItem("role");
    if (role !== "smt" && role !== "superAdmin") {
      if (role.length === 6) {
        return <Redirect to={"/app/dashboard/register-users"} />;
      } else {
        return <Redirect to={"/app/customers/request-delete"} />;
      }
    }

    const { phoneNumber, vinNumber, alertOpen } = this.state; //, userId, isActive
    const {
      loaderGetUserSecondHandCar,
      loaderSecondHandCar      
    } = this.props; //userSecondHandCarItem, isMessageSecondHandCar, secondHandCarCode,

    return (
      <div className="app-wrapper">
        <ContainerHeader
          match={this.props.match}
          title={<IntlMessages id="pages.secondhandcar" />}
        />
        <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3"></div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <div className="d-flex justify-content-center">
              <CardBox
                styleName="col-7"
                cardStyle="p-5"
                heading={<IntlMessages id="pages.secondhandcar.label.title" />}
              >
                <form
                  id="second-hand-car"
                  method="post"
                  //onSubmit={this.handleSubmit.bind(this)}
                  action="/"
                >
                  <TextField
                    type="tel"
                    label={
                      <IntlMessages id="pages.secondhandcar.textfield.phonenumber" />
                    }
                    onChange={(event) =>
                      this.setState({ phoneNumber: event.target.value })
                    }
                    fullWidth
                    margin="normal"
                    className="mt-0 mb-2"
                    inputProps={{ maxLength: 10 }}
                    // onInput={(e) => {
                    //   e.target.value = Math.max(0, parseInt(e.target.value))
                    //     .toString()
                    //     .slice(0, 10);
                    // }}
                  />

                  <TextField
                    type="text"
                    label={
                      <IntlMessages id="pages.secondhandcar.textfield.vinnumber" />
                    }
                    onChange={(event) =>
                      this.setState({ vinNumber: event.target.value })
                    }
                    fullWidth
                    margin="normal"
                    className="mt-0 mb-2"
                    inputProps={{ maxLength: 17 }}
                  />
                  <section>
                    <Button
                      variant="contained"
                      onClick={(event) => {
                        if (event) event.preventDefault();

                        if (this.state.phoneNumber === "") {
                          NotificationManager.warning(
                            <IntlMessages id="notification.warning" />,
                            <IntlMessages id="pages.secondhandcar.message.phonenumberempty" />,
                            3000
                          );
                          return;
                        }
                        if (this.state.vinNumber === "") {
                          NotificationManager.warning(
                            <IntlMessages id="notification.warning" />,
                            <IntlMessages id="pages.secondhandcar.message.vinnumberempty" />,
                            3000
                          );
                          return;
                        }

                        this.props.showGetUserSecondHandCarLoader();
                        this.props.getUserSecondCarRequest(
                          phoneNumber,
                          vinNumber
                        );
                      }}
                      color="primary"
                    >
                      <IntlMessages id="pages.secondhandcar.button.verify" />
                    </Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button
                      variant="contained"
                      onClick={(event) => {
                        if (event) event.preventDefault();

                        this.cancelPress();
                      }}
                      color="secondary"
                    >
                      <IntlMessages id="pages.secondhandcar.button.reset" />
                    </Button>
                  </section>
                </form>
              </CardBox>
            </div>
          </div>
        </div>
        {loaderGetUserSecondHandCar && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}
        {loaderSecondHandCar && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}
        <NotificationContainer />
        {this.state.userId !== ""
          ? this.displayProcessButton(this.props)
          : null}
        {this.showUser(this.props.userSecondHandCarItem)}

        
        {
          <Dialog
            open={this.state.alertOpen}
            // onClose={this.handleRequestClose()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {this.props.locale.locale === "en" ? "Warning" : "คำเตือน"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {this.props.locale.locale === "en" ? "Are you sure you want to permanently remove this car?" : "คุณแน่ใจที่จะลบรถคันนี้ใช่ไหม?"}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={(event) => {
                        if (event) event.preventDefault();

                        this.handleRequestClose();
                        this.secondHandCarPress();
                      }} color="primary" autoFocus>{this.props.locale.locale === "en" ? "Yes" : "ใช่"}</Button>
              <Button onClick={(event) => {
                        if (event) event.preventDefault();

                        this.handleRequestClose();
                      }} color="secondary">
                {this.props.locale.locale === "en" ? "No" : "ไม่ใช่"}
              </Button>
            </DialogActions>
          </Dialog>
          } 
        
      </div>
    );
  }
}

const mapStateToProps = ({ secondHandCar, settings }) => {
  const {
    loaderGetUserSecondHandCar,
    loaderSecondHandCar,
    userSecondHandCarItem,
    isMessageSecondHandCar,
    secondHandCarCode,
  } = secondHandCar;
  //const { locale } = settings;
  return {
    loaderGetUserSecondHandCar,
    loaderSecondHandCar,
    userSecondHandCarItem,
    isMessageSecondHandCar,
    secondHandCarCode,
    locale: settings.locale,
  };
};

export default connect(mapStateToProps, {
  getUserSecondCarRequest,
  secondHandCarRequest,
  showGetUserSecondHandCarLoader,
  showSecondHandCarLoader,
  resetSecondHandCar,
  showMessageSecondHandCar,
  hideMessageSecondHandCar,
})(SecondHandCar);
