import {
    GET_CONTACT_US_SUCCESS,
    ON_SHOW_LOADER_GET_CONTACT_US,
    ON_HIDE_LOADER_GET_CONTACT_US
  } from 'constants/ActionTypes';
  
  //INIT_STATE
  const INIT_STATE = {
    loader: false,
    alertMessage: '',
    showMessage: false,
    items: []
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_CONTACT_US_SUCCESS: {
        return {
          ...state,
          loader: false,
          items: action.payload
        };
      }
      case ON_SHOW_LOADER_GET_CONTACT_US: {
        return {
          ...state,
          loader: true
        };
      }
      case ON_HIDE_LOADER_GET_CONTACT_US: {
        return {
          ...state,
          loader: false
        };
      }      
  
      default:
        return state;
    }
  };