import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';

const ServiceSurvey = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/dashboard`} />
      <Route
        path={`${match.url}/dashboard`}
        component={asyncComponent(() => import('./routes/Dashboard'))}
      />
      <Route
        path={`${match.url}/dashboard-by-dealer`}
        component={asyncComponent(() => import('./routes/DashboardByDealer'))}
      />
      <Route
        path={`${match.url}/statistics`}
        component={asyncComponent(() => import('./routes/Statistics'))}
      />
      <Route
        path={`${match.url}/statistics-by-dealer`}
        component={asyncComponent(() => import('./routes/StatisticsByDealer'))}
      />
      <Route
        path={`${match.url}/statistics-by-date`}
        component={asyncComponent(() => import('./routes/StatisticsByDate'))}
      />
      <Route
        path={`${match.url}/ranking`}
        component={asyncComponent(() => import('./routes/Ranking'))}
      />
      <Route
        component={asyncComponent(() =>
          import('app/routes/extraPages/routes/404')
        )}
      />
    </Switch>
  </div>
);

export default ServiceSurvey;
