import React from "react";
import ContainerHeader from "components/ContainerHeader/index";
import IntlMessages from "util/IntlMessages";
import { connect } from "react-redux";
import CardBox from "components/CardBox";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Dropzone from "react-dropzone";
//import ReactCrop from "react-image-crop";
//import { useDropzone } from 'react-dropzone';
import "./custom-image-crop.css";
//import RootRef from '@material-ui/core/RootRef';
//import 'react-image-crop/dist/ReactCrop.css';
import "react-image-crop/lib/ReactCrop.scss";
import {
  showLoaderGetRewardById,
  showLoaderUpdateReward,
  getRewardByIdRequest,
  updateRewardRequest,
  clearRewardItem,
  showMessageReward,
  hideMessageReward,
} from "actions/Reward";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  base64StringtoFile,
  downloadBase64File,
  extractImageFileExtensionFromBase64,
  image64toCanvasRef,
} from "./ReuseableUtils";
import { Redirect } from "react-router-dom";

const imageMaxSize = 1000000000; // bytes
const acceptedFileTypes = "image/x-png, image/png, image/jpg, image/jpeg"; //, image/gif
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => {
  return item.trim();
});

class EditReward extends React.Component {
  constructor(props) {
    super(props);
    this.imagePreviewCanvasRef = React.createRef();
    this.fileInputRef = React.createRef();
    this.state = {
      rewardid: this.props.match.params.rewardid,
      imgSrc: null,
      imgSrcExt: null,
      crop: {
        aspect: 1 / 1,
      },
      order: 0,
      partNumber: "",
      titleEn: "",
      titleTh: "",
      detailEn: "",
      detailTh: "",
      points: "",
      pointsPerBaht: 50,
      isActive: true,
      isEditing: false,
    };
    this.props.clearRewardItem();
    this.props.showLoaderGetRewardById();
    this.props.getRewardByIdRequest(this.state.rewardid);
  }

  static getDerivedStateFromProps(props, state) {
    console.log("GetDerived is called");
    const { item } = props;
    const { isEditing } = state;
    // this.setState({
    //   usersByDealer: usersByDealerItems
    // });

    if (item && !isEditing) {
      console.log("G order State" + item.order);
      //document.getElementById('create-reward').reset();
      return {
        imgSrc: null,
        imgSrcExt: null,
        crop: {
          aspect: 1 / 1,
        },
        order: item.order,
        partNumber: item.partNumber,
        titleEn: item.titleEn,
        titleTh: item.titleTh,
        detailEn: item.detailEn,
        detailTh: item.detailTh,
        points: item.points,
        pointsPerBaht: 50,
        isActive: item.isActive,
        isEditing: true,
      };
    }

    return null;
  }

  backPressed = (e) => {
    e.preventDefault();

    this.props.clearRewardItem();
    this.props.history.goBack();
  };

  verifyFile = (files) => {
    if (files && files.length > 0) {
      const currentFile = files[0];
      const currentFileType = currentFile.type;
      const currentFileSize = currentFile.size;
      if (currentFileSize > imageMaxSize) {
        alert(
          "This file is not allowed. " + currentFileSize + " bytes is too large"
        );
        return false;
      }
      if (!acceptedFileTypesArray.includes(currentFileType)) {
        alert("This file is not allowed. Only images are allowed.");
        return false;
      }
      return true;
    }
  };

  // handleChange = text => {
  //   this.setState = {
  //     //isActive: !this.state.isActive
  //     isActive: false
  //   };
  // };

  handleOnDrop = (files, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      this.verifyFile(rejectedFiles);
    }

    if (files && files.length > 0) {
      const isVerified = this.verifyFile(files);
      if (isVerified) {
        // imageBase64Data
        const currentFile = files[0];
        const myFileItemReader = new FileReader();
        myFileItemReader.addEventListener(
          "load",
          () => {
            // console.log(myFileItemReader.result)
            const myResult = myFileItemReader.result;
            this.setState({
              imgSrc: myResult,
              imgSrcExt: extractImageFileExtensionFromBase64(myResult),
            });
          },
          false
        );

        myFileItemReader.readAsDataURL(currentFile);
      }
    }
  };

  handleImageLoaded = (image) => {
    console.log(image);
  };
  handleOnCropChange = (crop) => {
    this.setState({ crop: crop });
    //this.setState({ crop });
  };
  handleOnCropComplete = (crop, pixelCrop) => {
    //console.log(crop, pixelCrop)

    const canvasRef = this.imagePreviewCanvasRef.current;
    const { imgSrc } = this.state;
    image64toCanvasRef(canvasRef, imgSrc, pixelCrop);

    console.log("canvasWidth:" + canvasRef.width);
    console.log("canvasHeight:" + canvasRef.height);

    // const canvas = document.createElement('canvas');
    // // As a blob
    // return new Promise((resolve, reject) => {
    //   canvasRef.toBlob(file => {
    //     file.name = 'aaa';
    //     resolve(file);
    //   }, 'image/jpeg');
    // });
  };
  handleDownloadClick = (event) => {
    event.preventDefault();
    const { imgSrc } = this.state;
    if (imgSrc) {
      const canvasRef = this.imagePreviewCanvasRef.current;

      const { imgSrcExt } = this.state;
      const imageData64 = canvasRef.toDataURL("image/" + imgSrcExt);

      const myFilename = "previewFile." + imgSrcExt;

      // file to be uploaded
      const myNewCroppedFile = base64StringtoFile(imageData64, myFilename);
      console.log(myNewCroppedFile);
      // download file
      downloadBase64File(imageData64, myFilename);
      this.handleClearToDefault();
    }
  };

  handleClearToDefault = (event) => {
    if (event) event.preventDefault();

    const canvas = this.imagePreviewCanvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    //console.log('canvasWidth:' + canvas.width);
    //console.log('canvasHeight:' + canvas.height);
    this.setState({
      imgSrc: null,
      imgSrcExt: null,
      crop: {
        aspect: 1 / 1,
      },
    });
    this.fileInputRef.current.value = null;
  };

  handleClearImage = (event) => {
    if (event) event.preventDefault();

    this.setState({
      imgSrc: null,
      imgSrcExt: null,
      crop: {
        aspect: 1 / 1,
      },
    });
    //this.fileInputRef.current.value = null;
  };

  handleFileSelect = (event) => {
    // console.log(event)
    const files = event.target.files;
    if (files && files.length > 0) {
      const isVerified = this.verifyFile(files);
      if (isVerified) {
        // imageBase64Data
        const currentFile = files[0];
        const myFileItemReader = new FileReader();
        myFileItemReader.addEventListener(
          "load",
          () => {
            //console.log(myFileItemReader.result);
            const myResult = myFileItemReader.result;
            this.setState({
              imgSrc: myResult,
              imgSrcExt: extractImageFileExtensionFromBase64(myResult),
            });
          },
          false
        );

        myFileItemReader.readAsDataURL(currentFile);
      }
    }
  };

  handleChange = (name) => (event, checked) => {
    this.setState({ [name]: checked });
  };

  handleClearState = () => {
    this.setState({
      imgSrc: null,
      imgSrcExt: null,
      order: 0,
      partNumber: "",
      titleEn: "",
      titleTh: "",
      detailEn: "",
      detailTh: "",
      points: "",
      pointsPerBaht: 50,
      isActive: true,
      //rewardid: ''
    });
    // console.log('rewardid:' + rewardid);
    // if (rewardid !== undefined && rewardid.length > 0) {
    //   this.setState({
    //     imgSrc: null,
    //     imgSrcExt: null,
    //     order: 0,
    //     partNumber: '',
    //     titleEn: '',
    //     titleTh: '',
    //     detailEn: '',
    //     detailTh: '',
    //     points: '',
    //     isActive: true
    //     //rewardid: ''
    //   });
    // }
  };

  displayMessage() {
    NotificationManager.success(
      this.props.locale === "en" ? "Success" : "สำเร็จ",
      this.props.locale === "en"
        ? "Reward has been updated"
        : "รางวัลแก้ไขเรียบร้อยแล้ว"
    );
    if (this.props.isMessageReward) {
      this.props.hideMessageReward();
      setTimeout(() => {
        this.props.hideMessageReward();
        this.props.clearRewardItem();
        this.props.history.goBack();
      }, 2000);
    }
  }

  /*
  componentDidUpdate() {
    console.log('showMessageReward:' + this.props.isMessageReward);

    if (this.props.isMessageReward) {
      setTimeout(() => {
        this.props.hideMessageReward();
        this.props.clearRewardItem();
        this.props.history.goBack();
      }, 3000);
    }
  }
  */

  displayEditPane = (item) => {
    const {
      rewardid,
      imgSrc,
      order,
      partNumber,
      titleEn,
      titleTh,
      detailEn,
      detailTh,
      points,
      pointsPerBaht,
      isActive,
    } = this.state;
    return (
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
          <div className="d-flex justify-content-center">
            <CardBox
              styleName="col-7"
              cardStyle="p-5"
              heading={<IntlMessages id="pages.editreward.label.title" />}
            >
              {console.log("FF:" + item)}
              <form method="post" action="/">
                <div>
                  <Dropzone
                    onDrop={this.handleOnDrop}
                    accept={acceptedFileTypes}
                    multiple={false}
                    maxSize={imageMaxSize}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div className="Drow">
                          <div className="DropZone" {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p>
                              <IntlMessages id="pages.createreward.image.add" />
                            </p>
                          </div>
                          &nbsp;&nbsp;
                          <div>
                            <img
                              src={
                                this.state.imgSrc
                                  ? this.state.imgSrc
                                  : item
                                  ? item.photo
                                  : null
                              }
                              width="220"
                              height="220"
                              alt=""
                            ></img>
                          </div>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
                {/* {imgSrc !== null ? (
                    <div>
                      <section>
                        <img src={imgSrc} width="200" height="200"></img>
                      </section>
                      <br />
                      
                      <section>
                        <button onClick={this.handleClearImage}>Clear</button>
                      </section>
                    </div>
                  ) : (
                    <div>
                      <Dropzone
                        onDrop={this.handleOnDrop}
                        accept={acceptedFileTypes}
                        multiple={false}
                        maxSize={imageMaxSize}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div className="Drow">
                              <div className="DropZone" {...getRootProps()}>
                                <input {...getInputProps()} />
                                <p>
                                  <IntlMessages id="pages.createreward.image.add" />
                                </p>
                              </div>
                              &nbsp;&nbsp;
                              <div>
                                <img
                                  src={
                                    imgSrc ? imgSrc : item ? item.photo : null
                                  }
                                  width="220"
                                  height="220"
                                  alt="aaa"
                                ></img>
                              </div>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                  )} */}
                {/* <section>
                    <br />
                    <input
                      ref={this.fileInputRef}
                      type="file"
                      accept={acceptedFileTypes}
                      multiple={false}
                      onChange={this.handleFileSelect}
                    />
                  </section> */}
                <br />
                <TextField
                  type="number"
                  label={
                    <IntlMessages id="pages.createreward.textfield.order" />
                  }
                  onChange={(event) =>
                    this.setState({ order: event.target.value })
                  }
                  margin="normal"
                  className="mt-0 mb-2"
                  defaultValue={item ? item.order : ""}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 2);
                  }}
                />

                <TextField
                  type="text"
                  label={
                    <IntlMessages id="pages.createreward.textfield.partno" />
                  }
                  onChange={(event) =>
                    this.setState({ partNumber: event.target.value })
                  }
                  fullWidth
                  margin="normal"
                  className="mt-0 mb-2"
                  disabled={true}
                  defaultValue={item ? item.partNumber : ""}
                  inputProps={{ maxLength: 25 }}
                />

                <TextField
                  type="text"
                  label={
                    <IntlMessages id="pages.createreward.textfield.titleen" />
                  }
                  onChange={(event) =>
                    this.setState({ titleEn: event.target.value })
                  }
                  fullWidth
                  margin="normal"
                  className="mt-0 mb-2"
                  defaultValue={item ? item.titleEn : ""}
                  inputProps={{ maxLength: 120 }}
                />

                <TextField
                  type="text"
                  label={
                    <IntlMessages id="pages.createreward.textfield.titleth" />
                  }
                  onChange={(event) =>
                    this.setState({ titleTh: event.target.value })
                  }
                  fullWidth
                  margin="normal"
                  className="mt-0 mb-2"
                  defaultValue={item ? item.titleTh : ""}
                  inputProps={{ maxLength: 120 }}
                />

                {/* <TextField
                  type="text"
                  label={
                    <IntlMessages id="pages.createreward.textfield.detailen" />
                  }
                  onChange={(event) =>
                    this.setState({ detailEn: event.target.value })
                  }
                  fullWidth
                  margin="normal"
                  className="mt-0 mb-2"
                  defaultValue={item ? item.detailEn : ''}
                  inputProps={{ maxLength: 150 }}
                />

                <TextField
                  type="text"
                  label={
                    <IntlMessages id="pages.createreward.textfield.detailth" />
                  }
                  onChange={(event) =>
                    this.setState({ detailTh: event.target.value })
                  }
                  fullWidth
                  margin="normal"
                  className="mt-0 mb-2"
                  defaultValue={item ? item.detailTh : ''}
                  inputProps={{ maxLength: 150 }}
                /> */}

                <TextField
                  type="number"
                  label={
                    <IntlMessages id="pages.createreward.textfield.points" />
                  }
                  onChange={(event) =>
                    this.setState({ points: event.target.value })
                  }
                  fullWidth
                  margin="normal"
                  className="mt-0 mb-2"
                  defaultValue={item ? item.points : ""}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 7);
                  }}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={isActive}
                      onChange={this.handleChange("isActive")}
                      value="isActive"
                      defaultValue={item.isActive === true ? true : false}
                    />
                  }
                  label={
                    <IntlMessages id="pages.createreward.textfield.active" />
                  }
                />
                <section>
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (order === 0) {
                        // NotificationManager.warning(
                        //   <IntlMessages id="pages.createreward.message.orderempty" />
                        // );
                        NotificationManager.warning(
                          <IntlMessages id="notification.warning" />,
                          <IntlMessages id="pages.createreward.message.orderempty" />,
                          3000
                        );
                        return;
                      }
                      if (partNumber === "") {
                        NotificationManager.warning(
                          <IntlMessages id="notification.warning" />,
                          <IntlMessages id="pages.createreward.message.partnoempty" />,
                          3000
                        );
                        return;
                      }
                      if (titleEn === "") {
                        NotificationManager.warning(
                          <IntlMessages id="notification.warning" />,
                          <IntlMessages id="pages.createreward.message.titleenempty" />,
                          3000
                        );
                        return;
                      }
                      if (titleTh === "") {
                        NotificationManager.warning(
                          <IntlMessages id="notification.warning" />,
                          <IntlMessages id="pages.createreward.message.titlethempty" />,
                          3000
                        );
                        return;
                      }
                      // if (detailEn === '') {
                      //   NotificationManager.warning(
                      //     <IntlMessages id="notification.warning" />,
                      //     <IntlMessages id="pages.createreward.message.detailenempty" />,
                      //     3000
                      //   );
                      //   return;
                      // }
                      // if (detailTh === '') {
                      //   NotificationManager.warning(
                      //     <IntlMessages id="notification.warning" />,
                      //     <IntlMessages id="pages.createreward.message.detailthempty" />,
                      //     3000
                      //   );
                      //   return;
                      // }
                      if (points === "") {
                        NotificationManager.warning(
                          <IntlMessages id="notification.warning" />,
                          <IntlMessages id="pages.createreward.message.pointsempty" />,
                          3000
                        );
                        return;
                      }
                      this.props.showLoaderUpdateReward();
                      this.props.updateRewardRequest({
                        rewardid,
                        imgSrc,
                        order,
                        partNumber,
                        titleEn,
                        titleTh,
                        detailEn,
                        detailTh,
                        points,
                        pointsPerBaht,
                        isActive,
                      });
                      // this.props.updateRewardRequest(
                      //   this.state.rewardid,
                      //   this.state.imgSrc,
                      //   this.state.order,
                      //   this.state.partNumber,
                      //   this.state.titleEn,
                      //   this.state.titleTh,
                      //   this.state.detailEn,
                      //   this.state.detailTh,
                      //   this.state.points,
                      //   this.state.isActive
                      // );
                      //alert(password);
                      //this.props.showLoaderCreateDealerAccount();
                      //this.props.createDealerAccountRequest(email, password);
                      // this.props.createDealerAccountRequest({
                      //   email,
                      //   password
                      // });
                    }}
                    color="primary"
                  >
                    <IntlMessages id="pages.editreward.button.edit" />
                  </Button>
                </section>
              </form>
            </CardBox>
          </div>
        </div>
      </div>
    );
  };

  render() {
    let role = localStorage.getItem("role");
    if (role !== "smt" && role !== "superAdmin") {
      if (role.length === 6) {
        return <Redirect to={"/app/dashboard/register-users"} />;
      } else {
        return <Redirect to={"/app/customers/request-delete"} />;
      }
    }

    // const {
    //   rewardid,
    //   imgSrc,
    //   order,
    //   partNumber,
    //   titleEn,
    //   titleTh,
    //   detailEn,
    //   detailTh,
    //   points,
    //   pointsPerBaht,
    //   isActive,
    // } = this.state;
    const {
      loaderGetRewardById,
      loaderUpdateReward,
      isMessageReward,
      item,
    } = this.props;

    // const { getRootProps, getInputProps } = useDropzone();
    // const { ref, ...rootProps } = getRootProps();

    return (
      <div className="app-wrapper">
        <ContainerHeader
          match={this.props.match}
          title={<IntlMessages id="pages.editreward" />}
        />
        <div className="module-box-topbar">
          <IconButton className="icon-btn" onClick={this.backPressed}>
            <i className="zmdi zmdi-arrow-back" />
          </IconButton>
        </div>
        {item && this.displayEditPane(item)}
        {loaderGetRewardById && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}
        {loaderUpdateReward && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}
        {/* {rewardid && window.location.reload()} */}
        {/*isMessageReward &&
          NotificationManager.success(
            this.props.locale === 'en' ? 'Success' : 'สำเร็จ',
            this.props.locale === 'en'
              ? 'Reward has been updated'
              : 'รางวัลแก้ไขเรียบร้อยแล้ว'
          )*/}
        {isMessageReward && this.displayMessage()}
        {/* {showMessage && NotificationManager.error(alertMessage)} */}
        <NotificationContainer />
      </div>
    );
  }
}

// order: item.order,
//     partNumber: item.partNumber,
//     titleEn: item.titleEn,
//     titleTh: item.titleTh,
//     detailEn: item.detailEn,
//     detailTh: item.detailTh,
//     points: item.points,
//     isActive: item.isActive,

// EditReward.propTypes = {
//   order: PropTypes.order,
//   partNumber: PropTypes.partNumber
// };

const mapStateToProps = ({ reward, settings }) => {
  const {
    loaderGetRewardById,
    loaderUpdateReward,
    item,
    isMessageReward,
    alerMessage,
  } = reward;
  //const { locale } = settings;
  return {
    loaderGetRewardById,
    loaderUpdateReward,
    item,
    isMessageReward,
    alerMessage,
    locale: settings.locale,
  };
};
export default connect(mapStateToProps, {
  showLoaderGetRewardById,
  showLoaderUpdateReward,
  getRewardByIdRequest,
  updateRewardRequest,
  clearRewardItem,
  showMessageReward,
  hideMessageReward,
})(EditReward);
