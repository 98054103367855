import {
  GET_RESULT_SERVICE_SURVEY_SUCCESS,
  GET_RESULT_DEALER_SERVICE_SURVEY_SUCCESS,
  GET_RESULT_AREA_SERVICE_SURVEY_SUCCESS,
  GET_RESULT_BY_DEALER_SERVICE_SURVEY_SUCCESS,
  GET_SERVICE_SURVEY_SUCCESS,
  GET_SERVICE_SURVEY_BY_DATE_SUCCESS,
  ON_SHOW_LOADER,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER_SERVICE_SURVEY,
  ON_HIDE_LOADER_SERVICE_SURVEY
} from 'constants/ActionTypes';

const INIT_STATE = {
  loader: false,
  loaderServiceSurvey: false,
  dealerLoader: false,
  areaLoader: false,
  byDealerLoader: false,
  items: [],
  dealerItems: [],
  areaItems: [],
  byDealerItems: [],
  serviceSurveyItems: []
};

export default function serviceSurvey(state = INIT_STATE, action) {
  switch (action.type) {
    case GET_RESULT_SERVICE_SURVEY_SUCCESS: {
      return {
        ...state,
        loader: false,
        items: action.payload
      };
    }
    case GET_RESULT_DEALER_SERVICE_SURVEY_SUCCESS: {
      return {
        ...state,
        dealerLoader: false,
        dealerItems: action.payload
      };
    }
    case GET_RESULT_AREA_SERVICE_SURVEY_SUCCESS: {
      return {
        ...state,
        areaLoader: false,
        areaItems: action.payload
      };
    }
    case GET_RESULT_BY_DEALER_SERVICE_SURVEY_SUCCESS: {
      return {
        ...state,
        byDealerLoader: false,
        byDealerItems: action.payload
      };
    }
    case GET_SERVICE_SURVEY_SUCCESS: {
      return {
        ...state,
        loaderServiceSurvey: false,
        serviceSurveyItems: action.payload
      };
    }
    case GET_SERVICE_SURVEY_BY_DATE_SUCCESS: {
      return {
        ...state,
        loaderServiceSurvey: false,
        serviceSurveyItems: action.payload
      };
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true,
        dealerLoader: true,
        areaLoader: true,
        byDealerLoader: true
      };
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false,
        dealerLoader: false,
        areaLoader: false,
        byDealerLoader: false
      };
    }
    case ON_SHOW_LOADER_SERVICE_SURVEY: {
      return {
        ...state,
        loaderServiceSurvey: true
      };
    }
    case ON_HIDE_LOADER_SERVICE_SURVEY: {
      return {
        ...state,
        loaderServiceSurvey: false
      };
    }
    default:
      return state;
  }
}
