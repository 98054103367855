import {
  GET_USERS_BY_DATE_SUCCESS,
  GET_USERS_BY_DEALER_SUCCESS,
  GET_USERS_BY_DEALER_BY_DATE_SUCCESS,
  GET_USERS_BY_DEFAULT_DEALER_BY_DATE_SUCCESS,
  GET_FRIENDS_BY_DEALER_SUCCESS,
  ON_SHOW_LOADER_USERS_BY_DATE,
  ON_HIDE_LOADER_USERS_BY_DATE,
  ON_SHOW_LOADER_USERS_BY_DEALER,
  ON_HIDE_LOADER_USERS_BY_DEALER,
  ON_SHOW_LOADER_USERS_BY_DEALER_BY_DATE,
  ON_HIDE_LOADER_USERS_BY_DEALER_BY_DATE,
  ON_SHOW_LOADER_FRIENDS_BY_DEALER,
  ON_HIDE_LOADER_FRIENDS_BY_DEALER
} from 'constants/ActionTypes';

const INIT_STATE = {
  loaderUsersByDate: false,
  loaderUsersByDealer: false,
  loaderUsersByDealerByDate: false,
  loaderFriendsByDealer: false,
  usersByDateItems: [],
  usersByDealerItems: [],
  usersByDealerByDateItems: [],
  friendsByDealerItems: []
};

//dealers
export default function users(state = INIT_STATE, action) {
  switch (action.type) {
    case GET_USERS_BY_DATE_SUCCESS: {
      return {
        ...state,
        loaderUsersByDate: false,
        usersByDateItems: action.payload
      };
    }
    case GET_USERS_BY_DEALER_SUCCESS: {
      return {
        ...state,
        loaderUsersByDealer: false,
        usersByDealerItems: action.payload
      };
    }
    case GET_USERS_BY_DEALER_BY_DATE_SUCCESS: {
      return {
        ...state,
        loaderUsersByDealerByDate: false,
        usersByDealerByDateItems: action.payload
      };
    }
    case GET_USERS_BY_DEFAULT_DEALER_BY_DATE_SUCCESS: {
      return {
        ...state,
        loaderUsersByDealerByDate: false,
        usersByDealerByDateItems: action.payload
      };
    }
    case GET_FRIENDS_BY_DEALER_SUCCESS: {
      return {
        ...state,
        loaderFriendsByDealer: false,
        friendsByDealerItems: action.payload
      };
    }
    case ON_SHOW_LOADER_USERS_BY_DATE: {
      return {
        ...state,
        loaderUsersByDate: true
      };
    }
    case ON_HIDE_LOADER_USERS_BY_DATE: {
      return {
        ...state,
        loaderUsersByDate: false
      };
    }
    case ON_SHOW_LOADER_USERS_BY_DEALER: {
      return {
        ...state,
        loaderUsersByDealer: true
      };
    }
    case ON_HIDE_LOADER_USERS_BY_DEALER: {
      return {
        ...state,
        loaderUsersByDealer: false
      };
    }
    case ON_SHOW_LOADER_USERS_BY_DEALER_BY_DATE: {
      return {
        ...state,
        loaderUsersByDealerByDate: true
      };
    }
    case ON_HIDE_LOADER_USERS_BY_DEALER_BY_DATE: {
      return {
        ...state,
        loaderUsersByDealerByDate: false
      };
    }
    case ON_SHOW_LOADER_FRIENDS_BY_DEALER: {
      return {
        ...state,
        loaderFriendsByDealer: true
      };
    }
    case ON_HIDE_LOADER_FRIENDS_BY_DEALER: {
      return {
        ...state,
        loaderFriendsByDealer: false
      };
    }
    default:
      return state;
  }
}
