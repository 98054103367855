import {
    GET_DELETE_USER_DATA_SUCCESS,
    CREATE_DELETE_USER_DATA_SUCCESS,
    UPDATE_DELETE_USER_DATA_SUCCESS,
    ON_SHOW_LOADER_DELETE_USER_DATA,
    ON_HIDE_LOADER_DELETE_USER_DATA,
    ON_SHOW_LOADER_GET_DELETE_USER_DATA,
    ON_HIDE_LOADER_GET_DELETE_USER_DATA,
  } from 'constants/ActionTypes';
  
  const INIT_STATE = {
    loaderGetDeleteUserData: false,
    loaderDeleteUserData: false,    
    userData: null,
    userId: ''
  };

  export default function deleteUserData(state = INIT_STATE, action) {
    switch (action.type) {
      case GET_DELETE_USER_DATA_SUCCESS: {
        return {
          ...state,
          loaderGetDeleteUserData: false,
          userData: action.payload
        };
      }
      case CREATE_DELETE_USER_DATA_SUCCESS: {
        return {
          ...state,
          loaderDeleteUserData: false,
          userData: action.payload,
        };
      }
      case UPDATE_DELETE_USER_DATA_SUCCESS: {
        return {
          ...state,
          loaderDeleteUserData: false,
          userData: null,
          userId: action.payload
        };
      }
      case ON_SHOW_LOADER_DELETE_USER_DATA: {
        return {
          ...state,
          loaderDeleteUserData: true
        };
      }
      case ON_HIDE_LOADER_DELETE_USER_DATA: {
        return {
          ...state,
          loaderDeleteUserData: false
        };
      }      
      case ON_SHOW_LOADER_GET_DELETE_USER_DATA: {
        return {
          ...state,
          loaderGetDeleteUserData: true
        };
      }
      case ON_HIDE_LOADER_GET_DELETE_USER_DATA: {
        return {
          ...state,
          loaderGetDeleteUserData: false
        };
      } 
      default:
        return state;
  }
}