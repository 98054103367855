import { all, takeEvery, call, fork, put } from 'redux-saga/effects';
import {
  getDealersSuccess,
  getUsersDefaultSuccess,
  createDealerAccountSuccess,
  addDealerDataSuccess,
  hideLoader,
  hideLoaderUsersDefault,
  hideLoaderCreateDealerAccount,
  hideLoaderAddDealerData
} from 'actions/Dealers';
import {
  GET_DEALERS_REQUEST,
  GET_USERS_DEFAULT_DEALERS_REQUEST,
  CREATE_DEALER_ACCOUNT_REQUEST,
  ADD_DEALER_DATA_REQUEST
} from 'constants/ActionTypes';
import { rsf, firebase, auth } from '../firebase/firebase';
// import { rsf, db, auth } from '../firebase/firebase';
// import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";


function* getDealer() {
  try {
    const colRef = firebase.firestore().collection('DealersThailand');
    // const colRef = query(collection(db, 'DealersThailand'));
    const snapshot = yield call(rsf.firestore.getCollection, colRef);
    // const snapshot = yield call(
    //   rsf.firestore.getCollection,
    //   colRef.where('active', '==', true)
    // );
    //console.log('items:' + snapshot);
    let items = [];
    snapshot.forEach(doc => {
      //console.log(doc.data());
      var data = doc.data();
      data['id'] = data.dealerCode;
      data['friendsCount'] = data.friends.length;
      //console.log('T:' + data.friends.length);
      if ((data.dealerCode !== '988888') && (data.dealerCode !== '999999')) {
        items.push(data);
      }

      // datas = {
      //   id: doc.id,
      //   ...doc.data()
      // };
    });
    console.log('To:' + items[1].friendsCount);
    yield put(getDealersSuccess(items));
  } catch (error) {
    console.log(error);
    yield put(hideLoader());
  }
}

function* getUsersDefault({ payload }) {
  //const { dealerCode } = payload;
  //console.log('ddddealerCode:' + dealerCode);

  //console.log('Call getActiveUsersAll Sagas');

  try {
    const colRef = firebase.firestore().collection('ActiveUsersThailand');
    //const colRef = query(collection(db, 'ActiveUsersThailand'));

    const snapshot = yield call(rsf.firestore.getCollection, colRef);

    // const snapshot = yield call(
    //   rsf.firestore.getCollection,
    //   //colRef.where('dealerCode', '==', dealerCode)
    // );
    
    //console.log('items:' + snapshot);
    let items = [];
    snapshot.forEach((doc) => {
      //console.log(doc.data());
      var data = doc.data();
      data['id'] = data.dealerCode;
      items.push(data);

      // datas = {
      //   id: doc.id,
      //   ...doc.data()
      // };
    });
    items.sort((a, b) => {
      return a.updatedDate > b.updatedDate ? 1 : -1; //ascending
      //return a.dealerCode > b.dealerCode ? -1 : 1; //descending
    });
    console.log('Users Defaults To:' + items.length);
    yield put(getUsersDefaultSuccess(items));
  } catch (error) {
    console.log(error);
    yield put(hideLoaderUsersDefault());
  }
}

const createUserWithEmailPasswordRequest = async (email, password) =>
  await auth
    .createUserWithEmailAndPassword(email, password)
    .then(authUser => authUser)
    .catch(error => error);

function* createDealerAccount({ payload }) {
  const { email, password } = payload;
  console.log(JSON.stringify(payload));
  //console.log('emaila:' + email + ' passa:' + password);
  // try {
  //   const signUpDealer = yield call(
  //     createUserWithEmailPasswordRequest,
  //     email,
  //     password
  //   );
  //   if (signUpDealer.message) {
  //     //yield put(showAuthMessage(signUpUser.message));
  //     console.log('Create Dealer Error:' + signUpDealer.message);
  //     yield put(hideLoaderCreateDealerAccount);
  //   } else {
  //     //localStorage.setItem('user_id', signUpUser.user.uid);
  //     yield put(createDealerAccountSuccess(signUpDealer.user.uid));
  //   }
  // } catch (error) {
  //   console.log('Create Dealer Error Catch:' + error);
  //   yield put(hideLoaderCreateDealerAccount);
  //   //yield put(showAuthMessage(error));
  // }
}

function* addDealerData({ payload }) {}

export function* watchGetDealersRequest() {
  yield takeEvery(GET_DEALERS_REQUEST, getDealer);
}
export function* watchGetUsersDefaultRequest() {
  yield takeEvery(GET_USERS_DEFAULT_DEALERS_REQUEST, getUsersDefault);
}
export function* watchCreateDealerAccountRequest() {
  yield takeEvery(CREATE_DEALER_ACCOUNT_REQUEST, createDealerAccount);
}
export function* watchAddDealerDataRequest() {
  yield takeEvery(ADD_DEALER_DATA_REQUEST, addDealerData);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetDealersRequest),
    fork(watchGetUsersDefaultRequest),
    fork(watchCreateDealerAccountRequest),
    fork(watchAddDealerDataRequest)
  ]);
}
