import {
    all,
    call,
    fork,
    put,        
    takeEvery,    
  } from 'redux-saga/effects'; //take, select, takeLatest
  import {
    getContactUsSuccess,
    hideLoaderGetContactUs
  } from 'actions/ContactUs';
  import {
    GET_CONTACT_US_REQUEST,        
  } from 'constants/ActionTypes'; //ON_SHOW_LOADER_GET_CONTACT_US, ON_HIDE_LOADER_GET_CONTACT_US
  
  //import { database } from '../firebase/firebase';
  import { rsf, firebase } from '../firebase/firebase';
  // import { rsf, db } from '../firebase/firebase';
  // import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";

  function* getContactUs({ payload }) {
    const { startDate, endDate } = payload;
    const startDateStr = startDate + '000000';
    const endDateStr = endDate + '595959';
    console.log('ContactUs get call');
    console.log('ContactUsStartDateStr:' + startDate + ' ContactUsEndDateStr:' + endDate);
    //.where('date', '==', '20200117')
    //yield put(ON_SHOW_LOADER);
    //yield put(showLoader());
    try {
      
      const colRef = firebase.firestore().collection('ContactUsThailand');
      //const colRef = query(collection(db, 'ContactUsThailand'));

      //    const snapshot = yield call(rsf.firestore.getCollection, colRef);
      //colRef.where('date', '==', '20200117')
  
      // const snapshot = yield call(
      //   rsf.firestore.getCollection,
      //   colRef.where('date', '>=', startDate)
      // );
      // const snapshot = yield call(
      //   rsf.firestore.getCollection,
      //   colRef.where('date', '>=', startDate) ||
      //     colRef.where('date', '<=', endDate)
      // );
      const snapshot = yield call(
        rsf.firestore.getCollection,
        colRef.where('createdAt', '>=', startDateStr).where('createdAt', '<=', endDateStr)
      );
  
      //colRef.where('date', '>=', startDate).where('date', '<=', endDate);
      //console.log('items:' + snapshot);
      let items = [];
      snapshot.forEach(doc => {
        //console.log(doc.data());
        var data = doc.data();
        data['id'] = data.date;
        items.push(data);
        // datas = {
        //   id: doc.id,
        //   ...doc.data()
        // };
      });
      //var json = JSON.parse(snapshot);
      console.log('CONTACT:' + items.length);
      //const snapshot = ['A', 'B', 'C'];
      //yield put(hideLoader());
      yield put(getContactUsSuccess(items));
      //yield put({ type: 'ON_HIDE_LOADER_GET_ANALYTIC_USERS' });
    } catch (error) {
      console.log(error);
      //yield put(showMessage(error));
      //yield put({ type: 'ON_HIDE_LOADER_GET_ANALYTIC_USERS' });
      yield put(hideLoaderGetContactUs());
    }
  }

  export function* watchContactUsRequest() {
    //yield takeLatest(GET_REGISTER_USER_COUNT, getRegister);
    //yield fork(getRegister); // This is working
    //yield take(getRegister);
    //yield take(GET_REGISTER_USER_COUNT, getRegister);

    //yield takeLatest(GET_ANALYTIC_USERS_REQUEST, getAnalyticUsers); // This is working but refresh all the time
    yield takeEvery(GET_CONTACT_US_REQUEST, getContactUs); // This is working but refresh all the time*
  
    //yield call(getRegister);
  }
  
  export default function* rootSaga() {
    yield all([fork(watchContactUsRequest)]);
    //yield all([call(fetchRegisterUserCount)]);
    //yield all([takeEvery(GET_REGISTER_USER_COUNT, fetchRegisterUserCount)]);
  }