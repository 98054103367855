import {
    GET_ANALYTIC_USERS_REQUEST,
    GET_ANALYTIC_USERS_SUCCESS,
    ON_SHOW_LOADER_GET_ANALYTIC_USERS,
    ON_HIDE_LOADER_GET_ANALYTIC_USERS   
  } from 'constants/ActionTypes';

  export const getAnalyticUsersRequest = (startDate, endDate) => {
    return {
      type: GET_ANALYTIC_USERS_REQUEST,
      payload: {
        startDate: startDate,
        endDate: endDate
      }
    };
  };
  export const getAnalyticUsersSuccess = items => {
    return {
      type: GET_ANALYTIC_USERS_SUCCESS,
      payload: items
    };
  };
  export const showLoaderGetAnalyticUsers = () => {
    return {
      type: ON_SHOW_LOADER_GET_ANALYTIC_USERS
    };
  };
  export const hideLoaderGetAnalyticUsers = () => {
    return {
      type: ON_HIDE_LOADER_GET_ANALYTIC_USERS
    };
  };