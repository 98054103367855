import {
    GET_API_USER_SUCCESS,
    GET_USER_BY_OLD_PHONE_SUCCESS,
    GET_USER_BY_NEW_PHONE_SUCCESS,
    ON_SHOW_LOADER_API_USER,
    ON_HIDE_LOADER_API_USER,
    ON_SHOW_LOADER_OLD_PHONE,
    ON_HIDE_LOADER_OLD_PHONE,
    ON_SHOW_LOADER_NEW_PHONE,
    ON_HIDE_LOADER_NEW_PHONE,
    CHANGE_PHONE_SUCCESS,
    ON_SHOW_LOADER_CHANGE_PHONE,
    ON_HIDE_LOADER_CHANGE_PHONE,
    SHOW_MESSAGE_CHANGE_PHONE,
    HIDE_MESSAGE_CHANGE_PHONE,
    RESET_CHANGE_PHONE
} from 'constants/ActionTypes';

const INIT_STATE = {
    loaderApiUser: false,
    loaderUserByOldPhone: false,
    loaderUserByNewPhone: false,
    loaderChangePhone: false,
    apiUserItems: null,
    oldPhoneUser: null,
    newPhoneUser: null,
    isPhoneUsing: false,
    isMessageChangePhone: false,
    changePhoneCode: ''    
  };
  
  export default function changePhone(state = INIT_STATE, action) {
    switch (action.type) {
      case GET_API_USER_SUCCESS: {
        return {
          ...state,
          loaderApiUser: false,
          apiUserItems: action.payload
        };
      }
      case GET_USER_BY_OLD_PHONE_SUCCESS: {
        return {
          ...state,
          loaderUserByOldPhone: false,
          oldPhoneUser: action.payload
        };
      }
      case GET_USER_BY_NEW_PHONE_SUCCESS: {
        return {
          ...state,
          loaderUserByNewPhone: false,
          newPhoneUser: action.payload
          //isPhoneUsing: action.payload
        };
      }
      case ON_SHOW_LOADER_API_USER: {
        return {
          ...state,
          loaderApiUser: true
        };
      }
      case ON_HIDE_LOADER_API_USER: {
        return {
          ...state,
          loaderApiUser: false
        };
      }
      case ON_SHOW_LOADER_OLD_PHONE: {
        return {
          ...state,
          loaderUserByOldPhone: true
        };
      }
      case ON_HIDE_LOADER_OLD_PHONE: {
        return {
          ...state,
          loaderUserByOldPhone: false
        };
      }
      case ON_SHOW_LOADER_NEW_PHONE: {
        return {
          ...state,
          loaderUserByNewPhone: true
        };
      }
      case ON_HIDE_LOADER_NEW_PHONE: {
        return {
          ...state,
          loaderUserByNewPhone: false
        };
      }
      case CHANGE_PHONE_SUCCESS: {
        return {
          ...state,
          loaderApiUser: false,
          loaderUserByOldPhone: false,
          loaderUserByNewPhone: false,
          loaderChangePhone: false,
          apiUserItems: null,
          oldPhoneUser: null,
          newPhoneUser: null,
          isPhoneUsing: false,
          changePhoneCode: action.payload //100
        };
      }
      case ON_SHOW_LOADER_CHANGE_PHONE: {
        return {
          ...state,
          loaderChangePhone: true
        };
      }
      case ON_HIDE_LOADER_CHANGE_PHONE: {
        return {
          ...state,
          loaderChangePhone: false
        };
      }
      case SHOW_MESSAGE_CHANGE_PHONE: {
        return {
          ...state,
          isMessageChangePhone: true,
          //changePhoneCode: action.payload
        }
      }
      case HIDE_MESSAGE_CHANGE_PHONE: {
        return {
          ...state,
          isMessageChangePhone: false,
          changePhoneCode: ''
        }
      }
      case RESET_CHANGE_PHONE: {
        return {
          ...state,
          loaderApiUser: false,
          loaderUserByOldPhone: false,
          loaderUserByNewPhone: false,
          loaderChangePhone: false,
          apiUserItems: null,
          oldPhoneUser: null,
          newPhoneUser: null,
          isPhoneUsing: false,
          changePhoneCode: ''
        };
      }
      default:
        return state;
  }
}