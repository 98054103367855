import {
  GET_REWARDS_REQUEST,
  GET_REWARDS_SUCCESS,
  GET_REWARD_BY_ID_REQUEST,
  GET_REWARD_BY_ID_SUCCESS,
  CREATE_REWARD_REQUEST,
  CREATE_REWARD_SUCCESS,
  UPDATE_REWARD_REQUEST,
  UPDATE_REWARD_SUCCESS,
  DELETE_REWARD_REQUEST,
  DELETE_REWARD_SUCCESS,
  CLEAR_REWARD_ITEM,
  ON_SHOW_LOADER_GET_REWARDS,
  ON_HIDE_LOADER_GET_REWARDS,
  ON_SHOW_LOADER_GET_REWARD_BY_ID,
  ON_HIDE_LOADER_GET_REWARD_BY_ID,
  ON_SHOW_LOADER_CREATE_REWARD,
  ON_HIDE_LOADER_CREATE_REWARD,
  ON_SHOW_LOADER_UPDATE_REWARD,
  ON_HIDE_LOADER_UPDATE_REWARD,
  ON_SHOW_LOADER_DELETE_REWARD,
  ON_HIDE_LOADER_DELETE_REWARD,
  SHOW_MESSAGE_REWARD,
  HIDE_MESSAGE_REWARD,
  ON_SHOW_REFRESH_REWARD,
  ON_HIDE_REFRESH_REWARD
} from 'constants/ActionTypes';

export const getRewardsRequest = () => ({
  type: GET_REWARDS_REQUEST
});
export const getRewardsSuccess = items => ({
  type: GET_REWARDS_SUCCESS,
  payload: items
});

export const getRewardByIdRequest = rewardid => ({
  type: GET_REWARD_BY_ID_REQUEST,
  payload: {
    rewardid: rewardid
  }
});
export const getRewardByIdSuccess = item => ({
  type: GET_REWARD_BY_ID_SUCCESS,
  payload: item
});

export const createRewardRequest = payload => ({
  type: CREATE_REWARD_REQUEST,
  payload: payload
});
export const createRewardSuccess = rewardid => ({
  type: CREATE_REWARD_SUCCESS,
  payload: rewardid
});

export const updateRewardRequest = payload => ({
  type: UPDATE_REWARD_REQUEST,
  payload: payload
});
// export const updateRewardRequest = (
//   rewardid,
//   imgSrc,
//   order,
//   productCode,
//   titleEn,
//   titleTh,
//   detailEn,
//   detailTh,
//   points,
//   isActive
// ) => ({
//   type: UPDATE_REWARD_REQUEST,
//   payload: {
//     rewardid: rewardid,
//     imgSrc: imgSrc,
//     order: order,
//     productCode: productCode,
//     titleEn: titleEn,
//     titleTh: titleTh,
//     detailEn: detailEn,
//     detailTh: detailTh,
//     points: points,
//     isActive: isActive
//   }
// });
export const updateRewardSuccess = () => ({
  type: UPDATE_REWARD_SUCCESS
});

export const deleteRewardRequest = (rewardid, deletePath) => ({
  type: DELETE_REWARD_REQUEST,
  payload: {
    rewardid: rewardid,
    deletePath: deletePath
  }
});
export const deleteRewardSuccess = () => ({
  type: DELETE_REWARD_SUCCESS
});

export const clearRewardItem = () => ({
  type: CLEAR_REWARD_ITEM
});

export const showLoaderGetRewards = () => {
  return {
    type: ON_SHOW_LOADER_GET_REWARDS
  };
};
export const hideLoaderGetRewards = () => {
  return {
    type: ON_HIDE_LOADER_GET_REWARDS
  };
};

export const showLoaderGetRewardById = () => {
  return {
    type: ON_SHOW_LOADER_GET_REWARD_BY_ID
  };
};
export const hideLoaderGetRewardById = () => {
  return {
    type: ON_HIDE_LOADER_GET_REWARD_BY_ID
  };
};
export const showLoaderCreateReward = () => {
  return {
    type: ON_SHOW_LOADER_CREATE_REWARD
  };
};
export const hideLoaderCreateReward = () => {
  return {
    type: ON_HIDE_LOADER_CREATE_REWARD
  };
};
export const showLoaderUpdateReward = () => {
  return {
    type: ON_SHOW_LOADER_UPDATE_REWARD
  };
};
export const hideLoaderUpdateReward = () => {
  return {
    type: ON_HIDE_LOADER_UPDATE_REWARD
  };
};
export const showLoaderDeleteReward = () => {
  return {
    type: ON_SHOW_LOADER_DELETE_REWARD
  };
};
export const hideLoaderDeleteReward = () => {
  return {
    type: ON_HIDE_LOADER_DELETE_REWARD
  };
};
export const showMessageReward = message => {
  return {
    type: SHOW_MESSAGE_REWARD,
    payload: message
  };
};
export const hideMessageReward = () => {
  return {
    type: HIDE_MESSAGE_REWARD
  };
};
export const showRefreshReward = () => {
  return {
    type: ON_SHOW_REFRESH_REWARD
  };
};
export const hideRefreshReward = () => {
  return {
    type: ON_HIDE_REFRESH_REWARD
  };
};
