import {
  all,
  call,
  fork,
  put,
  take,
  select,
  takeEvery,
  takeLatest
} from 'redux-saga/effects';
import {
  //getRegisterUserCount,
  getRegisterUserCountSuccess,
  hideLoader
} from 'actions/RegisterUserCount';
import {
  GET_REGISTER_USER_COUNT_REQUEST,
  ON_SHOW_LOADER,
  ON_HIDE_LOADER
} from 'constants/ActionTypes';

import { database } from '../firebase/firebase';
import { rsf, firebase } from '../firebase/firebase';

// import { rsf, firebase, db } from '../firebase/firebase';
// import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";

//import { firestoreConnect } from 'react-redux-firebase';
//me use
//import { reactReduxFirebase, getFirebase } from 'react-redux-firebase';
//end use

// const getRegisterUser = async (month, year) =>
//   await db
//     .colletion('RegisterUsersThailand')
//     .get()
//     .then(snapshot => {
//       if (snapshot.empty) {
//         console.log('No such document!');
//       } else {
//         // do stuff  with data
//         const datas = [];
//         snapshot.forEach(doc => {
//           //datas.push(doc.data().day);
//           console.log('day:' + doc.data().day);
//           datas.push(doc.data());
//         });
//         return datas;
//       }
//     })
//     .catch(error => error);

// const getRegisterUser = async (month, year) =>
//   await rsf

// function* getRegister2() {
//   const db = firebase.firestore();
//   db.collection('RegisterUsersThailand')
//   .get()
//   .then(snapshot => {
//     if (snapshot.empty) {
//       console.log('No such document!');
//     } else {
//       // do stuff  with data

//       console.log(
//         'Num of Register User:' + snapshot.docs.length
//       );
//       let datas = [];
//       snapshot.forEach(doc => {
//         console.log(doc.data());
//         var data = doc.data();
//         data['id'] = data.date;
//         datas.push(data);
//       });
//       yield put(getRegisterUserCount(datas));
//     }
//   }).catch (err => {
//     console.log(err);
//     //yield put(showMessage(error));
//     yield put({ type: 'ON_HIDE_LOADER' });
//   });
// }

function* getRegister({ payload }) {
  const { startDate, endDate } = payload;
  //console.log('get call');
  //console.log('SAGAstartDateStr:' + startDate + ' SAGAendDateStr:' + endDate);
  //.where('date', '==', '20200117')
  //yield put(ON_SHOW_LOADER);
  //yield put(showLoader());
  try {
    //const snapshot = yield call(getRegisterUser, month, year);

    const colRef = firebase.firestore().collection('RegisterUsersThailand');
    //const colRef = db.collection('RegisterUsersThailand');
    //const colRef = query(collection(db, 'RegisterUsersThailand'));
    //const colRef = query(collection(db, 'RegisterUsersThailand'), where('date', '>=', startDate), where('date', '<=', endDate));
    //const colRef = getDocs(collection(db, 'RegisterUsersThailand'), where('date', '>=', startDate), where('date', '<=', endDate));
    //const colRef = collection(db, 'RegisterUsersThailand');
    //const colRef = getFirestore().collection('RegisterUsersThailand').where('date', '>=', startDate).where('date', '<=', endDate);
    //const colRef = db.collection('RegisterUsersThailand');

    //rsf.firestore.getCollection
    //const snapshot = yield call(rsf.firestore.getCollection, colRef); // rsf.firestore.getCollection

    //    const snapshot = yield call(rsf.firestore.getCollection, colRef);
    //colRef.where('date', '==', '20200117')

    // const snapshot = yield call(
    //   rsf.firestore.getCollection,
    //   colRef.where('date', '>=', startDate)
    // );
    // const snapshot = yield call(
    //   rsf.firestore.getCollection,
    //   colRef.where('date', '>=', startDate) ||
    //     colRef.where('date', '<=', endDate)
    // );

    const snapshot = yield call(
      rsf.firestore.getCollection,
      colRef.where('date', '>=', startDate).where('date', '<=', endDate)
    );

    
    //console.log('items:' + JSON.stringify(snapshot));
    
    let items = [];
    snapshot.forEach(doc => {
      //console.log(doc.data());
      var data = doc.data();
      data['id'] = data.date;
      items.push(data);
      // datas = {
      //   id: doc.id,
      //   ...doc.data()
      // };
    });
    //var json = JSON.parse(snapshot);
    console.log('TCOUNT:' + items.length);
    //const snapshot = ['A', 'B', 'C'];
    //yield put(hideLoader());
    yield put(getRegisterUserCountSuccess(items));
    //yield put({ type: 'ON_HIDE_LOADER' });
  } catch (error) {
    console.log(error);
    //yield put(showMessage(error));
    //yield put({ type: 'ON_HIDE_LOADER' });
    yield put(hideLoader());
  }
}

export function* watchRegisterUserCountRequest() {
  //yield takeLatest(GET_REGISTER_USER_COUNT, getRegister);
  //yield fork(getRegister); // This is working
  //yield take(getRegister);
  //yield take(GET_REGISTER_USER_COUNT_REQUEST, getRegister);
  //yield takeLatest(GET_REGISTER_USER_COUNT_REQUEST, getRegister); // This is working but refresh all the time
  yield takeEvery(GET_REGISTER_USER_COUNT_REQUEST, getRegister); // This is working but refresh all the time*

  //yield call(getRegister);
}

// export default function* mySaga() {
//   yield takeLatest(REQUEST_HELLO_WORLD, helloWorld);
// }

export default function* rootSaga() {
  yield all([fork(watchRegisterUserCountRequest)]);
  //yield all([call(fetchRegisterUserCount)]);
  //yield all([takeEvery(GET_REGISTER_USER_COUNT, fetchRegisterUserCount)]);
}
