import { all, takeEvery, call, fork, put } from 'redux-saga/effects';
import {
    getApiUserSuccess,
    getUserByOldPhoneSuccess,
    getUserByNewPhoneSuccess,
    hideApiUserLoader,
    hideOldPhoneLoader,
    hideNewPhoneLoader,
    changePhoneSuccess,
    hideChangePhoneLoader,
    showMessageChangePhone,
    resetChangePhone
} from 'actions/ChangePhone';
import {
    GET_API_USER_REQUEST,
    GET_USER_BY_OLD_PHONE_REQUEST,
    GET_USER_BY_NEW_PHONE_REQUEST,
    CHANGE_PHONE_REQUEST
} from 'constants/ActionTypes';
import { rsf, firebase, auth } from '../firebase/firebase';
//import { rsf, db } from '../firebase/firebase';
//import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import axios from "axios";
import https from "https";

// const checkPhoneNumberIsUsingRequest = async (phoneNumber) =>
//   await app.auth()
//     .getUserByPhoneNumber(phoneNumber)
//     .then((authUser) => authUser)
//     .catch((error) => error);


export const fetchUser = (postParams) => {
    // return axios ({
    //     method: 'get',
    //     headers: { 
    //         'Content-Type': 'application/json',
    //         'Access-Control-Allow-Origin': '*',
    //         'Access-Control-Allow-Credentials' : 'true',
    //         'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
    //         'Access-Control-Allow-Headers' : 'Origin, Content-Type, Accept'
    //     },
    //     url: url
    // }).then( resp => resp.data );

    // return axios.post(url, 
    //     {
    //         headers: { 
    //             'Content-Type': 'application/json',
    //             'Access-Control-Allow-Origin': '*',
    //             'Access-Control-Allow-Credentials' : 'true',
    //             'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
    //             'Access-Control-Allow-Headers' : 'Origin, Content-Type, Accept'
    //         }
    //     }).then(function (response) {
    //         console.log(response);
    //     });

    

    const agent = new https.Agent({
      rejectUnauthorized: false,
    });

    axios.defaults.options = agent

    return axios
    .post("https://34.96.201.193/getUserFromApi", postParams,  { httpsAgent: agent } )
    .then(response => {
      console.log("response: ", response)
      // do something about response
    })
    .catch(err => {
      console.error(err)
    })
}


// function fetchUser2(postParams) {
//   var getUser = firebase.functions().httpsCallable('getUserMyCar');
//   return getUser(postParams).then(function(result) {
//       return result;
//   }).catch(function(error) {
//       return error;
//   }); 
// }


//https://stackoverflow.com/questions/52365937/react-redux-saga-api-call
//https://stackoverflow.com/questions/47499340/cant-yield-json-response-from-server-with-redux-saga
function* getApiUser({ payload }) {
    const { newPhone, vinNumber } = payload;
    //console.log('newP:' + newPhone);
    //console.log('vinN:' + vinNumber);
    try {
        //const url = 'https://integration.mycardms.com/api_smt/api/SE_CUSTOMER_MAST?VIN=' + vinNumber + '&Tel=' +
        //newPhone +
        //'&Token=9601CCE489A997A7A659C4B175C49D5EB6FB17775CCFA4AEBF7DE894758169F4';

        //const url = 'https://34.96.201.193:8000/getUserFromApi';
        //const url = 'http://34.96.201.193:3000/getUserFromApi';
        //const url = 'https://34.96.201.193:8000/getUserFromApi'
        //const url = 'https://127.0.0.1:8000/getUserFromApi';
        //const url = 'http://127.0.0.1:3001/getUserFromApi';
        //const url = 'https://34.96.201.193/getUserFromApi';
        const url = 'https://127.0.0.1/getUserFromApi';
        //const body = '{}'
        var formData = new FormData();
        formData.append('vinnumber', vinNumber);
        formData.append('newphone', newPhone);

        // const json = yield fetch(url, {
        //   method: 'POST',          
        //   body: formData
        // });

        // const postParams = {
        //   vinnumber: vinNumber,
        //   newphone: newPhone
        // };

        const postParams = {
          vinNumber: vinNumber,
          newPhone: newPhone
        };

        const headerParams = {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        };

        const agent = new https.Agent({
          rejectUnauthorized: false,
        }); 

        axios.defaults.options = agent

        //const json = yield call(axios.post, url, postParams, {headers:headerParams, httpsAgent: agent});

        //const json = yield call(axios.post, url, postParams, {headers:headerParams}); //use this one

        rsf.region = 'asia-east2';
        //const json = yield call(rsf.functions.call, 'getUserMyCar', postParams);

        //https://asia-east2-hello-suzuki.cloudfunctions.net/getUserMyCar?vinNumber=MMSCVC31S00138144&newPhone=0982693295

        
        const json = yield call(rsf.functions.call, 'getUserMyCar', postParams);
        
        //const json = yield call(rsf.functions.call, 'testFunction1', postParams, { method: 'POST' });

        //const json = yield firebase.functions('asia-east2').httpsCallable('getUserMyCar', postParams);

        //const json = yield call(rsf.functions.call, firebase.functions('asia-east2').httpsCallable('getUserMyCar'), postParams);


        //https://integration.mycardms.com/api_smt/api/SE_CUSTOMER_MAST?VIN=MMSHZC72S00164481&Tel=0874314221&Token=9601CCE489A997A7A659C4B175C49D5EB6FB17775CCFA4AEBF7DE894758169F4


        // const postParams = {
        //   VIN: vinNumber,
        //   Tel: newPhone,
        //   Token: '9601CCE489A997A7A659C4B175C49D5EB6FB17775CCFA4AEBF7DE894758169F4'
        // };

        // const json = yield call(axios.get, 'https://integration.mycardms.com/api_smt/api/SE_CUSTOMER_MAST', postParams, {headers:headerParams});

        //const json = yield call(fetchUser2(postParams));

        // const json = yield fetch(url, {
        //     method: 'GET',
        //     headers: { 
        //         'Content-Type': 'application/json',
        //         'Access-Control-Allow-Origin': '*',
        //         'Access-Control-Allow-Credentials' : 'true',
        //         'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
        //         'Access-Control-Allow-Headers' : 'Origin, Content-Type, Accept'
        //     }
        // }).then(response => response.json(), );

        // const json = yield call(axios.get, url, {
        //     headers: { 
        //         'Content-Type': 'application/json',
        //         'Access-Control-Allow-Origin': 'http://localhost:3000',
        //         'Access-Control-Allow-Credentials' : 'true',
        //         'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
        //         'Access-Control-Allow-Headers' : 'Origin, Content-Type, Accept'
        //     }
        // }); //.then(response => response.data);

        // let res = yield call(fetch, url, {method: 'GET', mode: 'no-cors', 
        //     headers: { 
        //     'Content-Type': 'application/json',
        //     'Access-Control-Allow-Origin': '*',
        //     'Access-Control-Allow-Headers' : 'Origin, Content-Type,  X-Requested-With, Accept'
        //     }

        // });
        
        //let res  =  yield call(fetch, url, {mode: 'no-cors'});
        //let json =  yield call([res, res.json]);
        //res.setHeader('Access-Control-Allow-Origin', 'localhost:3000');
        //let json =  yield call([res, 'json']);
        //let json = yield res.json();

        //const json = yield call(fetchUser(url));
        //var json = JSON.parse(bodyReturn);
        // json.forEach((doc) => {
        //   console.log('CUS:' + doc.CUSTOMER_NAME);
        // });
        // json.forEach(function (value) {
        //   console.log('CUS:' + value.CUSTOMER_NAME);
        // });
        
        //console.log(JSON.stringify(json, null, "  ")); 
        yield put(getApiUserSuccess(json));
        //yield put(getApiUserSuccess(json.data));
      } catch (error) {
        console.log(error);
        yield put(hideApiUserLoader());
      }
}

function* getUsersByOldPhone({ payload }) {
    const { oldPhone } = payload;
    try {
      const colRef = firebase.firestore().collection('UsersThailand');
      //const colRef = query(collection(db, 'UsersThailand'));
      const snapshot = yield call(
        rsf.firestore.getCollection,
        colRef
            .where('phone', '==', oldPhone)
            .where('isHaveCar', '==', true)
      );
      //console.log('items:' + snapshot);
      let items = [];
      snapshot.forEach((doc) => {
        //console.log(doc.data());
        var data = doc.data();
        data['id'] = data.objectId;
        items.push(data);
  
        // datas = {
        //   id: doc.id,
        //   ...doc.data()
        // };
      });
    //   items.sort((a, b) => {
    //     return a.createdAt > b.createdAt ? 1 : -1; //ascending
    //     //return a.dealerCode > b.dealerCode ? -1 : 1; //descending
    //   });
      //console.log('OldTo:' + items.length);
      yield put(getUserByOldPhoneSuccess(items));
    } catch (error) {
      console.log(error);
      yield put(hideOldPhoneLoader());
    }
  }

  function* getUsersByNewPhone({ payload }) {
    const { newPhone } = payload;
    try {

    
      // let pValue = newPhone;
      // let finalPhone = "";
      // if (pValue.length > 0) {
      //   finalPhone = "+66" + pValue.substring(1, pValue.length);
      // }

      // const authUser = yield call(checkPhoneNumberIsUsingRequest, finalPhone);
      // console.log('authUser:' + JSON.stringify(authUser));

      const colRef = firebase.firestore().collection('UsersThailand');
      //const colRef = query(collection(db, 'UsersThailand'));
      const snapshot = yield call(
        rsf.firestore.getCollection,
        colRef
            .where('phone', '==', newPhone)
            .where('isHaveCar', '==', true)
      );
      //console.log('items:' + JSON.stringify(snapshot));
      //console.log('aa:' + snapshot.data());

      if (snapshot.empty) {
        //console.log('empty');
        const postParams = {
          phone: newPhone,
          token: '534d081b38cb068a04c728a10a43f7c1277b5192283a20cf8a0ab5ff33d3f5b78b1ebbff09b28e4c6034a28aca2c5f19c3b4beac1b69773f6d1913ed9d5e3617'
        }
  
        rsf.region = 'asia-east2';
        const json = yield call(rsf.functions.call, 'checkPhoneIsUsing', postParams);
        //console.log('json:' + JSON.stringify(json));
        //console.log('isUsing:' + json.isUsing);

        let items = [];
        let isPhoneUsing = false;
        if (json.isUsing) {
          isPhoneUsing = true;
          let data = {
            objectId: '1',
            fullname: 'เบอร์นี้เคยถูกใช้งานแล้วในระบบ',
            dealerCode: 'This phone number has already been used in the system.',
            chassisNumber: ''
          }
          items.push(data);
        } else {
          isPhoneUsing = false;
        }
        //console.log('ABC');
        yield put(getUserByNewPhoneSuccess(items));
      } else {
        //console.log('no empty');
        let items = [];
        snapshot.forEach((doc) => {
          //console.log(doc.data());
          var data = doc.data();
          data['id'] = data.objectId;
          items.push(data);          
        });
        //let isPhoneUsing = true;
   
        yield put(getUserByNewPhoneSuccess(items));
      }
      
    } catch (error) {
      console.log(error);
      yield put(hideNewPhoneLoader());
    }
  }

  function* changePhone({ payload }) {
    const { userId, newPhone } = payload;
    try {
        //const url = 'https://34.96.201.193:8000/changePhone';
        //const url = 'http://34.96.201.193:3000/changePhone';
        //const url = 'https://34.96.201.193:8000/changePhone';
        //const url = 'https://127.0.0.1:8000/changePhone';
        //const url = 'http://127.0.0.1:3001/changePhone';
        const url = 'https://34.96.201.193/changePhone';

        // const json = yield fetch(url, {
        //   method: 'POST',          
        //   body: formData
        // });

        const postParams = {
          userid: userId,
          newphone: newPhone
        };

        // const headerParams = {
        //   'Content-Type': 'application/json'
        // };

        // const json = yield call(axios.post, url, postParams, {headers:headerParams});

        rsf.region = 'asia-east2';
        const json = yield call(rsf.functions.call, 'changePhone', postParams);

        //console.log('CHAHHHH:' + JSON.stringify(json, null, "  "));
        // console.log('CHAHHHH2:' + JSON.stringify(json.data.STATUS_CODE, null, "  "));
        // console.log('CHAHHHH3:' + JSON.stringify(json, null, "  "));
        //yield put(changePhoneSuccess(json.data.STATUS_CODE));
        yield put(changePhoneSuccess(json.STATUS_CODE));
        yield put(showMessageChangePhone(json.STATUS_CODE));
        //yield put(changePhoneSuccess(json));
    } catch (error) {
      console.log(error);
      yield put(hideChangePhoneLoader());
    }
  }

  function* resetData () {
    yield put(resetChangePhone());
  }

  export function* watchGetApiUserRequest() {
    yield takeEvery(GET_API_USER_REQUEST, getApiUser);
  }
  export function* watchGetUserByOldPhoneRequest() {
    yield takeEvery(GET_USER_BY_OLD_PHONE_REQUEST, getUsersByOldPhone);
  }
  export function* watchGetUserByNewPhoneRequest() {
    yield takeEvery(GET_USER_BY_NEW_PHONE_REQUEST, getUsersByNewPhone);
  }
  export function* watchChangePhoneRequest() {
    yield takeEvery(CHANGE_PHONE_REQUEST, changePhone);
  }
  // export function* watchResetChangePhoneRequest() {
  //   yield takeEvery(RESET_CHANGE_PHONE, resetData);
  // }
  
  export default function* rootSaga() {
    yield all([
      fork(watchGetApiUserRequest),
      fork(watchGetUserByOldPhoneRequest),
      fork(watchGetUserByNewPhoneRequest),
      fork(watchChangePhoneRequest),
      //fork(watchResetChangePhoneRequest)
    ]);
  }