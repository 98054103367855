import {
    GET_DELETE_USER_DATA_REQUEST,
    GET_DELETE_USER_DATA_SUCCESS,
    CREATE_DELETE_USER_DATA_REQUEST,
    CREATE_DELETE_USER_DATA_SUCCESS,
    UPDATE_DELETE_USER_DATA_REQUEST,
    UPDATE_DELETE_USER_DATA_SUCCESS,    
    ON_SHOW_LOADER_DELETE_USER_DATA,
    ON_HIDE_LOADER_DELETE_USER_DATA,
    ON_SHOW_LOADER_GET_DELETE_USER_DATA,
    ON_HIDE_LOADER_GET_DELETE_USER_DATA,
  } from 'constants/ActionTypes';
  
  export const getDeleteUserDataRequest = (userId) => ({
    type: GET_DELETE_USER_DATA_REQUEST,
    payload: userId
  });
  export const getDeleteUserDataSuccess = userData => ({
    type: GET_DELETE_USER_DATA_SUCCESS,
    payload: userData
  });

  export const createDeleteUserDataRequest = (userId, reasonTh, reasonEn, otherReason) => ({
    type: CREATE_DELETE_USER_DATA_REQUEST,
    payload: {
        userId: userId,
        reasonTh: reasonTh,
        reasonEn: reasonEn,
        otherReason: otherReason
    }
  });
  export const createDeleteUserDataSuccess = userId => ({
    type: CREATE_DELETE_USER_DATA_SUCCESS,
    payload: userId
  });
  
  export const updateDeleteUserDataRequest = (updateData) => ({
    type: UPDATE_DELETE_USER_DATA_REQUEST,
    payload: updateData
  });
  export const updateDeleteUserDataSuccess = userId => ({
    type: UPDATE_DELETE_USER_DATA_SUCCESS,
    payload: userId
  });

  export const showLoaderDeleteUserData = () => {
    return {
      type: ON_SHOW_LOADER_DELETE_USER_DATA
    };
  };
  export const hideLoaderDeleteUserData = () => {
    return {
      type: ON_HIDE_LOADER_DELETE_USER_DATA
    };
  };

  export const showLoaderGetDeleteUserData = () => {
    return {
      type: ON_SHOW_LOADER_GET_DELETE_USER_DATA
    };
  };
  export const hideLoaderGetDeleteUserData = () => {
    return {
      type: ON_HIDE_LOADER_GET_DELETE_USER_DATA
    };
  };
  