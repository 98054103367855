import {
    GET_ACTIVE_USERS_ALL_REQUEST,
    GET_ACTIVE_USERS_ALL_SUCCESS,
    ON_SHOW_LOADER_GET_ACTIVE_USERS_ALL,
    ON_HIDE_LOADER_GET_ACTIVE_USERS_ALL,
    GET_USERS_BY_DEFAULT_DEALER_REQUEST,
    GET_USERS_BY_DEFAULT_DEALER_SUCCESS,
    ON_SHOW_LOADER_GET_USERS_BY_DEFAULT_DEALER,
    ON_HIDE_LOADER_GET_USERS_BY_DEFAULT_DEALER,
    UPDATE_ACTIVE_USERS_REQUEST,
    UPDATE_ACTIVE_USERS_SUCCESS,
    // GET_ACTIVE_USERS_BY_DATE_REQUEST,
    // GET_INACTIVE_USERS_BY_DATE_REQUEST,
    // GET_ACTIVE_USERS_BY_DATE_SUCCESS,
    // GET_INACTIVE_USERS_BY_DATE_SUCCESS,
    // ON_SHOW_LOADER_GET_ACTIVE_USERS,
    // ON_SHOW_LOADER_GET_INACTIVE_USERS,
    // ON_HIDE_LOADER_GET_ACTIVE_USERS,
    // ON_HIDE_LOADER_GET_INACTIVE_USERS,    
  } from 'constants/ActionTypes';

  export const getActiveUsersAllRequest = () => ({
    type: GET_ACTIVE_USERS_ALL_REQUEST,    
  });

  export const getActiveUsersAllSuccess = dataItems => ({
    type: GET_ACTIVE_USERS_ALL_SUCCESS,
    payload: dataItems
  });

  export const showLoaderGetActiveUsersAll = () => {
    return {
      type: ON_SHOW_LOADER_GET_ACTIVE_USERS_ALL
    };
  };
  export const hideLoaderGetActiveUsersAll = () => {
    return {
      type: ON_HIDE_LOADER_GET_ACTIVE_USERS_ALL
    };
  };

  export const getUsersByDefaultDealerRequest = (dealerCode) => ({
    type: GET_USERS_BY_DEFAULT_DEALER_REQUEST,
    payload: {
      dealerCode: dealerCode
    }
  });

  export const getUsersByDefaultDealerSuccess = userItems => ({
    type: GET_USERS_BY_DEFAULT_DEALER_SUCCESS,
    payload: userItems
  });

  export const showLoaderGetUsersByDefaultDealer = () => {
    return {
      type: ON_SHOW_LOADER_GET_USERS_BY_DEFAULT_DEALER
    };
  };
  export const hideLoaderGetUsersByDefaultDealer = () => {
    return {
      type: ON_HIDE_LOADER_GET_USERS_BY_DEFAULT_DEALER
    };
  };

  export const updateActiveUsersRequest = (payload) => ({
    type: UPDATE_ACTIVE_USERS_REQUEST,
    payload: payload
    // payload: {
    //   dealerCode: payload.dealerCode,
    //   totalAll: payload.totalAll
    // }
  });

  export const updateActiveUsersSuccess = () => ({
    type: UPDATE_ACTIVE_USERS_SUCCESS
  });

  // export const getActiveUsersByDateRequest = (startDate, endDate) => ({
  //   type: GET_ACTIVE_USERS_BY_DATE_REQUEST,
  //   payload: {
  //     startDate: startDate,
  //     endDate: endDate
  //   }
  // });

  // export const getInActiveUsersByDateRequest = (startDate, endDate) => ({
  //   type: GET_INACTIVE_USERS_BY_DATE_REQUEST,
  //   payload: {
  //     startDate: startDate,
  //     endDate: endDate
  //   }
  // });

  // export const getActiveUserByDateSuccess = activeUserItems => ({
  //   type: GET_ACTIVE_USERS_BY_DATE_SUCCESS,
  //   payload: activeUserItems
  // });

  // export const getInActiveUserByDateSuccess = inactiveUserItems => ({
  //   type: GET_INACTIVE_USERS_BY_DATE_SUCCESS,
  //   payload: inactiveUserItems
  // });

  // export const showLoaderGetActiveUsers = () => {
  //   return {
  //     type: ON_SHOW_LOADER_GET_ACTIVE_USERS
  //   };
  // };
  // export const showLoaderGetInactiveUsers = () => {
  //   return {
  //     type: ON_SHOW_LOADER_GET_INACTIVE_USERS
  //   };
  // };
  // export const hideLoaderGetActiveUsers = () => {
  //   return {
  //     type: ON_HIDE_LOADER_GET_ACTIVE_USERS
  //   };
  // };
  // export const hideLoaderGetInactiveUsers = () => {
  //   return {
  //     type: ON_HIDE_LOADER_GET_INACTIVE_USERS
  //   };
  // };