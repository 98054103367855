import {
    GET_USER_SECOND_HAND_CAR_REQUEST,
    GET_USER_SECOND_HAND_CAR_SUCCESS,
    ON_SHOW_LOADER_GET_USER_SECOND_HAND_CAR,
    ON_HIDE_LOADER_GET_USER_SECOND_HAND_CAR,
    SECOND_HAND_CAR_REQUEST,
    SECOND_HAND_CAR_SUCCESS,
    ON_SHOW_LOADER_SECOND_HAND_CAR,
    ON_HIDE_LOADER_SECOND_HAND_CAR,
    SHOW_MESSAGE_SECOND_HAND_CAR,
    HIDE_MESSAGE_SECOND_HAND_CAR,
    RESET_SECOND_HAND_CAR
} from 'constants/ActionTypes';

export const getUserSecondCarRequest = (phoneNumber, vinNumber) => ({
    type: GET_USER_SECOND_HAND_CAR_REQUEST,
    payload: {
        phoneNumber: phoneNumber,
        vinNumber: vinNumber,
      },
});
export const getUserSecondCarSuccess = items => ({
    type: GET_USER_SECOND_HAND_CAR_SUCCESS,
    payload: items
});
export const showGetUserSecondHandCarLoader = () => {
    return {
      type: ON_SHOW_LOADER_GET_USER_SECOND_HAND_CAR
    };
};
export const hideGetUserSecondHandCarLoader = () => {
    return {
      type: ON_HIDE_LOADER_GET_USER_SECOND_HAND_CAR
    };
};
export const secondHandCarRequest = (userId, vinNumber, isActive) => ({
    type: SECOND_HAND_CAR_REQUEST,
    payload: {
        userId: userId,
        vinNumber: vinNumber,
        isActive: isActive
      },
});
export const secondHandCarSuccess = payload => ({
    type: SECOND_HAND_CAR_SUCCESS,
    payload: payload
});
export const showSecondHandCarLoader = () => {
    return {
      type: ON_SHOW_LOADER_SECOND_HAND_CAR
    };
};
export const hideSecondHandCarLoader = () => {
    return {
      type: ON_HIDE_LOADER_SECOND_HAND_CAR
    };
};
export const showMessageSecondHandCar = message => {
    return {
      type: SHOW_MESSAGE_SECOND_HAND_CAR,
      payload: message
    };
  };
  export const hideMessageSecondHandCar = () => {
    return {
      type: HIDE_MESSAGE_SECOND_HAND_CAR
    };
  };
  export const resetSecondHandCar = () => {
    return {
      type: RESET_SECOND_HAND_CAR
    };
  };