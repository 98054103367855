import { all, takeEvery, call, fork, put } from 'redux-saga/effects';
import {
    getRankingResultServiceSurveySuccess,
    getRankingResultDealerServiceSurveySuccess,
    getRankingResultAreaServiceSurveySuccess,
    hideLoaderRanking
} from 'actions/Ranking';
import {
    GET_RANKING_RESULT_SERVICE_SURVEY_REQUEST,
    GET_RANKING_RESULT_DEALER_SERVICE_SURVEY_REQUEST,
    GET_RANKING_RESULT_AREA_SERVICE_SURVEY_REQUEST
} from 'constants/ActionTypes';
import { rsf, firebase } from '../firebase/firebase';
// import { rsf, db } from '../firebase/firebase';
// import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";

function* getResultServiceSurvey({ payload }) {
  const { month, year } = payload;
  console.log('sagaMonth:' + month + ' sagaYear:' + year);
  try {
    const colRef = firebase
      .firestore()
      .collection('ResultServiceSurveyThailand');
    // const colRef = query(collection(db, 'ResultServiceSurveyThailand'));
    const snapshot = yield call(
      rsf.firestore.getCollection,
      colRef.where('taxMonth', '==', month).where('taxYear', '==', year)
    );
    //console.log('items:' + snapshot);
    let items = [];
    snapshot.forEach(doc => {
      //console.log(doc.data());
      var data = doc.data();
      data['id'] = data.sendSurveyId;
      //data['friendsCount'] = data.friends.length;
      //console.log('T:' + data.friends.length);
      items.push(data);

      // datas = {
      //   id: doc.id,
      //   ...doc.data()
      // };
    });
    //console.log('To:' + items[1].friendsCount);
    yield put(getRankingResultServiceSurveySuccess(items));
  } catch (error) {
    console.log(error);
    yield put(hideLoaderRanking());
  }
}


function* getResultDealerServiceSurvey({ payload }) {
  const { month, year } = payload;
  console.log('sagaMonthDealer:' + month + ' sagaYearDealer:' + year);
  try {
    const colRef = firebase
      .firestore()
      .collection('ResultDealerServiceSurveyThailand');
    // const colRef = query(collection(db, 'ResultDealerServiceSurveyThailand'));
    const snapshot = yield call(rsf.firestore.getCollection, colRef.where('taxMonth', '==', month).where('taxYear', '==', year));
    
    //colRef.where('taxYear', '==', year)
    //colRef.where('taxMonth', '==', '01').where('taxYear', '==', '2020')
    //console.log('items:' + snapshot);
    let items = [];
    snapshot.forEach(doc => {
      //console.log(doc.data());
      var data = doc.data();
      data['id'] = data.taxYear + '-' + data.taxMonth + '-' + data.dealerCode;
      items.push(data);

      // datas = {
      //   id: doc.id,
      //   ...doc.data()
      // };
    });
    items.sort((a, b) => {
      return a.docId > b.docId ? 1 : -1; //ascending
      //return a.docId > b.docId ? -1 : 1; //descending
    });
    //console.log('To:' + items[1].friendsCount);
    yield put(getRankingResultDealerServiceSurveySuccess(items));
  } catch (error) {
    console.log(error);
    yield put(hideLoaderRanking());
  }
}


function* getResultAreaServiceSurvey({ payload }) {
  const { month, year } = payload;
  //console.log('sagaMonthArea:' + month + ' sagaYearArea:' + year);
  try {
    const colRef = firebase
      .firestore()
      .collection('ResultAreaServiceSurveyThailand');
    // const colRef = query(collection(db, 'ResultAreaServiceSurveyThailand'));
    const snapshot = yield call(rsf.firestore.getCollection, colRef.where('taxMonth', '==', month).where('taxYear', '==', year));
    //colRef.where('taxYear', '==', year)
    //colRef.where('taxMonth', '==', month).where('taxYear', '==', year)
    //colRef.where('taxMonth', '==', '01').where('taxYear', '==', '2020')
    //console.log('items:' + snapshot);
    let items = [];
    snapshot.forEach(doc => {
      //console.log(doc.data());
      var data = doc.data();
      data['id'] = data.docId;
      items.push(data);

      // datas = {
      //   id: doc.id,
      //   ...doc.data()
      // };
    });
    //console.log('To:' + items[1].friendsCount);
    items.sort((a, b) => {
      return a.docId > b.docId ? 1 : -1; //ascending
      //return a.docId > b.docId ? -1 : 1; //descending
    });
    yield put(getRankingResultAreaServiceSurveySuccess(items));
  } catch (error) {
    console.log(error);
    yield put(hideLoaderRanking());
  }
}

export function* watchGetRankingResultServiceSurveyRequest() {
  yield takeEvery(GET_RANKING_RESULT_SERVICE_SURVEY_REQUEST, getResultServiceSurvey);
}

export function* watchGetRankingResultDealerServiceSurveyRequest() {
  yield takeEvery(
    GET_RANKING_RESULT_DEALER_SERVICE_SURVEY_REQUEST,
    getResultDealerServiceSurvey
  );
}

export function* watchGetRankingResultAreaSurveyRequest() {
  yield takeEvery(
    GET_RANKING_RESULT_AREA_SERVICE_SURVEY_REQUEST,
    getResultAreaServiceSurvey
  );
}


export default function* rootSaga() {
  yield all([
    fork(watchGetRankingResultServiceSurveyRequest),
    fork(watchGetRankingResultDealerServiceSurveyRequest),
    fork(watchGetRankingResultAreaSurveyRequest)
  ]);
}
