import React from "react";
import ContainerHeader from "components/ContainerHeader";
import IntlMessages from "util/IntlMessages";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

class AddDealerData extends React.Component {
  render() {
    let role = localStorage.getItem("role");
    if (role !== "superAdmin") {
      if (role === "smt" || role.length === 6) {
        return <Redirect to={"/app/dashboard/register-users"} />;
      } else {
        return <Redirect to={"/app/customers/request-delete"} />;
      }
    }

    return (
      <div className="app-wrapper">
        <ContainerHeader
          match={this.props.match}
          title={<IntlMessages id="pages.adddealerdata" />}
        />
      </div>
    );
  }
}

export default AddDealerData;
