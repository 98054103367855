import React from "react";
import ContainerHeader from "components/ContainerHeader/index";
import IntlMessages from "util/IntlMessages";
import { connect } from "react-redux";
import { showLoader, getDealersRequest } from "actions/Dealers";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CardBox from "components/CardBox";
import Button from "@material-ui/core/Button";
//import ReactExport from "react-data-export";
import XLSX from "@sheet/chart"; // Pro Basic + Pro Chart
import moment from "moment-timezone";
import { Redirect } from 'react-router-dom';

class TermsAndPolicy extends React.Component {
  constructor(props) {
    super(props);

    //this.props.showLoader();
    //this.props.getDealersRequest();
  }

  termsThai = () => {
    return (
      <div>
        <CardBox styleName="col-12" cardStyle="p-5">
          <div className="table-responsive-material">
            <p class="p1">
              <b>นโยบายความเป็นส่วนตัว</b>
            </p>
            <p class="p2">
              <span class="s1">Suzuki </span>พัฒนา
              <span class="s1"> Hello Suzuki </span>เป็นแอปพลิเคชันฟรี
              <span class="s1"> </span>ซูซูกิเป็นผู้เสนอบริการนี้
              <span class="s1"> [</span>โดยไม่มีค่าใช้จ่าย
              <span class="s1">] </span>
              และมีวัตถุประสงค์เพื่อใช้งานตามที่เป็นอยู่
              <span class="s1">
                <span class="Apple-converted-space"> </span>
              </span>
            </p>
            <p class="p2">
              หน้านี้ใช้เพื่อแจ้งผู้เยี่ยมชมเว็บไซต์เกี่ยวกับนโยบายของเราเกี่ยวกับการเก็บรวบรวม
              <span class="s1"> </span>การใช้<span class="s1"> </span>
              และการเปิดเผยข้อมูลส่วนบุคคลสำหรับผู้ที่ตัดสินใจใช้บริการของเรา
              <span class="s1">
                <span class="Apple-converted-space"> </span>
              </span>
            </p>
            <p class="p2">
              หากท่านเลือกที่จะใช้บริการของเรา<span class="s1"> </span>
              ท่านจะต้องยอมรับการเก็บรวบรวม<span class="s1"> </span>
              และการใช้ข้อมูลที่เกี่ยวข้องกับนโยบายนี้<span class="s1"> </span>
              ข้อมูลส่วนบุคคลที่เรารวบรวมนั้นใช้สำหรับการจัดหา
              <span class="s1"> </span>และปรับปรุงบริการ
              <span class="s1"> </span>
              จะไม่ใช้หรือแบ่งปันข้อมูลของท่านกับบุคคลอื่น
              <span class="s1"> </span>
              ยกเว้นตามที่อธิบายไว้ในนโยบายความเป็นส่วนตัวนี้
              <span class="s1">
                <span class="Apple-converted-space"> </span>
              </span>
            </p>
            <p class="p2">
              <b>การเก็บรวบรวมและการใช้ข้อมูล</b>
            </p>
            <p class="p2">
              เพื่อประสบการณ์ที่ยิ่งขึ้นขณะใช้บริการ<span class="s1"> </span>
              เราอาจต้องการให้ท่านแสดงข้อมูลที่สามารถระบุตัวบุคคลได้บางส่วน
              <span class="s1"> [</span>ชื่อผู้ใช้<span class="s1"> | </span>
              เบอร์โทรศัพท์<span class="s1"> | </span>ที่อยู่
              <span class="s1"> | </span>สถานที่ปัจจุบัน
              <span class="s1"> | </span>รูปภาพ<span class="s1">] </span>
              ข้อมูลที่เราร้องขอ<span class="s1"> [</span>
              ถูกเก็บอยู่ในอุปกรณ์ของท่าน<span class="s1"> </span>
              และเราไม่ได้เก็บรวบรวมในทางใด<span class="s1">] | [</span>
              เราจะเก็บไว้และนำไปใช้ตามที่อธิบายไว้ในนโยบายความเป็นส่วนตัวนี้
              <span class="s1">
                ]<span class="Apple-converted-space"> </span>
              </span>
            </p>
            <p class="p2">
              แอปพลิเคชันใช้บริการของบุคคลที่สามที่อาจรวบรวมข้อมูลที่ใช้ระบุตัวท่าน
              <span class="s1">
                <span class="Apple-converted-space"> </span>
              </span>
            </p>
            <p class="p4">
              <b>Log Data</b>
            </p>
            <p class="p2">
              เราต้องการแจ้งให้ทราบว่าเมื่อใดก็ตามที่ท่านใช้บริการของเรา
              <span class="s1"> </span>หากเกิดข้อผิดพลาดในแอปพลิเคชัน
              <span class="s1"> </span>เราจะเก็บรวบรวมข้อมูล
              <span class="s1"> (</span>ผ่านผลิตภัณฑ์ของบุคคลที่สาม
              <span class="s1">) </span>บนโทรศัพท์ของท่านที่เรียกว่า
              <span class="s1"> Log Data Log Data </span>นี้อาจรวมถึงข้อมูล
              <span class="s1"> </span>เช่น<span class="s1"> </span>
              อินเทอร์เน็ตโปรโตคอล<span class="s1"> (“IP”) </span>
              แอดเดรสของอุปกรณ์ของท่าน<span class="s1"> </span>ชื่ออุปกรณ์
              <span class="s1"> </span>รุ่นระบบปฏิบัติการ
              <span class="s1"> </span>
              การกำหนดค่าแอปพลิเคชันเมื่อใช้บริการของเรา
              <span class="s3"> </span>เวลาและวันที่ที่ใช้บริการ
              <span class="s1"> </span>และสถิติอื่น<span class="s1"> </span>ๆ
              <span class="s1">
                <span class="Apple-converted-space"> </span>
              </span>
            </p>
            <p class="p2">
              <b>คุกกี้</b>
            </p>
            <p class="p2">
              คุกกี้คือไฟล์ที่มีข้อมูลจำนวนน้อยขนาดเล็กซึ่งโดยทั่วไปจะใช้เพื่อการระบุโดยไม่ระบุตัวตน
              <span class="s1"> </span>
              สิ่งนี้จะส่งจากเว็บไซต์ที่ท่านเยี่ยมชมไปยังเบราว์เซอร์
              <span class="s1"> </span>
              และถูกเก็บไว้ในหน่วยความจำภายในของอุปกรณ์ของท่าน
              <span class="s1">
                <span class="Apple-converted-space"> </span>
              </span>
            </p>
            <p class="p2">
              บริการนี้ไม่ได้ใช้<span class="s1"> “</span>คุกกี้
              <span class="s1">” </span>เหล่านี้โดยตรง<span class="s1"> </span>
              อย่างไรก็ตามแอปพลิเคชันอาจใช้รหัสบุคคลที่สามและไลบรารี่ที่ใช้
              <span class="s1"> “</span>คุกกี้<span class="s1">” </span>
              เพื่อเก็บรวบรวมข้อมูลและปรับปรุงบริการ<span class="s1"> </span>
              ท่านสามารถจะยอมรับหรือปฏิเสธคุกกี้เหล่านี้ได้
              <span class="s1"> </span>
              รวมทั้งจะทราบเมื่อมีการส่งคุกกี้ไปยังอุปกรณ์
              <span class="s1"> </span>หากท่านเลือกที่จะปฏิเสธคุกกี้ของเรา
              <span class="s1"> </span>ท่านอาจไม่สามารถใช้บางส่วนของบริการนี้ได้
              <span class="s1">
                <span class="Apple-converted-space"> </span>
              </span>
            </p>
            <p class="p2">
              <b>ผู้ให้บริการ</b>
            </p>
            <p class="p2">
              เราอาจจ้าง<span class="s1"> </span>บริษัทภายนอก
              <span class="s1"> </span>และบุคคลอื่นเพื่อเหตุผลดังต่อไปนี้
              <span class="s1">:</span>
            </p>
            <ul class="ul1">
              <li class="li2">
                <span class="s4"></span>เพื่อช่วยการบริการของเรา
              </li>
              <li class="li2">
                <span class="s4"></span>เพื่อให้บริการในนามของเรา
              </li>
              <li class="li2">
                <span class="s4"></span>
                เพื่อดำเนินการบริการที่เกี่ยวข้องกับบริการ
                <span class="s1"> </span>หรือ
              </li>
              <li class="li2">
                <span class="s4"></span>เพื่อช่วยวิเคราะห์การใช้บริการ
              </li>
            </ul>
            <p class="p2">
              เราต้องการแจ้งให้ผู้ใช้บริการทราบว่า<span class="s1"> </span>
              บุคคลเหล่านี้สามารถเข้าถึงข้อมูลส่วนบุคคลของท่านได้
              <span class="s1"> </span>เพื่อทำงานที่ได้รับมอบหมายในนามของเรา
              <span class="s1"> </span>
              อย่างไรก็ตามบุคคลเหล่านี้มีหน้าที่ต้องไม่เปิดเผยหรือใช้ข้อมูลเพื่อวัตถุประสงค์อื่นใด
              <span class="s1">
                <span class="Apple-converted-space"> </span>
              </span>
            </p>
            <p class="p2">
              <b>ความปลอดภัย</b>
            </p>
            <p class="p2">
              เราให้ความสำคัญกับความไว้วางใจของท่านในการแจ้งข้อมูลส่วนบุคคลแก่เรา
              <span class="s1"> </span>
              ดังนั้นเราจึงมุ่งมั่นที่จะใช้วิธีการที่เป็นที่ยอมรับในเชิงพาณิชย์ในการปกป้องข้อมูล
              <span class="s1"> </span>
              แต่โปรดจำไว้ว่าไม่มีวิธีการส่งผ่านอินเทอร์เน็ตหรือวิธีการจัดเก็บข้อมูลอิเล็กทรอนิกส์ที่ปลอดภัยและเชื่อถือได้
              <span class="s1"> 100% </span>
              และเราไม่สามารถรับประกันความปลอดภัยได้อย่างไม่มีเงื่อนไข
              <span class="s1">
                <span class="Apple-converted-space"> </span>
              </span>
            </p>
            <p class="p2">
              <b>ลิงก์ไปยังเว็บไซต์อื่น</b>
              <span class="s1">
                <b> </b>
              </span>
              <b>ๆ</b>
            </p>
            <p class="p2">
              บริการนี้อาจมีลิงก์ไปยังเว็บไซต์อื่น<span class="s1"> </span>ๆ
              <span class="s1"> </span>หากคุณคลิกที่ลิงก์จากบุคคลที่สาม
              <span class="s1"> </span>ท่านจะถูกนำไปยังเว็บไซต์นั้น
              <span class="s1"> </span>
              โปรดทราบว่าเราไม่ได้ดำเนินการเว็บไซต์ภายนอกเหล่านี้
              <span class="s1"> </span>
              ดังนั้นเราขอแนะนำให้ท่านอ่านนโยบายความเป็นส่วนตัวของเว็บไซต์เหล่านี้
              <span class="s1"> </span>
              เราไม่สามารถควบคุมและไม่รับผิดชอบต่อเนื้อหานโยบายความเป็นส่วนตัวหรือแนวทางปฏิบัติของเว็บไซต์หรือบริการของบุคคลที่สาม
              <span class="s1">
                <span class="Apple-converted-space"> </span>
              </span>
            </p>
            <p class="p2">
              <b>ความเป็นส่วนตัวของเด็ก</b>
            </p>
            <p class="p2">
              บริการเหล่านี้จะไม่ระบุผู้ที่มีอายุต่ำกว่า
              <span class="s1"> 13 </span>ปี<span class="s1"> </span>
              เราจะไม่เก็บข้อมูลส่วนบุคคลที่สามารถระบุตัวบุคคลได้จากเด็กอายุต่ำกว่า
              <span class="s1"> 13 </span>ปี<span class="s1"> </span>
              ในกรณีที่เราค้นพบว่าเด็กอายุต่ำกว่า<span class="s1"> 13 </span>
              ปีได้ให้ข้อมูลส่วนบุคคลกับเรา<span class="s1"> </span>
              เราจะลบข้อมูลนี้ออกจากเซิร์ฟเวอร์ของเราทันที
              <span class="s1"> </span>หากท่านเป็นผู้ปกครองหรือผู้พิทักษ์
              <span class="s1"> </span>
              และทราบว่าบุตรหลานของคุณได้ให้ข้อมูลส่วนบุคคลกับเรา
              <span class="s1"> </span>
              โปรดติดต่อเราเพื่อให้เราสามารถดำเนินการที่จำเป็นได้
              <span class="s1">
                <span class="Apple-converted-space"> </span>
              </span>
            </p>
            <p class="p2">
              <b>การเปลี่ยนแปลงนโยบายความเป็นส่วนตัว</b>
            </p>
            <p class="p2">
              เราอาจเปลี่ยนแปลงนโยบายความเป็นส่วนตัวเป็นครั้งคราว
              <span class="s1"> </span>ดังนั้น<span class="s1"> </span>
              ท่านควรตรวจสอบหน้านี้เป็นระยะเพื่อตรวจสอบว่ามีการเปลี่ยนแปลงหรือไม่
              <span class="s1"> </span>เราจะแจ้งให้ท่านทราบถึงการเปลี่ยนแปลงใด
              <span class="s1"> </span>ๆ<span class="s1"> </span>
              โดยประกาศนโยบายความเป็นส่วนตัวใหม่ในหน้านี้
              <span class="s1"> </span>
              การเปลี่ยนแปลงเหล่านี้จะมีผลทันทีหลังจากประกาศในหน้านี้
              <span class="s1">
                <span class="Apple-converted-space"> </span>
              </span>
            </p>
            <p class="p2">
              <b>ติดต่อเรา</b>
            </p>
            <p class="p5">
              หากท่านมีคำถามหรือข้อเสนอแนะเกี่ยวกับนโยบายความเป็นส่วนตัวของเรา
              <span class="s1"> </span>โปรดอย่าลังเลที่จะติดต่อเรา
              <span class="s1">
                <span class="Apple-converted-space"> </span>
              </span>
            </p>
          </div>
        </CardBox>
      </div>
    );
  };

  termsEnglish = () => {
    return (
      <div>
        <CardBox styleName="col-12" cardStyle="p-5">
          <div className="table-responsive-material">
            <p class="p2">
              <span class="s1">Suzuki built the </span>
              <span class="s2">Hello Suzuki</span>
              <span class="s1">
                {" "}
                app as free app. This SERVICE is provided by Suzuki [at no cost]
                and is intended for use as is.
                <span class="Apple-converted-space"> </span>
              </span>
            </p>
            <p class="p2">
              <span class="s1">
                This page is used to inform website visitors regarding our
                policies with the collection, use, and disclosure of Personal
                Information if anyone decided to use our Service.
                <span class="Apple-converted-space"> </span>
              </span>
            </p>
            <p class="p2">
              <span class="s1">
                If you choose to use our Service, then you agree to the
                collection and use of information in relation to this policy.
                The Personal Information that we collect is used for providing
                and improving the Service. we will not use or share your
                information with anyone except as described in this Privacy
                Policy.<span class="Apple-converted-space"> </span>
              </span>
            </p>
            <p class="p2">
              <span class="s1">
                The terms used in this Privacy Policy have the same meanings as
                in our Terms and Conditions, which is accessible at{" "}
              </span>
              <span class="s2">Hello Suzuki</span>
              <span class="s1">
                {" "}
                unless otherwise defined in this Privacy Policy.
                <span class="Apple-converted-space"> </span>
              </span>
            </p>
            <p class="p2">
              <span class="s1">
                <b>Information Collection and Use</b>
              </span>
            </p>
            <p class="p2">
              <span class="s1">
                For a better experience, while using our Service, we may require
                you to provide us with certain personally identifiable
                information[users name | address | location | pictures] The
                information that we request is [retained on your device and is
                not collected by us in any way]|[will be retained by us and used
                as described in this privacy policy].
                <span class="Apple-converted-space"> </span>
              </span>
            </p>
            <p class="p2">
              <span class="s1">
                The app does use third party services that may collect
                information used to identify you.
                <span class="Apple-converted-space"> </span>
              </span>
            </p>
            <p class="p2">
              <span class="s1">
                <b>Log Data</b>
              </span>
            </p>
            <p class="p2">
              <span class="s1">
                we want to inform you that whenever you use our Service, in a
                case of an error in the app we collect data and information
                (through third party products) on your phone called Log Data.
                This Log Data may include information such as your device
                Internet Protocol (“IP”) address, device name, operating system
                version, the configuration of the app when utilizing our
                Service, the time and date of your use of the Service, and other
                statistics.<span class="Apple-converted-space"> </span>
              </span>
            </p>
            <p class="p2">
              <span class="s1">
                <b>Cookies</b>
              </span>
            </p>
            <p class="p2">
              <span class="s1">
                Cookies are files with small amount of data that is commonly
                used an anonymous unique identifier. These are sent to your
                browser from the website that you visit and are stored on your
                device internal memory.
                <span class="Apple-converted-space"> </span>
              </span>
            </p>
            <p class="p2">
              <span class="s1">
                This Service does not use these “cookies” explicitly. However,
                the app may use third party code and libraries that use
                “cookies” to collection information and to improve their
                services. You have the option to either accept or refuse these
                cookies and know when a cookie is being sent to your device. If
                you choose to refuse our cookies, you may not be able to use
                some portions of this Service.
                <span class="Apple-converted-space"> </span>
              </span>
            </p>
            <p class="p2">
              <span class="s1">
                <b>Service Providers</b>
              </span>
            </p>
            <p class="p2">
              <span class="s1">
                we may employ third-party companies and individuals due to the
                following reasons:
              </span>
            </p>
            <ul class="ul1">
              <li class="li3">
                <span class="s3"></span>
                <span class="s1">To facilitate our Service;</span>
              </li>
              <li class="li3">
                <span class="s3"></span>
                <span class="s1">To provide the Service on our behalf;</span>
              </li>
              <li class="li3">
                <span class="s3"></span>
                <span class="s1">To perform Service-related services; or</span>
              </li>
              <li class="li3">
                <span class="s3"></span>
                <span class="s1">
                  To assist us in analyzing how our Service is used.
                </span>
              </li>
            </ul>
            <p class="p2">
              <span class="s1">
                we want to inform users of this Service that these third parties
                have access to your Personal Information. The reason is to
                perform the tasks assigned to them on our behalf. However, they
                are obligated not to disclose or use the information for any
                other purpose.<span class="Apple-converted-space"> </span>
              </span>
            </p>
            <p class="p2">
              <span class="s1">
                <b>Security</b>
              </span>
            </p>
            <p class="p2">
              <span class="s1">
                we value your trust in providing us your Personal Information,
                thus we are striving to use commercially acceptable means of
                protecting it. But remember that no method of transmission over
                the internet, or method of electronic storage is 100% secure and
                reliable, and we cannot guarantee its absolute security.
                <span class="Apple-converted-space"> </span>
              </span>
            </p>
            <p class="p2">
              <span class="s1">
                <b>Links to Other Sites</b>
              </span>
            </p>
            <p class="p2">
              <span class="s1">
                This Service may contain links to other sites. If you click on a
                third-party link, you will be directed to that site. Note that
                these external sites are not operated by us. Therefore, we
                strongly advise you to review the Privacy Policy of these
                websites. we have no control over and assume no responsibility
                for the content, privacy policies, or practices of any
                third-party sites or services.
                <span class="Apple-converted-space"> </span>
              </span>
            </p>
            <p class="p2">
              <span class="s1">
                <b>Children’s Privacy</b>
              </span>
            </p>
            <p class="p2">
              <span class="s1">
                These Services do not address anyone under the age of 13. we do
                not knowingly collect personally identifiable information from
                children under 13. In the case we discover that a child under 13
                has provided us with personal information, we immediately delete
                this from our servers. If you are a parent or guardian and you
                are aware that your child has provided us with personal
                information, please contact us so that we will be able to do
                necessary actions.<span class="Apple-converted-space"> </span>
              </span>
            </p>
            <p class="p2">
              <span class="s1">
                <b>Changes to This Privacy Policy</b>
              </span>
            </p>
            <p class="p2">
              <span class="s1">
                we may update our Privacy Policy from time to time. Thus, you
                are advised to review this page periodically for any changes. we
                will notify you of any changes by posting the new Privacy Policy
                on this page. These changes are effective immediately after they
                are posted on this page.
                <span class="Apple-converted-space"> </span>
              </span>
            </p>
            <p class="p2">
              <span class="s1">
                <b>Contact Us</b>
              </span>
            </p>
            <p class="p2">
              <span class="s1">
                If you have any questions or suggestions about our Privacy
                Policy, do not hesitate to contact us.
                <span class="Apple-converted-space"> </span>
              </span>
            </p>
          </div>
        </CardBox>
      </div>
    );
  };

  render() {
    let role = localStorage.getItem("role");
    if ((role === "smt") || (role === "superAdmin")|| (role.length === 6)){
      return <Redirect to={"/app/dashboard/register-users"} />;
    }
    const { locale } = this.props;
    return (
      <div className="app-wrapper">
        <ContainerHeader
          match={this.props.match}
          title={<IntlMessages id="pages.termsandpolicy" />}
        />

        {this.props.locale.locale === 'th'
          ? this.termsThai()
          : this.termsEnglish()}

        {/* {loader ? (
              <div className="loader-view">
                <CircularProgress />
              </div>
            ) : (
              //this.showDealers(this.props)
            )} */}
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  //const { loader, alertMessage, showMessage, items } = dealers;
  const { locale } = settings;
  return { locale: settings.locale };
};

export default connect(mapStateToProps, {})(TermsAndPolicy);
