import { all, takeEvery, call, fork, put } from 'redux-saga/effects';
import {
  getDeleteUserDataSuccess,
  createDeleteUserDataSuccess,
  updateDeleteUserDataSuccess,
  hideLoaderDeleteUserData,
  hideLoaderGetDeleteUserData,
} from 'actions/DeleteUserData';
import {
    GET_DELETE_USER_DATA_REQUEST,
    CREATE_DELETE_USER_DATA_REQUEST,
    UPDATE_DELETE_USER_DATA_REQUEST
} from 'constants/ActionTypes';
import { rsf } from '../firebase/firebase'; //, firebase, auth
import moment from 'moment-timezone';

function* getUserRequestDeleteData({ payload }) {
    const { userId } = payload;
    try {

        //console.log('userId:' + userId);

      //const colRef = firebase.firestore().collection('UsersRequestDeleteDataThailand');
      //const snapshot = yield call(rsf.firestore.getCollection, colRef);
    //   const snapshot = yield call(
    //     rsf.firestore.getCollection,
    //     colRef.where('active', '==', true)
    //   );

        const snapshot = yield call(rsf.firestore.getDocument, 'UsersRequestDeleteDataThailand/' + userId);

        //console.log('snapshot:' + JSON.stringify(snapshot));
        if (snapshot.empty) {
            //console.log('A1');
            yield put(getDeleteUserDataSuccess(null));
        } else {
            //console.log('A2');
            const userData = snapshot.data();
            //console.log('userData:' + JSON.stringify(userData));
            //console.log('active:' + userData.active)
            if (userData === undefined) {
                yield put(getDeleteUserDataSuccess(null));
            } else {
                if (userData.active) {
                    //console.log('B1');
                    yield put(getDeleteUserDataSuccess(userData));
                } else {
                    //console.log('B2');
                    yield put(getDeleteUserDataSuccess(null));
                }
            }
            
            
        }
        //console.log('item:' + snapshot.data());
      
  
        // datas = {
        //   id: doc.id,
        //   ...doc.data()
        // };

      //console.log('To:' + items[1].friendsCount);
      
    } catch (error) {
      console.log(error);
      yield put(hideLoaderGetDeleteUserData());
    }
}

function* createUserRequestDeleteData({ payload }) {
    const { userId, reasonTh, reasonEn, otherReason } = payload;
    try {

        //console.log('reasonTh:' + reasonTh + ' reasonEn:' + reasonEn + ' otherReason:' + otherReason);
        const user = yield call(rsf.firestore.getDocument, 'UsersThailand/' + userId);

        //console.log('user:' + user.data());

        if (!user.empty) {
            const userData = user.data();
            let currentDateTime = getCurrentDateTime();

            const snapshot = yield call(rsf.firestore.getDocument, 'UsersRequestDeleteDataThailand/' + userId);  
            
            //console.log('snapshot:' + snapshot.data());   
            const userRequestDeleteData = snapshot.data();

            if (userRequestDeleteData === undefined) {
                //console.log('ccccccccc');
                let createData = {
                    userId: userId,
                    firstname: userData.firstname,
                    lastname: userData.lastname,
                    email: userData.email,
                    phone: userData.phone,
                    isHaveCar: userData.isHaveCar,
                    reasonTh: reasonTh,
                    reasonEn: reasonEn,
                    active: true,
                    otherReason: otherReason,
                    createdDate: currentDateTime,
                    updatedDate: currentDateTime
                }
                //console.log('createData:' + JSON.stringify(createData));
                const doc = yield call(rsf.firestore.setDocument, 'UsersRequestDeleteDataThailand/' + userId, createData);
                yield put(createDeleteUserDataSuccess(createData));
            } else {
                //console.log('uuuuuuuuuu');
                let updateData = {
                    userId: userId,
                    firstname: userData.firstname,
                    lastname: userData.lastname,
                    email: userData.email,
                    phone: userData.phone,
                    isHaveCar: userData.isHaveCar,
                    reasonTh: reasonTh,
                    reasonEn: reasonEn,
                    active: true,
                    otherReason: otherReason,
                    createdDate: currentDateTime,
                    updatedDate: currentDateTime
                }
                const doc = yield call(rsf.firestore.updateDocument, 'UsersRequestDeleteDataThailand/' + userId, updateData);
                yield put(createDeleteUserDataSuccess(updateData));
            }
        } else {
            yield put(hideLoaderDeleteUserData());
        }


    } catch (error) {
        console.log(error);
        yield put(hideLoaderDeleteUserData());
    }
}

function* updateUserRequestDeleteData({ payload }) {
    const { updateData } = payload;

    let data = updateData;
    data.updatedDate = getCurrentDateTime();

    //console.log('updateData:' + JSON.stringify(data));

    try {        
        yield call(
            rsf.firestore.updateDocument,
            'UsersRequestDeleteDataThailand/' + updateData.userId,
            data
          );
      
          yield put(updateDeleteUserDataSuccess(updateData.userId));
    } catch (error) {
        console.log(error);
        yield put(hideLoaderDeleteUserData());
    }
}

function getCurrentDateTime() {
    let dd = moment()
      .tz('Asia/Bangkok')
      .format('YYYYMMDD HH:mm:ss'); //LTS
    //console.log('time1:' + dd);
    let ee = dd.replace(/:/g, '');
    //console.log('time2:' + ee);
    ee = ee.replace(/ /g, '');
    let str = ee.trim();
    //console.log('final:' + str);
    return str;
}

export function* watchGetDeleteUserDataRequest() {    
    yield takeEvery(GET_DELETE_USER_DATA_REQUEST, getUserRequestDeleteData);
}

export function* watchCreateDeleteUserDataRequest() {    
    yield takeEvery(CREATE_DELETE_USER_DATA_REQUEST, createUserRequestDeleteData);
}

export function* watchUpdateDeleteUserDataRequest() {    
    yield takeEvery(UPDATE_DELETE_USER_DATA_REQUEST, updateUserRequestDeleteData);
}
  
export default function* rootSaga() {
    yield all([
      fork(watchGetDeleteUserDataRequest),
      fork(watchCreateDeleteUserDataRequest),
      fork(watchUpdateDeleteUserDataRequest)
    ]);
}
