import {
  GET_REDEEM_SUCCESS,
  GET_REDEEM_BY_DEALER_SUCCESS,
  GET_REDEEM_DEALERLIST_SUCCESS,
  ON_SHOW_LOADER_GET_REDEEM,
  ON_HIDE_LOADER_GET_REDEEM,
  ON_SHOW_LOADER_GET_REDEEM_BY_DEALER,
  ON_HIDE_LOADER_GET_REDEEM_BY_DEALER,
  ON_SHOW_LOADER_GET_REDEEM_DEALERLIST,
  ON_HIDE_LOADER_GET_REDEEM_DEALERLIST,
} from 'constants/ActionTypes';

const INIT_STATE = {
  loader: false,
  loaderByDealer: false,
  dealerLoader: false,
  items: [],
  dealerItems: [],
  dealerList: [],
};

export default function redeem(state = INIT_STATE, action) {
  switch (action.type) {
    case GET_REDEEM_SUCCESS: {
      return {
        ...state,
        loader: false,
        items: action.payload,
      };
    }
    case GET_REDEEM_BY_DEALER_SUCCESS: {
      return {
        ...state,
        loaderByDealer: false,
        dealerItems: action.payload,
      };
    }
    case GET_REDEEM_DEALERLIST_SUCCESS: {
      return {
        ...state,
        dealerLoader: false,
        dealerList: action.payload,
      };
    }
    case ON_SHOW_LOADER_GET_REDEEM: {
      return {
        ...state,
        loader: true,
      };
    }
    case ON_HIDE_LOADER_GET_REDEEM: {
      return {
        ...state,
        loader: false,
      };
    }
    case ON_SHOW_LOADER_GET_REDEEM_BY_DEALER: {
      return {
        ...state,
        loaderByDealer: true,
      };
    }
    case ON_HIDE_LOADER_GET_REDEEM_BY_DEALER: {
      return {
        ...state,
        loaderByDealer: false,
      };
    }
    case ON_SHOW_LOADER_GET_REDEEM_DEALERLIST: {
      return {
        ...state,
        dealerLoader: true,
      };
    }
    case ON_HIDE_LOADER_GET_REDEEM_DEALERLIST: {
      return {
        ...state,
        dealerLoader: false,
      };
    }
    default:
      return state;
  }
}
