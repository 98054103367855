import React from 'react';
import ContainerHeader from 'components/ContainerHeader';
import IntlMessages from 'util/IntlMessages';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import {
  showLoaderChatHistoryByChatroomid,
  getChatHistoryByChatroomidRequest
} from 'actions/Chats';
//import CircularProgress from '@material-ui/core/CircularProgress';
//import { Redirect } from 'react-router-dom';

//No use

class DealerChats extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dealercode: this.props.match.params.dealercode,
      userid: this.props.match.params.userid
    };
    // this.setState({
    //   dealercode: this.props.match.params.dealercode
    // });

    console.log('DEALERCODE_PARAM:' + this.props.match.params.dealercode);
    console.log('USERID_PARAM:' + this.props.match.params.userid);

    // this.props.showLoaderUsersByDealer();
    // this.props.getUsersByDealerRequest(this.props.match.params.dealercode);
  }

  backPressed = e => {
    e.preventDefault();

    this.props.history.goBack();
  };

  render() {
    // const {
    //   loaderChatHistoryByChatroomid,
    //   chatHistoryItems,
    //   locale
    // } = this.props;
    return (
      <div className="app-wrapper">
        <ContainerHeader
          match={this.props.match}
          title={<IntlMessages id="pages.chatuserhistory" />}
        />
        <div className="module-box-topbar">
          <IconButton className="icon-btn" onClick={this.backPressed}>
            <i className="zmdi zmdi-arrow-back" />
          </IconButton>
        </div>
        Chat History
      </div>
    );
  }
}

const mapStateToProps = ({ chats, settings }) => {
  const { loaderChatHistoryByChatroomid, chatHistoryItems } = chats;
  //const { locale } = settings;
  return {
    loaderChatHistoryByChatroomid,
    chatHistoryItems,
    locale: settings.locale
  };
};

export default connect(mapStateToProps, {
  showLoaderChatHistoryByChatroomid,
  getChatHistoryByChatroomidRequest
})(DealerChats);
