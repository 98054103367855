import React from 'react';
import ContainerHeader from 'components/ContainerHeader';
import IntlMessages from 'util/IntlMessages';
import { connect } from 'react-redux';
//import { showLoader, getDealersRequest } from 'actions/Dealers';
import {
  showLoaderUsersByDealer,
  getUsersByDealerRequest
} from 'actions/Users';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CardBox from 'components/CardBox';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
//import ReactExport from 'react-data-export';
import SearchBox from 'components/SearchBox';
import { Dropdown, DropdownMenu, DropdownToggle, Popover } from 'reactstrap';

class ChatUsersByDealer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dealercode: this.props.match.params.dealercode,
      searchTextValue: '',
      usersByDealer: []
    };
    // this.setState({
    //   dealercode: this.props.match.params.dealercode
    // });

    //console.log('CCCCCCC:' + this.state.dealercode);
    //console.log('DDDDDDD:' + this.props.match.params.dealercode);
    this.props.showLoaderUsersByDealer();
    this.props.getUsersByDealerRequest(this.props.match.params.dealercode);
  }

  static getDerivedStateFromProps(props, state) {
    //console.log('GetDerived is called');
    const { usersByDealerItems } = props;
    //console.log('G:' + state.searchTextValue);
    // this.setState({
    //   usersByDealer: usersByDealerItems
    // });

    if (state.searchTextValue.length > 0) {
      return null;
    }
    if (usersByDealerItems) {
      return { usersByDealer: usersByDealerItems };
    }

    return null;
  }

  backPressed = e => {
    e.preventDefault();

    this.props.history.goBack();
  };

  dateToString = dateData => {
    let year = dateData.substring(0, 4);
    let month = dateData.substring(4, 6);
    let day = dateData.substring(6, 8);
    let hr = dateData.substring(8, 10);
    let mi = dateData.substring(10, 12);
    let sec = dateData.substring(12, 14);
    return day + '/' + month + '/' + year + ' ' + hr + ':' + mi + ':' + sec;
  };

  searchUsers = searchText => {
    console.log('SearchText:' + searchText);
    if (searchText === '') {
      //this.setState();
      this.showUsers(this.props.usersByDealerItems);
    } else {
      // const chassisSearch =
      //   user.chassisNumber.length > 0
      //     ? user.chassisNumber[0]
      //         .toLowerCase()
      //         .indexOf(searchText.toLowerCase())
      //     : 0;
      // This is working
      // const users = this.props.usersByDealerItems.filter(
      //   user =>
      //     (user.firstname.toLowerCase().indexOf(searchText.toLowerCase()) &&
      //       user.lastname.toLowerCase().indexOf(searchText.toLowerCase())) > -1
      // );
      //This is working for chassisNumber
      // const users = this.props.usersByDealerItems.filter(
      //   user =>
      //     (user.chassisNumber.length > 0
      //       ? user.chassisNumber[0]
      //           .toLowerCase()
      //           .indexOf(searchText.toLowerCase())
      //       : 0) > -1
      //   // (user.firstname.toLowerCase().indexOf(searchText.toLowerCase()) &&
      //   //   user.lastname.toLowerCase().indexOf(searchText.toLowerCase())) > -1
      // );
      const users = this.props.usersByDealerItems.filter(
        user =>
          (user.firstname.toLowerCase().indexOf(searchText.toLowerCase()) &&
            user.lastname.toLowerCase().indexOf(searchText.toLowerCase()) &&
            user.phone.toLowerCase().indexOf(searchText.toLowerCase()) &&
            (user.chassisNumber.length > 0
              ? user.chassisNumber[0]
                  .toLowerCase()
                  .indexOf(searchText.toLowerCase())
              : -2)) > -1
        // (user.firstname.toLowerCase().indexOf(searchText.toLowerCase()) &&
        //   user.lastname.toLowerCase().indexOf(searchText.toLowerCase())) > -1
      );
      console.log('ResultCount:' + users.length);
      this.showUsers(users);
      this.setState({
        usersByDealer: users
      });
      console.log('TTTETETE:' + this.state.usersByDealer.length);
    }
  };

  updateSearch = evt => {
    //this.props.updateSearch(evt.target.value);
    this.setState({
      searchTextValue: evt.target.value
    });
    this.searchUsers(evt.target.value);
  };

  // finishLoading = usersByDealerItems => {
  //   this.setState({
  //     usersByDealer: usersByDealerItems
  //   });
  //   this.showUsers(this.state.usersByDealer);
  // };

  showUsers = users => {
    //const nodata = <div></div>;

    if (users !== undefined && users != null && users.length !== 0) {
      //const dataSet = this.dataForExportToExcel(items);
      return (
        <div className="row">
          <CardBox styleName="col-12" cardStyle="p-0">
            <div className="table-responsive-material">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center"> </TableCell>
                    <TableCell align="center">
                      {<IntlMessages id="pages.userlist.table.firstname" />}
                    </TableCell>
                    <TableCell align="center">
                      {<IntlMessages id="pages.userlist.table.lastname" />}
                    </TableCell>
                    <TableCell align="center">
                      {<IntlMessages id="pages.userlist.table.email" />}
                    </TableCell>
                    <TableCell align="center">
                      {<IntlMessages id="pages.userlist.table.phone" />}
                    </TableCell>
                    <TableCell align="center">
                      {<IntlMessages id="pages.userlist.table.chassisnumber" />}
                    </TableCell>
                    <TableCell align="center">
                      {<IntlMessages id="pages.userlist.table.registerdate" />}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map(u => {
                    return (
                      <TableRow key={u.id}>
                        <TableCell align="center">
                          <Link
                            // to={`chathistory/chat-user-history/${this.state
                            //   .dealercode + u.objectId}`}
                            to={`${this.props.match.url}/chat-user-history/${u.objectId}/${u.fullname}`}
                            // to={`/usersbydealer/${dealer.dealer_code}`}
                            className="btn btn-primary"
                          >
                            <i className="zmdi zmdi-comment zmdi-hc-fw" />{' '}
                            {
                              <IntlMessages id="pages.chatusersbydealer.chats" />
                            }
                          </Link>
                        </TableCell>
                        <TableCell align="left">{u.firstname}</TableCell>
                        <TableCell align="left">{u.lastname}</TableCell>
                        <TableCell align="left">{u.email}</TableCell>
                        <TableCell align="center">N/A</TableCell>
                        <TableCell align="center">{u.chassisNumber}</TableCell>
                        <TableCell align="center">
                          {this.dateToString(u.createdAt)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </CardBox>
        </div>
      );
    }
  };

  displayBackButton = () => {
    var role = localStorage.getItem('role');
    if (role === 'superAdmin') {
      return (
        <div className="module-box-topbar">
          <IconButton className="icon-btn" onClick={this.backPressed}>
            <i className="zmdi zmdi-arrow-back" />
          </IconButton>
        </div>
      );
    } else if (role === 'smt') {
      return (
        <div className="module-box-topbar">
          <IconButton className="icon-btn" onClick={this.backPressed}>
            <i className="zmdi zmdi-arrow-back" />
          </IconButton>
        </div>
      );
    } else {
      return null;
    }
  };

  render() {
    const { loaderUsersByDealer, usersByDealerItems, locale } = this.props;
    const { usersByDealer } = this.state;
    return (
      <div className="app-wrapper">
        <ContainerHeader
          match={this.props.match}
          title={<IntlMessages id="pages.usersbydealer" />}
        />
        {/* <div className="col-xl-12 col-lg-12 col-md-12 col-12"> */}
        {this.displayBackButton()}
        <div className="d-flex justify-content-center">
          <CardBox
            styleName="col-lg-6 col-sm-6"
            // heading={<IntlMessages id="picker.date.date" />}
          >
            <center>
              <SearchBox
                styleName="search-dropdown"
                placeholder={
                  this.props.locale.locale === 'en'
                    ? 'Search Users by First Name, Last Name, Phone, Chassis Number'
                    : 'ค้นหาผู้ใช้ด้วย ชื่อ, นามสกุล, เบอร์โทรศัพท์, หมายเลขตัวถัง'
                }
                onChange={this.updateSearch.bind(this)}
                value={this.state.searchTextValue}
              />
            </center>
          </CardBox>
        </div>
        {/* </div> */}
        {loaderUsersByDealer ? (
          <div className="loader-view">
            <CircularProgress />
          </div>
        ) : (
          //this.showUsers(this.props.usersByDealerItems)
          //this.finishLoading(this.props.usersByDealerItems)
          this.showUsers(this.state.usersByDealer)
        )}
      </div>
    );
  }
}
const mapStateToProps = ({ users, settings }) => {
  //console.log('BBBBBB:' + state.dealercode);
  const { loaderUsersByDealer, usersByDealerItems } = users;
  const { locale } = settings;
  return {
    loaderUsersByDealer,
    usersByDealerItems,
    locale: settings.locale
  };
};

export default connect(mapStateToProps, {
  showLoaderUsersByDealer,
  getUsersByDealerRequest
})(ChatUsersByDealer);
