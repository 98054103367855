import React from "react";
import ContainerHeader from "components/ContainerHeader/index";
import IntlMessages from "util/IntlMessages";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import CardBox from "components/CardBox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";
import {
  showLoaderGetUsersByDefaultDealer,
  getUsersByDefaultDealerRequest,
  showLoaderGetActiveUsersAll,
  getActiveUsersAllRequest,
  updateActiveUsersRequest,
} from "actions/ActiveUsers";
//import { showLoaderUsersByDealer, getUsersByDealerRequest } from 'actions/Users';
import { showLoader, getDealersRequest } from "actions/Dealers";
import moment from "moment-timezone";
import XLSX from "@sheet/chart"; // Pro Basic + Pro Chart
import { Redirect } from "react-router-dom";

class ActiveUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dealerCode: "",
      dealers: [],
      allDealers: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.cancelPress = this.cancelPress.bind(this);

    //this.handleDealerChange = this.handleDealerChange.bind(this);
    //this.renderDealerToDropdown = this.renderDealerToDropdown.bind(this);

    this.props.showLoader();
    this.props.getDealersRequest();

    this.props.showLoaderGetActiveUsersAll();
    this.props.getActiveUsersAllRequest();

    this.baseState = this.state;
  }

  static getDerivedStateFromProps(props, state) {
    //console.log('GetDerived is called');
    const { dealerCode, dealers, allDealers } = state;
    const { loader, items, dataItems } = props;
    //const { loaderServiceSurvey, serviceSurveyItems, loader, items } = props;
    //const { dealers } = state;
    //console.log('GD:' + state.dealerCode);

    //console.log('getDeri dealerCode:' + dealerCode);

    if (items) {
      //console.log('Have dealer items');
      // var isHaveAll = items.find(d => d.id === 0);
      // if (!isHaveAll) {
      //   var dealerAll = {
      //     id: 0,
      //     dealerId: 0,
      //     dealerCode: '',
      //     dealerNameEn: 'All Dealers',
      //     delaerNameTh: 'ทั้งหมด'
      //   };
      //   items.splice(0, 0, dealerAll);
      // }
      return { dealers: items, allDealers: dataItems };
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    e.target.reset();
  }

  cancelPress() {
    //e.preventDefault();
    //document.getElementById('search_user').reset();
    this.setState({
      dealerCode: "",
    });

    this.setState(this.baseState);
    //this.props.resetSecondHandCar();
  }

  //When change language and want state to prolong
  componentDidMount() {
    //const { navigate,goBack } = this.props.navigation;

    // if (this.props.isMessageSecondHandCar) {
    //   this.displayMessage(this.props.secondHandCarCode);
    // }

    const { dealerCode, dealers, allDealers } = this.state;
    const {
      loader,
      items,
      loaderGetUsersByDefaultDealer,
      userItems,
      dataItems,
    } = this.props;

    if (userItems.length > 0) {
      let userObj = userItems[0];
      this.setState({ dealerCode: userObj.dealerCode });
    }
  }

  handleDealerChange = (dealerCode) => (event) => {

    if (event) event.preventDefault();

    this.setState({ dealerCode: event.target.value });

    

    // if (this.state.phoneNumber === '') {
    //   NotificationManager.warning(
    //     <IntlMessages id="notification.warning" />,
    //     <IntlMessages id="pages.activeusers.message.phonenumberempty" />,
    //     3000
    //   );
    //   return;
    // }
    if (event.target.value === "") {
      NotificationManager.warning(
        <IntlMessages id="notification.warning" />,
        <IntlMessages id="pages.activeusers.message.dealercodeempty" />,
        3000
      );
      return;
    }

    //console.log('button:' + this.state.dealerCode);

    //this.props.showLoaderGetActiveUsersAll();
    this.props.getActiveUsersAllRequest();

    this.props.showLoaderGetUsersByDefaultDealer();
    this.props.getUsersByDefaultDealerRequest(event.target.value);

    // this.props.items = [];
    // this.props.dealerItems = [];
    // this.props.areaItems = [];
    // this.props.byDealerItems = [];

    //console.log('DealerCode:' + this.state.dealerCode);
    //console.log('DD:', event.target.value);
    // this.props.showLoader();
    // this.props.getResultServiceSurveyRequest(this.state.month, this.state.year);
    // this.props.getResultDealerServiceSurveyRequest();
    // this.props.getResultAreaServiceSurveyRequest();
    // this.props.getResultByDealerServiceSurveyRequest(this.state.dealerCode);

    // this.props.showLoaderServiceSurvey();
    // this.props.getServiceSurveyRequest(
    //   event.target.value,
    //   this.state.month,
    //   this.state.year
    // );
  };

  getCurrentDateTime = () => {
    let dd = moment().tz("Asia/Bangkok").format("YYYYMMDD HH:mm:ss"); //LTS
    //console.log('time1:' + dd);
    let ee = dd.replace(/:/g, "");
    //console.log('time2:' + ee);
    ee = ee.replace(/ /g, "");
    let str = ee.trim();
    //console.log('final:' + str);
    return str;
  };

  dateToStringEn = (dateData) => {
    let year = dateData.substring(0, 4);
    let month = dateData.substring(4, 6);
    let day = dateData.substring(6, 8);
    let hr = dateData.substring(8, 10);
    let mi = dateData.substring(10, 12);
    let sec = dateData.substring(12, 14);
    return day + "/" + month + "/" + year + " " + hr + ":" + mi + ":" + sec;
  };

  dateToStringTh = (dateData) => {
    let year = dateData.substring(0, 4);
    let month = dateData.substring(4, 6);
    let day = dateData.substring(6, 8);

    year = parseInt(year, 0) + 543;
    let hr = dateData.substring(8, 10);
    let mi = dateData.substring(10, 12);
    let sec = dateData.substring(12, 14);
    return (
      day +
      "/" +
      month +
      "/" +
      year.toString() +
      " " +
      hr +
      ":" +
      mi +
      ":" +
      sec
    );
  };

  numberWithCommas = (x) => {
    //console.log('x:' + x);

    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // if (x === 0) {
    //   return '0';
    // } else {
    //   return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // }
  };

  // sortArrayByDealerCode = dealerItems => {
  //   console.log('sort array is called');
  //   var sortArray = dealerItems;

  //   sortArray.sort((a, b) => {
  //     return a.dealerCode > b.dealerCode ? 1 : -1; //ascending
  //     //return a.dealerCode > b.dealerCode ? -1 : 1; //descending
  //   });

  //   return sortArray;
  // };

  // renderDealerToDropdown = dealerItems => {
  //   console.log('Come to droprender');
  //   console.log('dealerItems count: ' + dealerItems.length);
  //   dealerItems = this.sortArrayByDealerCode(dealerItems);
  //   var dealers = [];

  //   for (var i = 0; i < dealerItems.length; i++) {
  //     dealers.push(dealerItems[i]);
  //   }

  //   console.log('dealer count: ' + dealers.length);
  //   // this.setState({
  //   //   dealers: dealers
  //   // });
  //   //this.setState((dealers = dealers));
  //   //return dealers;
  // };

  exportToExcelActive = (items, inactiveUsersArray) => {
    // var workbook = { SheetNames: [], Sheets: {} };
    // const wsname0 = workbook.SheetNames[0];
    // console.log('wsname:' + wsname0);
    // var ws0 = workbook.Sheets[wsname0];
    //console.log('itemCount:' + items.length);

    const fontName = "Calibri";
    const fontSize = "11";
    const borderColor = 0x808080;
    const csiBgColor = 0xe4efdc;
    const answerBgColor = 0xdfeaf5;
    const sumBgColor = 0xdadada;
    const totalBgColor = 0x595959;
    const whiteBgColor = 0xffffff;
    const textMainColor = 0x3f3f3f;
    const textWhiteColor = 0xffffff;
    const takumiBgColor = 0x003145;

    const textColor = "#7f7f7f";

    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.aoa_to_sheet([[]]);
    var wsname = "User List";
    XLSX.utils.book_append_sheet(wb, ws, wsname);

    //var ws_data = ['Dealer Code', 'Dealer Name Thai', 'Dealer Name English', 'Customers Count'];
    //var ws = XLSX.utils.aoa_to_sheet(ws_data);
    //var ws = workbook.Sheets[wsname];

    /* Set worksheet sheet to "normal" */
    ws["!margins"] = {
      left: 0.7,
      right: 0.7,
      top: 0.75,
      bottom: 0.75,
      header: 0.3,
      footer: 0.3,
    };

    var wscols = [
      { width: 15 },
      { width: 40 },
      { width: 40 },
      { width: 20 },
      { width: 10 },
      { width: 15 },
    ];
    ws["!cols"] = wscols;
    //ws['!rows']

    ws["!gridlines"] = false;

    ws["!freeze"] = "A2"; // Freeze first row               bottom pane starts at A2
    //ws["!freeze"] = "B1"; // Freeze first column             right pane starts at B1
    //ws["!freeze"] = "B2"; // Freeze row and column    bottom-right pane starts at B2

    // ws['!freeze'] = {
    //   xSplit: '1',
    //   ySplit: '1',
    //   topLeftCell: 'B2',
    //   activePane: 'bottomRight',
    //   state: 'frozen'
    // };

    var row = 1;

    var dataArray = ["", "", "", "", "", ""];
    XLSX.utils.sheet_add_aoa(ws, [dataArray], { origin: "A" + row });

    //Header
    ws["A" + 1] = {
      t: "s",
      v: this.props.locale.locale === "en" ? "Dealer Code" : "รหัสตัวแทน",
      s: {
        top: { style: "thin", color: { rgb: borderColor } },
        bottom: { style: "thin", color: { rgb: borderColor } },
        left: { style: "thin", color: { rgb: borderColor } },
        right: { style: "thin", color: { rgb: borderColor } },
        fgColor: { rgb: takumiBgColor },
        name: fontName,
        sz: fontSize,
        bold: true,
        color: { rgb: textWhiteColor },
        alignment: {
          horizontal: "center",
          vertical: "center",
          indent: 0,
          wrapText: true,
        },
      },
    };
    ws["B" + 1] = {
      t: "s",
      v: this.props.locale.locale === "en" ? "Dealer Name" : "ชื่อตัวแทน",
      s: {
        top: { style: "thin", color: { rgb: borderColor } },
        bottom: { style: "thin", color: { rgb: borderColor } },
        left: { style: "thin", color: { rgb: borderColor } },
        right: { style: "thin", color: { rgb: borderColor } },
        fgColor: { rgb: takumiBgColor },
        name: fontName,
        sz: fontSize,
        bold: true,
        color: { rgb: textWhiteColor },
        alignment: {
          horizontal: "center",
          vertical: "center",
          indent: 0,
          wrapText: true,
        },
      },
    };
    ws["C" + 1] = {
      t: "s",
      v: this.props.locale.locale === "en" ? "Fullname" : "ชื่อและนามสกุล",
      s: {
        top: { style: "thin", color: { rgb: borderColor } },
        bottom: { style: "thin", color: { rgb: borderColor } },
        left: { style: "thin", color: { rgb: borderColor } },
        right: { style: "thin", color: { rgb: borderColor } },
        fgColor: { rgb: takumiBgColor },
        name: fontName,
        sz: fontSize,
        bold: true,
        color: { rgb: textWhiteColor },
        alignment: {
          horizontal: "center",
          vertical: "center",
          indent: 0,
          wrapText: true,
        },
      },
    };
    ws["D" + 1] = {
      t: "s",
      v: this.props.locale.locale === "en" ? "Vin Number" : "หมายเลขตัวถัง",
      s: {
        top: { style: "thin", color: { rgb: borderColor } },
        bottom: { style: "thin", color: { rgb: borderColor } },
        left: { style: "thin", color: { rgb: borderColor } },
        right: { style: "thin", color: { rgb: borderColor } },
        fgColor: { rgb: takumiBgColor },
        name: fontName,
        sz: fontSize,
        bold: true,
        color: { rgb: textWhiteColor },
        alignment: {
          horizontal: "center",
          vertical: "center",
          indent: 0,
          wrapText: true,
        },
      },
    };
    ws["E" + 1] = {
      t: "s",
      v: this.props.locale.locale === "en" ? "Points" : "คะแนน",
      s: {
        top: { style: "thin", color: { rgb: borderColor } },
        bottom: { style: "thin", color: { rgb: borderColor } },
        left: { style: "thin", color: { rgb: borderColor } },
        right: { style: "thin", color: { rgb: borderColor } },
        fgColor: { rgb: takumiBgColor },
        name: fontName,
        sz: fontSize,
        bold: true,
        color: { rgb: textWhiteColor },
        alignment: {
          horizontal: "center",
          vertical: "center",
          indent: 0,
          wrapText: true,
        },
      },
    };
    ws["F" + 1] = {
      t: "s",
      v:
        this.props.locale.locale === "en"
          ? "Next Point Expired Date"
          : "วันที่คะแนนหมดอายุ",
      s: {
        top: { style: "thin", color: { rgb: borderColor } },
        bottom: { style: "thin", color: { rgb: borderColor } },
        left: { style: "thin", color: { rgb: borderColor } },
        right: { style: "thin", color: { rgb: borderColor } },
        fgColor: { rgb: takumiBgColor },
        name: fontName,
        sz: fontSize,
        bold: true,
        color: { rgb: textWhiteColor },
        alignment: {
          horizontal: "center",
          vertical: "center",
          indent: 0,
          wrapText: true,
        },
      },
    };

    //Content
    for (var i = 0; i < items.length; i++) {
      row++;
      var dataArray = ["", "", "", "", "", ""];
      XLSX.utils.sheet_add_aoa(ws, [dataArray], { origin: "A" + row });

      ws["A" + row] = {
        t: "s",
        v: items[i].dealerCode,
        s: {
          bottom: { style: "thin", color: { rgb: borderColor } },
          left: { style: "thin", color: { rgb: borderColor } },
          right: { style: "thin", color: { rgb: borderColor } },
          fgColor: { rgb: whiteBgColor },
          name: fontName,
          sz: fontSize,
          bold: false,
          color: { rgb: textMainColor },
          alignment: {
            horizontal: "center",
            vertical: "center",
            indent: 0,
            wrapText: true,
          },
        },
      };
      ws["B" + row] = {
        t: "s",
        v:
          this.props.locale.locale === "th"
            ? items[i].dealerNameTh
            : items[i].dealerNameEn,
        s: {
          bottom: { style: "thin", color: { rgb: borderColor } },
          left: { style: "thin", color: { rgb: borderColor } },
          right: { style: "thin", color: { rgb: borderColor } },
          fgColor: { rgb: whiteBgColor },
          name: fontName,
          sz: fontSize,
          bold: false,
          color: { rgb: textMainColor },
          alignment: {
            horizontal: "left",
            vertical: "center",
            indent: 0,
            wrapText: true,
          },
        },
      };
      ws["C" + row] = {
        t: "s",
        v: items[i].fullname,
        s: {
          bottom: { style: "thin", color: { rgb: borderColor } },
          left: { style: "thin", color: { rgb: borderColor } },
          right: { style: "thin", color: { rgb: borderColor } },
          fgColor: { rgb: whiteBgColor },
          name: fontName,
          sz: fontSize,
          bold: false,
          color: { rgb: textMainColor },
          alignment: {
            horizontal: "left",
            vertical: "center",
            indent: 0,
            wrapText: true,
          },
        },
      };
      ws["D" + row] = {
        t: "s",
        v: items[i].vinNumber,
        s: {
          bottom: { style: "thin", color: { rgb: borderColor } },
          left: { style: "thin", color: { rgb: borderColor } },
          right: { style: "thin", color: { rgb: borderColor } },
          fgColor: { rgb: whiteBgColor },
          name: fontName,
          sz: fontSize,
          bold: false,
          color: { rgb: textMainColor },
          alignment: {
            horizontal: "center",
            vertical: "center",
            indent: 0,
            wrapText: true,
          },
        },
      };
      ws["E" + row] = {
        t: "s",
        v: items[i].totalPoints,
        s: {
          bottom: { style: "thin", color: { rgb: borderColor } },
          left: { style: "thin", color: { rgb: borderColor } },
          right: { style: "thin", color: { rgb: borderColor } },
          fgColor: { rgb: whiteBgColor },
          name: fontName,
          sz: fontSize,
          bold: false,
          color: { rgb: textMainColor },
          alignment: {
            horizontal: "center",
            vertical: "center",
            indent: 0,
            wrapText: true,
          },
        },
      };
      ws["F" + row] = {
        t: "s",
        v: items[i].nextPointExpiredDate,
        s: {
          bottom: { style: "thin", color: { rgb: borderColor } },
          left: { style: "thin", color: { rgb: borderColor } },
          right: { style: "thin", color: { rgb: borderColor } },
          fgColor: { rgb: whiteBgColor },
          name: fontName,
          sz: fontSize,
          bold: false,
          color: { rgb: textMainColor },
          alignment: {
            horizontal: "center",
            vertical: "center",
            indent: 0,
            wrapText: true,
          },
        },
      };
    }

    var currentDate = moment().tz("Asia/Bangkok").format("YYYYMMDD");
    //### Save xlsx file
    XLSX.writeFile(
      wb,
      "ActiveUsersByDealer-" +
        this.state.dealerCode +
        "-" +
        currentDate +
        ".xlsx",
      {
        cellStyles: true,
        bookImages: true,
      }
    );

    this.exportToExcelInactive(inactiveUsersArray);
  };

  exportToExcelInactive = (items) => {
    // var workbook = { SheetNames: [], Sheets: {} };
    // const wsname0 = workbook.SheetNames[0];
    // console.log('wsname:' + wsname0);
    // var ws0 = workbook.Sheets[wsname0];
    //console.log('itemCount:' + items.length);

    const fontName = "Calibri";
    const fontSize = "11";
    const borderColor = 0x808080;
    const csiBgColor = 0xe4efdc;
    const answerBgColor = 0xdfeaf5;
    const sumBgColor = 0xdadada;
    const totalBgColor = 0x595959;
    const whiteBgColor = 0xffffff;
    const textMainColor = 0x3f3f3f;
    const textWhiteColor = 0xffffff;
    const takumiBgColor = 0x003145;

    const textColor = "#7f7f7f";

    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.aoa_to_sheet([[]]);
    var wsname = "User List";
    XLSX.utils.book_append_sheet(wb, ws, wsname);

    //var ws_data = ['Dealer Code', 'Dealer Name Thai', 'Dealer Name English', 'Customers Count'];
    //var ws = XLSX.utils.aoa_to_sheet(ws_data);
    //var ws = workbook.Sheets[wsname];

    /* Set worksheet sheet to "normal" */
    ws["!margins"] = {
      left: 0.7,
      right: 0.7,
      top: 0.75,
      bottom: 0.75,
      header: 0.3,
      footer: 0.3,
    };

    var wscols = [
      { width: 15 },
      { width: 40 },
      { width: 40 },
      { width: 20 },
      { width: 10 },
      { width: 15 },
    ];
    ws["!cols"] = wscols;
    //ws['!rows']

    ws["!gridlines"] = false;

    ws["!freeze"] = "A2"; // Freeze first row               bottom pane starts at A2
    //ws["!freeze"] = "B1"; // Freeze first column             right pane starts at B1
    //ws["!freeze"] = "B2"; // Freeze row and column    bottom-right pane starts at B2

    // ws['!freeze'] = {
    //   xSplit: '1',
    //   ySplit: '1',
    //   topLeftCell: 'B2',
    //   activePane: 'bottomRight',
    //   state: 'frozen'
    // };

    var row = 1;

    var dataArray = ["", "", "", "", "", ""];
    XLSX.utils.sheet_add_aoa(ws, [dataArray], { origin: "A" + row });

    //Header
    ws["A" + 1] = {
      t: "s",
      v: this.props.locale.locale === "en" ? "Dealer Code" : "รหัสตัวแทน",
      s: {
        top: { style: "thin", color: { rgb: borderColor } },
        bottom: { style: "thin", color: { rgb: borderColor } },
        left: { style: "thin", color: { rgb: borderColor } },
        right: { style: "thin", color: { rgb: borderColor } },
        fgColor: { rgb: takumiBgColor },
        name: fontName,
        sz: fontSize,
        bold: true,
        color: { rgb: textWhiteColor },
        alignment: {
          horizontal: "center",
          vertical: "center",
          indent: 0,
          wrapText: true,
        },
      },
    };
    ws["B" + 1] = {
      t: "s",
      v: this.props.locale.locale === "en" ? "Dealer Name" : "ชื่อตัวแทน",
      s: {
        top: { style: "thin", color: { rgb: borderColor } },
        bottom: { style: "thin", color: { rgb: borderColor } },
        left: { style: "thin", color: { rgb: borderColor } },
        right: { style: "thin", color: { rgb: borderColor } },
        fgColor: { rgb: takumiBgColor },
        name: fontName,
        sz: fontSize,
        bold: true,
        color: { rgb: textWhiteColor },
        alignment: {
          horizontal: "center",
          vertical: "center",
          indent: 0,
          wrapText: true,
        },
      },
    };
    ws["C" + 1] = {
      t: "s",
      v: this.props.locale.locale === "en" ? "Fullname" : "ชื่อและนามสกุล",
      s: {
        top: { style: "thin", color: { rgb: borderColor } },
        bottom: { style: "thin", color: { rgb: borderColor } },
        left: { style: "thin", color: { rgb: borderColor } },
        right: { style: "thin", color: { rgb: borderColor } },
        fgColor: { rgb: takumiBgColor },
        name: fontName,
        sz: fontSize,
        bold: true,
        color: { rgb: textWhiteColor },
        alignment: {
          horizontal: "center",
          vertical: "center",
          indent: 0,
          wrapText: true,
        },
      },
    };
    ws["D" + 1] = {
      t: "s",
      v: this.props.locale.locale === "en" ? "Vin Number" : "หมายเลขตัวถัง",
      s: {
        top: { style: "thin", color: { rgb: borderColor } },
        bottom: { style: "thin", color: { rgb: borderColor } },
        left: { style: "thin", color: { rgb: borderColor } },
        right: { style: "thin", color: { rgb: borderColor } },
        fgColor: { rgb: takumiBgColor },
        name: fontName,
        sz: fontSize,
        bold: true,
        color: { rgb: textWhiteColor },
        alignment: {
          horizontal: "center",
          vertical: "center",
          indent: 0,
          wrapText: true,
        },
      },
    };
    ws["E" + 1] = {
      t: "s",
      v: this.props.locale.locale === "en" ? "Points" : "คะแนน",
      s: {
        top: { style: "thin", color: { rgb: borderColor } },
        bottom: { style: "thin", color: { rgb: borderColor } },
        left: { style: "thin", color: { rgb: borderColor } },
        right: { style: "thin", color: { rgb: borderColor } },
        fgColor: { rgb: takumiBgColor },
        name: fontName,
        sz: fontSize,
        bold: true,
        color: { rgb: textWhiteColor },
        alignment: {
          horizontal: "center",
          vertical: "center",
          indent: 0,
          wrapText: true,
        },
      },
    };
    ws["F" + 1] = {
      t: "s",
      v:
        this.props.locale.locale === "en"
          ? "Next Point Expired Date"
          : "วันที่คะแนนหมดอายุ",
      s: {
        top: { style: "thin", color: { rgb: borderColor } },
        bottom: { style: "thin", color: { rgb: borderColor } },
        left: { style: "thin", color: { rgb: borderColor } },
        right: { style: "thin", color: { rgb: borderColor } },
        fgColor: { rgb: takumiBgColor },
        name: fontName,
        sz: fontSize,
        bold: true,
        color: { rgb: textWhiteColor },
        alignment: {
          horizontal: "center",
          vertical: "center",
          indent: 0,
          wrapText: true,
        },
      },
    };

    //Content
    for (var i = 0; i < items.length; i++) {
      row++;
      var dataArray = ["", "", "", "", "", ""];
      XLSX.utils.sheet_add_aoa(ws, [dataArray], { origin: "A" + row });

      ws["A" + row] = {
        t: "s",
        v: items[i].dealerCode,
        s: {
          bottom: { style: "thin", color: { rgb: borderColor } },
          left: { style: "thin", color: { rgb: borderColor } },
          right: { style: "thin", color: { rgb: borderColor } },
          fgColor: { rgb: whiteBgColor },
          name: fontName,
          sz: fontSize,
          bold: false,
          color: { rgb: textMainColor },
          alignment: {
            horizontal: "center",
            vertical: "center",
            indent: 0,
            wrapText: true,
          },
        },
      };
      ws["B" + row] = {
        t: "s",
        v:
          this.props.locale.locale === "th"
            ? items[i].dealerNameTh
            : items[i].dealerNameEn,
        s: {
          bottom: { style: "thin", color: { rgb: borderColor } },
          left: { style: "thin", color: { rgb: borderColor } },
          right: { style: "thin", color: { rgb: borderColor } },
          fgColor: { rgb: whiteBgColor },
          name: fontName,
          sz: fontSize,
          bold: false,
          color: { rgb: textMainColor },
          alignment: {
            horizontal: "left",
            vertical: "center",
            indent: 0,
            wrapText: true,
          },
        },
      };
      ws["C" + row] = {
        t: "s",
        v: items[i].fullname,
        s: {
          bottom: { style: "thin", color: { rgb: borderColor } },
          left: { style: "thin", color: { rgb: borderColor } },
          right: { style: "thin", color: { rgb: borderColor } },
          fgColor: { rgb: whiteBgColor },
          name: fontName,
          sz: fontSize,
          bold: false,
          color: { rgb: textMainColor },
          alignment: {
            horizontal: "left",
            vertical: "center",
            indent: 0,
            wrapText: true,
          },
        },
      };
      ws["D" + row] = {
        t: "s",
        v: items[i].vinNumber,
        s: {
          bottom: { style: "thin", color: { rgb: borderColor } },
          left: { style: "thin", color: { rgb: borderColor } },
          right: { style: "thin", color: { rgb: borderColor } },
          fgColor: { rgb: whiteBgColor },
          name: fontName,
          sz: fontSize,
          bold: false,
          color: { rgb: textMainColor },
          alignment: {
            horizontal: "center",
            vertical: "center",
            indent: 0,
            wrapText: true,
          },
        },
      };
      ws["E" + row] = {
        t: "s",
        v: items[i].totalPoints,
        s: {
          bottom: { style: "thin", color: { rgb: borderColor } },
          left: { style: "thin", color: { rgb: borderColor } },
          right: { style: "thin", color: { rgb: borderColor } },
          fgColor: { rgb: whiteBgColor },
          name: fontName,
          sz: fontSize,
          bold: false,
          color: { rgb: textMainColor },
          alignment: {
            horizontal: "center",
            vertical: "center",
            indent: 0,
            wrapText: true,
          },
        },
      };
      ws["F" + row] = {
        t: "s",
        v: items[i].nextPointExpiredDate,
        s: {
          bottom: { style: "thin", color: { rgb: borderColor } },
          left: { style: "thin", color: { rgb: borderColor } },
          right: { style: "thin", color: { rgb: borderColor } },
          fgColor: { rgb: whiteBgColor },
          name: fontName,
          sz: fontSize,
          bold: false,
          color: { rgb: textMainColor },
          alignment: {
            horizontal: "center",
            vertical: "center",
            indent: 0,
            wrapText: true,
          },
        },
      };
    }

    var currentDate = moment().tz("Asia/Bangkok").format("YYYYMMDD");
    //### Save xlsx file
    XLSX.writeFile(
      wb,
      "InactiveUsersByDealer-" +
        this.state.dealerCode +
        "-" +
        currentDate +
        ".xlsx",
      {
        cellStyles: true,
        bookImages: true,
      }
    );
  };

  showData = (userItems) => {
    //console.log("showData is CALL");
    if ((userItems !== undefined && userItems !== null) && (!this.props.loaderGetUsersByDefaultDealer)){
      if (userItems.length > 0) {
        //console.log('Num of users:' + userItems.length);

        let activeUsersArray = [];
        let inactiveUsersArray = [];

        let totalActiveHaveCar = 0;
        let totalInactiveHaveCar = 0;
        let totalActiveNoCar = 0;
        let totalInactiveNoCar = 0;

        let totalActive = 0;
        let totalInactive = 0;

        //Math.round(doneSurveyPercent * 10) / 10 + '%'

        // let newDate = new Date();
        // let cDate = newDate.getDate();
        // let cMonth = newDate.getMonth() + 1;
        // let cYear = newDate.getFullYear() - 1;
        // let currentDate = cDate + cMonth + cYear;
        // console.log('currentDate:' + currentDate);
        let currentDate = moment()
          .tz("Asia/Bangkok")
          .subtract(1, "years")
          .format("YYYYMMDD");
        //console.log('currentDate:' + currentDate);

        //let userObj2 = userItems[0];
        //console.log('DATA:' + JSON.stringify(userObj2));
        for (let i = 0; i < userItems.length; i++) {
          let userObj = userItems[i];
          //console.log('userId:' + userObj.objectId + ' dealerCode:' + userObj.dealerCode + ' firstname:' + userObj.firstname + ' ' + userObj.lastname);

          if (userObj.active) {
            let lastOnline = userObj.lastOnline.substring(0, 8);
            //console.log('lastOnline:' + lastOnline + ' currentDate:' + currentDate);
            let vinNumber = "";
            let displayTotalPoints = "";
            let displayPointExpiredDate = "";

            if (userObj.isHaveCar) {
              for (let a = 0; a < userObj.chassisNumber.length; a++) {
                vinNumber += userObj.chassisNumber[a];
                if (a < userObj.chassisNumber.length - 1) {
                  vinNumber += ", ";
                }
              }
              displayTotalPoints = this.numberWithCommas(userObj.totalPoint);

              if (userObj.nextPointExpiredDate !== undefined) {
                if (this.props.locale.locale === "th") {
                  displayPointExpiredDate = this.dateToStringTh(
                    userObj.nextPointExpiredDate
                  );
                } else {
                  displayPointExpiredDate = this.dateToStringEn(
                    userObj.nextPointExpiredDate
                  );
                }
              } else {
                //console.log('empty:' + userObj.objectId);
                let nextPointExpiredDateStr =
                  (
                    parseInt(userObj.createdAt.substring(0, 4), 0) + 2
                  ).toString() +
                  userObj.createdAt.substring(4, 6) +
                  userObj.createdAt.substring(6, 8);
                //console.log('date:' + nextPointExpiredDateStr);
                if (this.props.locale.locale === "th") {
                  displayPointExpiredDate = this.dateToStringTh(
                    nextPointExpiredDateStr
                  );
                } else {
                  displayPointExpiredDate = this.dateToStringEn(
                    nextPointExpiredDateStr
                  );
                }
                //console.log('FinalDate:' + displayPointExpiredDate);
              }
            }

            let itemObj = {
              dealerCode: userObj.dealerCode,
              dealerNameEn: userObj.dealerNameEn,
              dealerNameTh: userObj.dealerNameTh,
              fullname: userObj.fullname,
              vinNumber: vinNumber,
              totalPoints: displayTotalPoints,
              nextPointExpiredDate: displayPointExpiredDate,
              isHaveCar: userObj.isHaveCar,
            };

            if (lastOnline < currentDate) {
              inactiveUsersArray.push(itemObj);
              totalInactive += 1;
              if (userObj.isHaveCar) {
                totalInactiveHaveCar += 1;
              } else {
                totalInactiveNoCar += 1;
              }
            } else {
              activeUsersArray.push(itemObj);
              totalActive += 1;
              if (userObj.isHaveCar) {
                totalActiveHaveCar += 1;
              } else {
                totalActiveNoCar += 1;
              }
            }
          }
        }

        let totalAll = totalActive + totalInactive; //userItems.length;
        let numActivePercent = Math.round((totalActive / totalAll) * 100) + "%";
        let numInactivePercent =
          Math.round((totalInactive / totalAll) * 100) + "%";

        let totalAllHaveCar = totalActiveHaveCar + totalInactiveHaveCar;
        let numHaveCarActivePercent =
          Math.round((totalActiveHaveCar / totalAllHaveCar) * 100) + "%";
        let numHaveCarInactivePercent =
          Math.round((totalInactiveHaveCar / totalAllHaveCar) * 100) + "%";

        let totalAllNoCar = totalActiveNoCar + totalInactiveNoCar;
        let numNoCarActivePercent =
          Math.round((totalActiveNoCar / totalAllNoCar) * 100) + "%";
        let numNoCarInactivePercent =
          Math.round((totalInactiveNoCar / totalAllNoCar) * 100) + "%";
        //console.log('totalAllNoCar:' + totalAllNoCar + ' ActiveNoCar:' + totalActiveNoCar + ' InactiveNoCar:' + totalInactiveNoCar);

        // if (isNaN(numNoCarActivePercent)) {
        //   numNoCarActivePercent = '0%';
        // }

        // if (isNaN(numNoCarInactivePercent)) {
        //   numNoCarInactivePercent = '0%';
        // }

        //console.log('numNoCarActivePercent:' + numNoCarActivePercent + ' numNoCarInactivePercent:' + numNoCarInactivePercent);

        let totalSuzukiAll = 0;
        let totalSuzukiAllActive = 0;
        let totalSuzukiAllInactive = 0;

        let totalSuzukiAllHaveCar = 0;
        let totalSuzukiActiveHaveCar = 0;
        let totalSuzukiInactiveHaveCar = 0;

        let totalSuzukiAllNoCar = 0;
        let totalSuzukiActiveNoCar = 0;
        let totalSuzukiInactiveNoCar = 0;

        let slowUpdateDealer = "";
        let slowUpdateDate = "";

        if (
          this.state.allDealers !== undefined &&
          this.state.allDealers.length > 0
        ) {
          let dealerArray = this.state.allDealers;
          //let activeObj = this.state.allDealers[0];
          //console.log('DDDDDD:' + activeObj.totalAll + ' Count:' + activeObj.totalAll);

          if (dealerArray.length > 0) {
            let dealerObject = dealerArray[0];
            if (dealerObject.dealerCode === this.state.dealerCode) {
              dealerObject = dealerArray[1];
              slowUpdateDealer = dealerObject.dealerCode;
              slowUpdateDate = dealerObject.updatedDate;
            } else {
              slowUpdateDealer = dealerObject.dealerCode;
              slowUpdateDate = dealerObject.updatedDate;
            }
          }
          for (let k = 0; k < dealerArray.length; k++) {
            
            let dealerObj = dealerArray[k];     
            
            //console.log('dealerCode:' + dealerObj.dealerCode + ' updateDate:' + this.dateToStringEn(dealerObj.updatedDate));

            // if (k === 0) {
            //   console.log(
            //     "slowDealer:" +
            //       dealerObj.dealerCode +
            //       " updateDate:" +
            //       dealerObj.updatedDate
            //   );
            //   slowUpdateDealer = dealerObj.dealerCode;
            //   slowUpdateDate = dealerObj.updatedDate;
            // } else {
            //   if ((dealerObj.updatedDate < slowUpdateDate) && (dealerObj.dealerCode !== this.state.dealerCode)) {
            //     slowUpdateDealer = dealerObj.dealerCode;
            //     slowUpdateDate = dealerObj.updatedDate;
            //   }
            // }

            //console.log('date:' + dealerObj.updatedDate);

            totalSuzukiAll += dealerObj.totalAll;
            totalSuzukiAllActive += dealerObj.totalActive;
            totalSuzukiAllInactive += dealerObj.totalInactive;

            totalSuzukiAllHaveCar += dealerObj.totalAllHaveCar;
            totalSuzukiActiveHaveCar += dealerObj.totalActiveHaveCar;
            totalSuzukiInactiveHaveCar += dealerObj.totalInactiveHaveCar;

            totalSuzukiAllNoCar += dealerObj.totalAllNoCar;
            totalSuzukiActiveNoCar += dealerObj.totalActiveNoCar;
            totalSuzukiInactiveNoCar += dealerObj.totalInactiveNoCar;
          }
        }       

        let numSuzukiActivePercent =
          Math.round((totalSuzukiAllActive / totalSuzukiAll) * 100) + "%";
        let numSuzukiInactivePercent =
          Math.round((totalSuzukiAllInactive / totalSuzukiAll) * 100) + "%";

        //console.log('totalSuzukiAll:' + totalSuzukiAll + ' totalSuzukiAllActive:' + totalSuzukiAllActive + ' totalSuzukiAllInactive:' + totalSuzukiAllInactive);

        let displaySlowDealerUpdate = "";
        if (this.props.locale.locale === "th") {
          displaySlowDealerUpdate =
            "ข้อมูลเก่าสุดที่เรียกคือ " +
            slowUpdateDealer +
            " เมื่อ " +
            this.dateToStringTh(slowUpdateDate);
        } else {
          displaySlowDealerUpdate =
            "The last data retrieved is " +
            slowUpdateDealer +
            " on " +
            this.dateToStringEn(slowUpdateDate);
        }

        let currentDateTime = this.getCurrentDateTime();

        let updateData = {
          dealerCode: this.state.dealerCode,
          totalAll: totalAll,
          totalActive: totalActive,
          totalInactive: totalInactive,
          totalAllPercent: "100%",
          numActivePercent: numActivePercent,
          numInactivePercent: numInactivePercent,
          totalAllHaveCar: totalAllHaveCar,
          totalActiveHaveCar: totalActiveHaveCar,
          totalInactiveHaveCar: totalInactiveHaveCar,
          totalHaveCarPercent: "100%",
          numHaveCarActivePercent: numHaveCarActivePercent,
          numHaveCarInactivePercent: numHaveCarInactivePercent,
          totalAllNoCar: totalAllNoCar,
          totalActiveNoCar: totalActiveNoCar,
          totalInactiveNoCar: totalInactiveNoCar,
          totalNoCarPercent: "100%",
          numNoCarActivePercent: numNoCarActivePercent,
          numNoCarInactivePercent: numNoCarInactivePercent,
          updatedDate: currentDateTime,
          updatedTimestamp: new Date(),
        };
        this.props.updateActiveUsersRequest({ updateData });
        //admin.firestore.Timestamp.fromDate(new Date())

        return (
          <div className="row">
           <div className="col-xl-12 col-lg-12 col-md-12 col-12">
              <div className="d-flex justify-content-center">
                <CardBox styleName="col-lg-6 col-sm-6" heading="">
                  <center>
                    <Button
                      variant="contained"
                      className="jr-btn bg-green text-white"
                      onClick={() => {
                        this.exportToExcelActive(
                          activeUsersArray,
                          inactiveUsersArray
                        );
                        //this.exportToExcelInactive(inactiveUsersArray);
                      }}
                    >
                      <i className="zmdi zmdi-assignment-returned" />
                      <span>
                        <IntlMessages id="pages.activeuser.button.exporttoexcel" />
                      </span>
                    </Button>
                    <br />
                    <br />
                    {displaySlowDealerUpdate}
                  </center>
                 
                </CardBox>
              </div>
            </div>
            <div className="col-lg-12 col-sm-6 col-12">
              <div className="d-flex justify-content-center">
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="card jr-card-intra shadow text-center">
                    <div className="card-header py-3 d-flex align-items-center bg-primary">
                      <h4 className="mb-0 text-white">
                        <IntlMessages id="pages.activeusers.totalsuzuki" />
                      </h4>
                      <span className="badge badge-info ml-auto">
                        {/* <IntlMessages id="dashboard.monthly" /> */}
                        {this.props.locale.locale === "th" ? "ทั้งหมด" : "All"}
                      </span>
                    </div>
                    <div className="stack-order  py-4 px-2">
                      <h1 className="chart-f30">
                        {this.numberWithCommas(totalSuzukiAll)}
                      </h1>
                      <span className="h3 text-muted">
                        {/* <IntlMessages id="dashboard.totalView" /> */}
                      </span>
                      <span className="h5 text-info">
                        <i className="zmdi zmdi-circle zmdi-hc-fw" />
                        {"100%"}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="card jr-card-intra shadow text-center">
                    <div className="card-header py-3 d-flex align-items-center bg-primary">
                      <h4 className="mb-0 text-white">
                        <IntlMessages id="pages.activeusers.totalsuzuki" />
                      </h4>
                      <span className="badge badge-success ml-auto">
                        {/* <IntlMessages id="dashboard.monthly" /> */}
                        {this.props.locale.locale === "th"
                          ? "ลูกค้าใช้งาน"
                          : "Active Users"}
                      </span>
                    </div>
                    {/* <div className="stack-order  py-4 px-2 bg-red">  */}
                    <div className="stack-order  py-4 px-2">
                      <h1 className="chart-f30">
                        {this.numberWithCommas(totalSuzukiAllActive)}
                      </h1>
                      <span className="h3 text-muted">
                        {/* <IntlMessages id="dashboard.totalView" /> */}
                      </span>
                      <span className="h5 text-green">
                        <i className="zmdi zmdi-circle zmdi-hc-fw" />
                        {numSuzukiActivePercent}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="card jr-card-intra shadow text-center">
                    <div className="card-header py-3 d-flex align-items-center bg-primary">
                      <h4 className="mb-0 text-white">
                        <IntlMessages id="pages.activeusers.totalsuzuki" />
                      </h4>
                      <span className="badge badge-danger ml-auto">
                        {/* <IntlMessages id="dashboard.monthly" /> */}
                        {this.props.locale.locale === "th"
                          ? "ลูกค้าไม่ใช้งาน"
                          : "Inactive Users"}
                      </span>
                    </div>
                    <div className="stack-order  py-4 px-2">
                      <h1 className="chart-f30">
                        {this.numberWithCommas(totalSuzukiAllInactive)}
                      </h1>
                      <span className="h3 text-muted">
                        {/* <IntlMessages id="dashboard.totalView" /> */}
                      </span>
                      <span className="h5 text-red">
                        <i className="zmdi zmdi-circle zmdi-hc-fw" />
                        {numSuzukiInactivePercent}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Suzuki Total */}
            <div className="col-lg-12 col-sm-6 col-12">
              <div className="d-flex justify-content-center">
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="card jr-card-intra shadow text-center">
                    <div className="card-header py-3 d-flex align-items-center">
                      <h4 className="mb-0">
                        <IntlMessages id="pages.activeusers.totalinactive" />
                      </h4>
                      <span className="badge badge-info ml-auto">
                        {/* <IntlMessages id="dashboard.monthly" /> */}
                        {this.props.locale.locale === "th" ? "ทั้งหมด" : "All"}
                      </span>
                    </div>
                    <div className="stack-order  py-4 px-2">
                      <h1 className="chart-f30">
                        {this.numberWithCommas(totalAll)}
                      </h1>
                      <span className="h3 text-muted">
                        {/* <IntlMessages id="dashboard.totalView" /> */}
                      </span>
                      <span className="h5 text-info">
                        <i className="zmdi zmdi-circle zmdi-hc-fw" />
                        {"100%"}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="card jr-card-intra shadow text-center">
                    <div className="card-header py-3 d-flex align-items-center">
                      <h4 className="mb-0">
                        <IntlMessages id="pages.activeusers.totalactive" />
                      </h4>
                      <span className="badge badge-success ml-auto">
                        {/* <IntlMessages id="dashboard.monthly" /> */}
                        {this.props.locale.locale === "th"
                          ? "ลูกค้าใช้งาน"
                          : "Active Users"}
                      </span>
                    </div>
                    {/* <div className="stack-order  py-4 px-2 bg-red">  */}
                    <div className="stack-order  py-4 px-2">
                      <h1 className="chart-f30">
                        {this.numberWithCommas(totalActive)}
                      </h1>
                      <span className="h3 text-muted">
                        {/* <IntlMessages id="dashboard.totalView" /> */}
                      </span>
                      <span className="h5 text-green">
                        <i className="zmdi zmdi-circle zmdi-hc-fw" />
                        {numActivePercent}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="card jr-card-intra shadow text-center">
                    <div className="card-header py-3 d-flex align-items-center">
                      <h4 className="mb-0">
                        <IntlMessages id="pages.activeusers.totalinactive" />
                      </h4>
                      <span className="badge badge-danger ml-auto">
                        {/* <IntlMessages id="dashboard.monthly" /> */}
                        {this.props.locale.locale === "th"
                          ? "ลูกค้าไม่ใช้งาน"
                          : "Inactive Users"}
                      </span>
                    </div>
                    <div className="stack-order  py-4 px-2">
                      <h1 className="chart-f30">
                        {this.numberWithCommas(totalInactive)}
                      </h1>
                      <span className="h3 text-muted">
                        {/* <IntlMessages id="dashboard.totalView" /> */}
                      </span>
                      <span className="h5 text-red">
                        <i className="zmdi zmdi-circle zmdi-hc-fw" />
                        {numInactivePercent}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-sm-6 col-12">
              <div className="d-flex justify-content-center">
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="card jr-card-intra shadow text-center">
                    <div className="card-header py-3 d-flex align-items-center">
                      <h4 className="mb-0">
                        <IntlMessages id="pages.activeusers.totalhavecaractive" />
                      </h4>
                      <span className="badge badge-info ml-auto">
                        {/* <IntlMessages id="dashboard.monthly" /> */}
                        {this.props.locale.locale === "th" ? "ทั้งหมด" : "All"}
                      </span>
                    </div>
                    <div className="stack-order  py-4 px-2">
                      <h1 className="chart-f30">
                        {this.numberWithCommas(totalAllHaveCar)}
                      </h1>
                      <span className="h3 text-muted">
                        {/* <IntlMessages id="dashboard.totalView" /> */}
                      </span>
                      <span className="h5 text-info">
                        <i className="zmdi zmdi-circle zmdi-hc-fw" />
                        {"100%"}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="card jr-card-intra shadow text-center">
                    <div className="card-header py-3 d-flex align-items-center">
                      <h4 className="mb-0">
                        <IntlMessages id="pages.activeusers.totalhavecaractive" />
                      </h4>
                      <span className="badge badge-success ml-auto">
                        {/* <IntlMessages id="dashboard.monthly" /> */}
                        {this.props.locale.locale === "th"
                          ? "ลูกค้าใช้งาน"
                          : "Active Users"}
                      </span>
                    </div>
                    {/* <div className="stack-order  py-4 px-2 bg-red">  */}
                    <div className="stack-order  py-4 px-2">
                      <h1 className="chart-f30">
                        {this.numberWithCommas(totalActiveHaveCar)}
                      </h1>
                      <span className="h3 text-muted">
                        {/* <IntlMessages id="pages.activeusers.totalinactive" /> */}
                      </span>
                      <span className="h5 text-green">
                        <i className="zmdi zmdi-circle zmdi-hc-fw" />
                        {numHaveCarActivePercent}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="card jr-card-intra shadow text-center">
                    <div className="card-header py-3 d-flex align-items-center">
                      <h4 className="mb-0">
                        <IntlMessages id="pages.activeusers.totalhavecarinactive" />
                      </h4>
                      <span className="badge badge-danger ml-auto">
                        {this.props.locale.locale === "th"
                          ? "ลูกค้าไม่ใช้งาน"
                          : "Inactive Users"}
                      </span>
                    </div>
                    <div className="stack-order  py-4 px-2">
                      <h1 className="chart-f30">
                        {this.numberWithCommas(totalInactiveHaveCar)}
                      </h1>
                      <span className="h3 text-muted">
                        {/* <IntlMessages id="pages.activeusers.totalhavecarinactive" /> */}
                      </span>
                      <span className="h5 text-red">
                        <i className="zmdi zmdi-circle zmdi-hc-fw" />
                        {numHaveCarInactivePercent}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-sm-6 col-12">
              <div className="d-flex justify-content-center">
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="card jr-card-intra shadow text-center">
                    <div className="card-header py-3 d-flex align-items-center">
                      <h4 className="mb-0">
                        <IntlMessages id="pages.activeusers.totalnocaractive" />
                      </h4>
                      <span className="badge badge-info ml-auto">
                        {/* <IntlMessages id="dashboard.monthly" /> */}
                        {this.props.locale.locale === "th" ? "ทั้งหมด" : "All"}
                      </span>
                    </div>
                    <div className="stack-order  py-4 px-2">
                      <h1 className="chart-f30">
                        {this.numberWithCommas(totalAllNoCar)}
                      </h1>
                      <span className="h3 text-muted">
                        {/* <IntlMessages id="dashboard.totalView" /> */}
                      </span>
                      <span className="h5 text-info">
                        <i className="zmdi zmdi-circle zmdi-hc-fw" />
                        {"100%"}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="card jr-card-intra shadow text-center">
                    <div className="card-header py-3 d-flex align-items-center">
                      <h4 className="mb-0">
                        <IntlMessages id="pages.activeusers.totalnocaractive" />
                      </h4>
                      <span className="badge badge-success ml-auto">
                        {/* <IntlMessages id="dashboard.monthly" /> */}
                        {this.props.locale.locale === "th"
                          ? "ลูกค้าใช้งาน"
                          : "Active Users"}
                      </span>
                    </div>
                    {/* <div className="stack-order  py-4 px-2 bg-red">  */}
                    <div className="stack-order  py-4 px-2">
                      <h1 className="chart-f30">
                        {this.numberWithCommas(totalActiveNoCar)}
                      </h1>
                      <span className="h3 text-muted">
                        {/* <IntlMessages id="pages.activeusers.totalinactive" /> */}
                      </span>
                      <span className="h5 text-green">
                        <i className="zmdi zmdi-circle zmdi-hc-fw" />
                        {numNoCarActivePercent}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="card jr-card-intra shadow text-center">
                    <div className="card-header py-3 d-flex align-items-center">
                      <h4 className="mb-0">
                        <IntlMessages id="pages.activeusers.totalnocarinactive" />
                      </h4>
                      <span className="badge badge-danger ml-auto">
                        {this.props.locale.locale === "th"
                          ? "ลูกค้าไม่ใช้งาน"
                          : "Inactive Users"}
                      </span>
                    </div>
                    <div className="stack-order  py-4 px-2">
                      <h1 className="chart-f30">
                        {this.numberWithCommas(totalInactiveNoCar)}
                      </h1>
                      <span className="h3 text-muted">
                        {/* <IntlMessages id="pages.activeusers.totalhavecarinactive" /> */}
                      </span>
                      <span className="h5 text-red">
                        <i className="zmdi zmdi-circle zmdi-hc-fw" />
                        {numNoCarInactivePercent}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>            
          </div>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  render() {
    let role = localStorage.getItem("role");
    if (role !== "smt" && role !== "superAdmin") {
      if (role.length === 6) {
        return <Redirect to={"/app/dashboard/register-users"} />;
      } else {
        return <Redirect to={"/app/customers/request-delete"} />;
      }
    }

    const { dealerCode, dealers, allDealers } = this.state;
    const {
      loader,
      items,
      loaderGetUsersByDefaultDealer,
      userItems,
      loaderGetActiveUsersAll,
      dataItems,
    } = this.props;

    return (
      <div className="app-wrapper">
        <ContainerHeader
          match={this.props.match}
          title={<IntlMessages id="pages.activeusers" />}
        />
        <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3"></div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <div className="d-flex justify-content-center">
              <CardBox
                styleName="col-7"
                cardStyle="p-5"
                heading={<IntlMessages id="pages.activeusers.label.title" />}
              >
                <div className="col-lg-12 col-sm-12 col-12">
                  <FormControl className="w-100 mb-2">
                    <InputLabel htmlFor="dealer-simple">
                      <IntlMessages id="form.dropdown.dealer" />
                    </InputLabel>
                    <Select
                      // options={_.uniqBy(this.props.dealerItems, (e) => (e.dealerCode))}
                      value={this.state.dealerCode}
                      onChange={this.handleDealerChange("dealerCode")}
                      input={<Input id="dealerId" />}
                    >
                      {/* <MenuItem value="">
                <em>None</em>
              </MenuItem> */}
                      {/* <MenuItem value={'2020'}>{2020}</MenuItem>
              <MenuItem value={'2021'}>{2021}</MenuItem> */}

                      {loader ? (
                        <MenuItem value="">
                          <em>None</em>{" "}
                          {/* {this.props.locale.locale === 'en'
                        ? 'All Dealers'
                        : 'ทั้งหมด'} */}
                        </MenuItem>
                      ) : (
                        dealers.map((element) => (
                          <MenuItem value={element.dealerCode} key={element.id}>
                            {element.dealerCode}{" "}
                            {this.props.locale.locale === "en"
                              ? element.dealerNameEn
                              : element.dealerNameTh}
                          </MenuItem>
                          // this.props.dealerItems
                          //   .filter(element => this.state.month === element.taxMonth)
                          //   .map(element => (
                          //     <MenuItem value={element.dealerCode} key={element.id}>
                          //       {element.dealerCode}{' '}
                          //       {this.props.locale.locale === 'en'
                          //         ? element.dealerNameEn
                          //         : element.dealerNameTh}
                          //     </MenuItem>
                        ))
                      )}
                    </Select>
                  </FormControl>
                </div>
                {/* <section>
                  <Button
                    variant="contained"
                    onClick={(event) => {
                      if (event) event.preventDefault();

                      // if (this.state.phoneNumber === '') {
                      //   NotificationManager.warning(
                      //     <IntlMessages id="notification.warning" />,
                      //     <IntlMessages id="pages.activeusers.message.phonenumberempty" />,
                      //     3000
                      //   );
                      //   return;
                      // }
                      if (this.state.dealerCode === "") {
                        NotificationManager.warning(
                          <IntlMessages id="notification.warning" />,
                          <IntlMessages id="pages.activeusers.message.dealercodeempty" />,
                          3000
                        );
                        return;
                      }

                      //console.log('button:' + this.state.dealerCode);

                      this.props.showLoaderGetActiveUsersAll();
                      this.props.getActiveUsersAllRequest();

                      this.props.showLoaderGetUsersByDefaultDealer();
                      this.props.getUsersByDefaultDealerRequest(
                        this.state.dealerCode
                      );
                    }}
                    color="primary"
                  >
                    <IntlMessages id="pages.activeusers.button.getdata" />
                  </Button>
                  &nbsp;&nbsp;&nbsp;&nbsp;                  
                </section> */}
              </CardBox>
            </div>
          </div>
        </div>
        {(loader || loaderGetActiveUsersAll) && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}

        {/* {loaderGetActiveUsersAll && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )} */}

        {loaderGetUsersByDefaultDealer && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}

        {/* { this.state.dealerCode.length > 0 ? this.showData(this.props.userItems) : null } */}
        {/* {loaderGetUsersByDefaultDealer ? this.showData(this.props.userItems) : null } */}
        {this.showData(this.props.userItems)}
        <NotificationContainer />
      </div>
    );
  }
}

const mapStateToProps = ({ dealers, activeUsers, settings }) => {
  const { loader, items } = dealers;
  const {
    dataItems,
    loaderGetActiveUsersAll,
    userItems,
    loaderGetUsersByDefaultDealer,
  } = activeUsers;
  //const { loaderUsersByDealer, usersByDealerItems } = users;
  const { locale } = settings;
  return {
    loader,
    items,
    dataItems,
    loaderGetActiveUsersAll,
    userItems,
    loaderGetUsersByDefaultDealer,
    locale: settings.locale,
  };
};

export default connect(mapStateToProps, {
  showLoaderGetUsersByDefaultDealer,
  getUsersByDefaultDealerRequest,
  showLoaderGetActiveUsersAll,
  getActiveUsersAllRequest,
  updateActiveUsersRequest,
  showLoader,
  getDealersRequest,
})(ActiveUsers);
