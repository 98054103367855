import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from 'components/Header/index';
import Sidebar from 'containers/SideNav/index';
import Footer from 'components/Footer';
import Tour from '../components/Tour/index';
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION
} from 'constants/ActionTypes';
import { isIOS, isMobile } from 'react-device-detect';
import asyncComponent from '../util/asyncComponent';
import TopNav from 'components/TopNav';
import AppModule from './routes/appModule';
import RegisterUser from './routes/dashboard';
import AnalyticUsers from './routes/analytics';
import Dealers from './routes/dealers';
import Users from './routes/users';
import ServiceSurvey from './routes/serviceSurvey';
import UsersByDealer from './routes/users/routes/Dealers/routes/UsersByDealer';
import UsersByDefaultDealer from './routes/users/routes/Dealers/routes/UsersByDefaultDealer';
import FriendsByDealer from './routes/users/routes/FriendsDealers/routes/FriendsByDealer';
import ChatHistory from './routes/chatHistory';
import ChatUsersByDealer from './routes/chatHistory/routes/Dealers/routes/ChatUsersByDealer';
import ChatUserHistory from './routes/chatHistory/routes/Dealers/routes/ChatUsersByDealer/routes/ChatUserHistory';
import DealerChats from './routes/chatHistory/routes/Dealers/routes/ChatUsersByDealer/routes/DealerChats';
import AddDealerData from './routes/dealers/routes/CreateDealerAccount/routes/AddDealerData';
import Privilege from './routes/privilege';
import EditReward from './routes/privilege/routes/RewardList/routes/EditReward';
import ChangePhone from './routes/users/routes/ChangePhone';
import SecondHandCar from './routes/users/routes/SecondHandCar';
import ContactUsQuery from './routes/contactUs';
import SearchUser from './routes/users/routes/SearchUser';
import ActiveUsers from './routes/users/routes/ActiveUsers';
import RequestDelete from './routes/customers/routes/RequestDelete';
import TermsAndPolicy from './routes/customers/routes/TermsAndPolicy';
import Error404 from './routes/extraPages/routes/404';

class App extends React.Component {
  render() {
    const {
      match,
      drawerType,
      navigationStyle,
      horizontalNavPosition
    } = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? 'fixed-drawer'
      : drawerType.includes(COLLAPSED_DRAWER)
      ? 'collapsible-drawer'
      : 'mini-drawer';

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add('ios-mobile-view-height');
    } else if (document.body.classList.contains('ios-mobile-view-height')) {
      document.body.classList.remove('ios-mobile-view-height');
    }

    return (
      <div className={`app-container ${drawerStyle}`}>
        <Tour />

        <Sidebar />
        <div className="app-main-container">
          <div
            className={`app-header ${
              navigationStyle === HORIZONTAL_NAVIGATION
                ? 'app-header-horizontal'
                : ''
            }`}
          >
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === ABOVE_THE_HEADER && (
                <TopNav styleName="app-top-header" />
              )}
            <Header />
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === BELOW_THE_HEADER && <TopNav />}
          </div>

          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              <Switch>
                <Route path={`${match.url}/app-module`} component={AppModule} />
                <Route
                  path={`${match.url}/analytics`}
                  component={AnalyticUsers}
                />
                <Route
                  path={`${match.url}/dashboard`}
                  component={RegisterUser}
                />
                <Route
                  path={`${match.url}/dealers/create-dealer-account/add-dealer-data/:userid`}
                  component={AddDealerData}
                />
                <Route path={`${match.url}/dealers`} component={Dealers} />
                <Route
                  path={`${match.url}/users/dealers/users-by-dealer/:dealercode`}
                  component={UsersByDealer}
                />
                <Route
                  path={`${match.url}/users/dealers/users-by-default-dealer/:dealercode`}
                  component={UsersByDefaultDealer}
                />
                <Route
                  path={`${match.url}/users/friendsdealers/friends-by-dealer/:dealercode`}
                  component={FriendsByDealer}
                />

                <Route path={`${match.url}/users`} component={Users} />
                <Route
                  path={`${match.url}/service-survey`}
                  component={ServiceSurvey}
                />
                <Route
                  path={`${match.url}/chat-history/dealers/users-by-dealer/:dealercode/dealer-chats/:userid`}
                  component={DealerChats}
                />
                <Route
                  path={`${match.url}/chat-history/dealers/users-by-dealer/:dealercode/chat-user-history/:userid/:fullname`}
                  component={ChatUserHistory}
                />
                <Route
                  path={`${match.url}/chat-history/dealers/users-by-dealer/:dealercode`}
                  component={ChatUsersByDealer}
                />
                <Route
                  path={`${match.url}/chat-history`}
                  component={ChatHistory}
                />
                <Route
                  path={`${match.url}/privilege/reward-list/edit-reward/:rewardid`}
                  component={EditReward}
                />
                <Route path={`${match.url}/privilege`} component={Privilege} />
                <Route path={`${match.url}/users/user-change-phone`} component={ChangePhone} />
                <Route path={`${match.url}/users/second-hand-car`} component={SecondHandCar} />
                <Route
                  path={`${match.url}/contactUs`}
                  component={ContactUsQuery}
                />
                <Route path={`${match.url}/users/search-user`} component={SearchUser} />
                <Route path={`${match.url}/users/active-users`} component={ActiveUsers} />

                <Route path={`${match.url}/customers/request-delete`} component={RequestDelete} />
                <Route path={`${match.url}/customers/terms-and-policy`} component={TermsAndPolicy} />

                {/* <Route path={`${match.url}/extraPages/routes/404`} component={Error404} /> */}
                {/* <Route
                  path={`${match.url}/chat-history/dealers/users-by-dealer/100002/chat-user-history`}
                  component={ChatUserHistory}
                /> */}

                <Route
                  path={`${match.url}/extraPages/routes/404`}
                  component={asyncComponent(() =>
                    import('./routes/extraPages/routes/404')
                  )}
                />

                {/* <Route
                  path={`${match.url}/sample-page`}
                  component={asyncComponent(() =>
                    import('./routes/SamplePage')
                  )}
                /> */}
              </Switch>
            </div>
            <Footer />
          </main>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { drawerType, navigationStyle, horizontalNavPosition } = settings;
  return { drawerType, navigationStyle, horizontalNavPosition };
};
export default withRouter(connect(mapStateToProps)(App));
