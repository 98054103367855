import React from "react";
import ContainerHeader from "components/ContainerHeader";
import IntlMessages from "util/IntlMessages";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import {
  showLoaderChatHistoryByChatroomid,
  getChatHistoryByChatroomidRequest,
} from "actions/Chats";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CardBox from "components/CardBox";
import Button from "@material-ui/core/Button";
//import { Link } from 'react-router-dom';
import XLSX from "@sheet/chart"; // Pro Basic + Pro Chart
import moment from "moment-timezone";
import { Redirect } from 'react-router-dom';

class ChatUserHistory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dealercode: this.props.match.params.dealercode,
      userid: this.props.match.params.userid,
      fullname: this.props.match.params.fullname,
    };
    // console.log('dealercode:' + this.props.match.params.dealercode);
    // console.log('userid:' + this.props.match.params.userid);
    // console.log('fullname:' + this.props.match.params.fullname);
    // this.setState({
    //   dealercode: this.props.match.params.dealercode
    // });

    //console.log('DEALERCODE_PARAM:' + this.props.match.params.dealercode);
    //console.log('USERID_PARAM:' + this.props.match.params.userid);

    // var chatRoomId = '';
    // if (this.state.dealercode < this.state.userid) {
    //   chatRoomId = this.state.dealercode + this.state.userid;
    // } else {
    //   chatRoomId = this.state.userid + this.state.dealercode;
    // }

    this.props.showLoaderChatHistoryByChatroomid();
    this.props.getChatHistoryByChatroomidRequest(
      this.state.dealercode,
      this.state.userid
    );
  }

  backPressed = (e) => {
    e.preventDefault();

    this.props.history.goBack();
  };

  dateToString = (dateData) => {
    let year = dateData.substring(0, 4);
    let month = dateData.substring(4, 6);
    let day = dateData.substring(6, 8);
    let hr = dateData.substring(8, 10);
    let mi = dateData.substring(10, 12);
    let sec = dateData.substring(12, 14);
    return day + "/" + month + "/" + year + " " + hr + ":" + mi + ":" + sec;
  };

  displayLink = (message) => {
    var link = "#";
    if (message.type === "picture") {
      link = message.picture;
    } else if (message.type === "video") {
      link = message.video;
    } else if (message.type === "file") {
      link = message.file;
    } else if (message.type === "location") {
      link =
        "http://maps.google.com/maps?q=" +
        message.latitude +
        "," +
        message.longitude;
    }
    return link;
    //return '<a href="' + link + '">link text</a>';
    //return '<Link to="' + link + '">link text</Link>';
  };

  displayLinkText = (message) => {
    var linkText = "";
    if (this.props.locale.locale === "en") {
      if (message.type === "picture") {
        linkText = "Click to see a picture";
      } else if (message.type === "video") {
        linkText = "Click to watch a video";
      } else if (message.type === "file") {
        linkText = "Click to see a file";
      } else if (message.type === "location") {
        linkText = "Click to see location on map";
      }
    }

    if (this.props.locale.locale === "th") {
      if (message.type === "picture") {
        linkText = "คลิกเพื่อดูรูป";
      } else if (message.type === "video") {
        linkText = "คลิกเพื่อดูวีดีโอ";
      } else if (message.type === "file") {
        linkText = "คลิกเพื่อดูไฟล์";
      } else if (message.type === "location") {
        linkText = "คลิกเพื่อดูตำแหน่งบนแผนที่";
      }
    }

    return linkText;
  };

  isUnsent = (isUnsent, senderId) => {
    var text = "";
    if (isUnsent !== undefined) {
      if (isUnsent) {
        if (this.props.locale.locale === "th") {
          if (senderId.length > 6) {
            text = "ลูกค้ายกเลิกข้อความ"; // Unsent by user
          } else {
            text = "ดีลเลอร์ยกเลิกข้อความ"; // Unsent by dealer
          }
        } else {
          if (senderId.length > 6) {
            text = "Customer unsent the message."; // Unsent by user
          } else {
            text = "Dealer unsent the message"; // Unsent by dealer
          }
        }
      }
    }
    return text;
  };

  isDeleted = (isDeleted) => {
    var text = "";
    if (isDeleted !== undefined) {
      if (isDeleted) {
        if (this.props.locale.locale === "th") {
          text = "ข้อความถูกลบ";
          // if (senderId.length > 6) {
          //   text = ''; // Unsent by user
          // } else {
          //   text = ''; // Unsent by dealer
          // }
        } else {
          text = "The message is deleted.";
          // if (senderId.length > 6) {
          //   text = ''; // Unsent by user
          // } else {
          //   text = ''; // Unsent by dealer
          // }
        }
      }
    }
    return text;
  };

  exportToExcel = (items) => {
    // var workbook = { SheetNames: [], Sheets: {} };
    // const wsname0 = workbook.SheetNames[0];
    // console.log('wsname:' + wsname0);
    // var ws0 = workbook.Sheets[wsname0];

    const fontName = "Calibri";
    const fontSize = "11";
    const borderColor = 0x808080;
    // const csiBgColor = 0xe4efdc;
    // const answerBgColor = 0xdfeaf5;
    // const sumBgColor = 0xdadada;
    // const totalBgColor = 0x595959;
    const whiteBgColor = 0xffffff;
    const textMainColor = 0x3f3f3f;
    const textWhiteColor = 0xffffff;
    const takumiBgColor = 0x003145;
    const textLinkColor = 0x0044cc;

    // const textColor = "#7f7f7f";

    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.aoa_to_sheet([[]]);
    var wsname = "Dealer List";
    XLSX.utils.book_append_sheet(wb, ws, wsname);

    //var ws_data = ['Dealer Code', 'Dealer Name Thai', 'Dealer Name English', 'Customers Count'];
    //var ws = XLSX.utils.aoa_to_sheet(ws_data);
    //var ws = workbook.Sheets[wsname];

    /* Set worksheet sheet to "normal" */
    ws["!margins"] = {
      left: 0.7,
      right: 0.7,
      top: 0.75,
      bottom: 0.75,
      header: 0.3,
      footer: 0.3,
    };

    var wscols = [
      { width: 40 },
      { width: 50 },
      { width: 50 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
    ];
    ws["!cols"] = wscols;
    //ws['!rows']

    ws["!gridlines"] = false;

    ws["!freeze"] = "A2"; // Freeze first row               bottom pane starts at A2
    //ws["!freeze"] = "B1"; // Freeze first column             right pane starts at B1
    //ws["!freeze"] = "B2"; // Freeze row and column    bottom-right pane starts at B2

    // ws['!freeze'] = {
    //   xSplit: '1',
    //   ySplit: '1',
    //   topLeftCell: 'B2',
    //   activePane: 'bottomRight',
    //   state: 'frozen'
    // };

    var row = 1;

    var dataArray = ["", "", "", "", "", "", ""];
    XLSX.utils.sheet_add_aoa(ws, [dataArray], { origin: "A" + row });

    //Header
    ws["A" + 1] = {
      t: "s",
      v: this.props.locale.locale === "en" ? "Sender Name" : "ผู้ส่ง",
      s: {
        top: { style: "thin", color: { rgb: borderColor } },
        bottom: { style: "thin", color: { rgb: borderColor } },
        left: { style: "thin", color: { rgb: borderColor } },
        right: { style: "thin", color: { rgb: borderColor } },
        fgColor: { rgb: takumiBgColor },
        name: fontName,
        sz: fontSize,
        bold: true,
        color: { rgb: textWhiteColor },
        alignment: {
          horizontal: "center",
          vertical: "center",
          indent: 0,
          wrapText: true,
        },
      },
    };
    ws["B" + 1] = {
      t: "s",
      v: this.props.locale.locale === "en" ? "Customer" : "ลูกค้า",
      s: {
        top: { style: "thin", color: { rgb: borderColor } },
        bottom: { style: "thin", color: { rgb: borderColor } },
        left: { style: "thin", color: { rgb: borderColor } },
        right: { style: "thin", color: { rgb: borderColor } },
        fgColor: { rgb: takumiBgColor },
        name: fontName,
        sz: fontSize,
        bold: true,
        color: { rgb: textWhiteColor },
        alignment: {
          horizontal: "center",
          vertical: "center",
          indent: 0,
          wrapText: true,
        },
      },
    };
    ws["C" + 1] = {
      t: "s",
      v: this.props.locale.locale === "en" ? "Dealer" : "ตัวแทน",
      s: {
        top: { style: "thin", color: { rgb: borderColor } },
        bottom: { style: "thin", color: { rgb: borderColor } },
        left: { style: "thin", color: { rgb: borderColor } },
        right: { style: "thin", color: { rgb: borderColor } },
        fgColor: { rgb: takumiBgColor },
        name: fontName,
        sz: fontSize,
        bold: true,
        color: { rgb: textWhiteColor },
        alignment: {
          horizontal: "center",
          vertical: "center",
          indent: 0,
          wrapText: true,
        },
      },
    };
    ws["D" + 1] = {
      t: "s",
      v: this.props.locale.locale === "en" ? "Sent Date" : "วันที่ส่ง",
      s: {
        top: { style: "thin", color: { rgb: borderColor } },
        bottom: { style: "thin", color: { rgb: borderColor } },
        left: { style: "thin", color: { rgb: borderColor } },
        right: { style: "thin", color: { rgb: borderColor } },
        fgColor: { rgb: takumiBgColor },
        name: fontName,
        sz: fontSize,
        bold: true,
        color: { rgb: textWhiteColor },
        alignment: {
          horizontal: "center",
          vertical: "center",
          indent: 0,
          wrapText: true,
        },
      },
    };
    ws["E" + 1] = {
      t: "s",
      v: this.props.locale.locale === "en" ? "Link" : "ลิงค์",
      s: {
        top: { style: "thin", color: { rgb: borderColor } },
        bottom: { style: "thin", color: { rgb: borderColor } },
        left: { style: "thin", color: { rgb: borderColor } },
        right: { style: "thin", color: { rgb: borderColor } },
        fgColor: { rgb: takumiBgColor },
        name: fontName,
        sz: fontSize,
        bold: true,
        color: { rgb: textWhiteColor },
        alignment: {
          horizontal: "center",
          vertical: "center",
          indent: 0,
          wrapText: true,
        },
      },
    };
    ws["F" + 1] = {
      t: "s",
      v: this.props.locale.locale === "en" ? "Unsent" : "ยกเลิกข้อความ",
      s: {
        top: { style: "thin", color: { rgb: borderColor } },
        bottom: { style: "thin", color: { rgb: borderColor } },
        left: { style: "thin", color: { rgb: borderColor } },
        right: { style: "thin", color: { rgb: borderColor } },
        fgColor: { rgb: takumiBgColor },
        name: fontName,
        sz: fontSize,
        bold: true,
        color: { rgb: textWhiteColor },
        alignment: {
          horizontal: "center",
          vertical: "center",
          indent: 0,
          wrapText: true,
        },
      },
    };
    ws["G" + 1] = {
      t: "s",
      v: this.props.locale.locale === "en" ? "Deleted" : "ข้อความถูกลบ",
      s: {
        top: { style: "thin", color: { rgb: borderColor } },
        bottom: { style: "thin", color: { rgb: borderColor } },
        left: { style: "thin", color: { rgb: borderColor } },
        right: { style: "thin", color: { rgb: borderColor } },
        fgColor: { rgb: takumiBgColor },
        name: fontName,
        sz: fontSize,
        bold: true,
        color: { rgb: textWhiteColor },
        alignment: {
          horizontal: "center",
          vertical: "center",
          indent: 0,
          wrapText: true,
        },
      },
    };

    for (var i = 0; i < items.length; i++) {
      row++;
      var dataArray = ["", "", "", "", "", "", ""];
      XLSX.utils.sheet_add_aoa(ws, [dataArray], { origin: "A" + row });

      ws["A" + row] = {
        t: "s",
        v: items[i].senderName,
        s: {
          bottom: { style: "thin", color: { rgb: borderColor } },
          left: { style: "thin", color: { rgb: borderColor } },
          right: { style: "thin", color: { rgb: borderColor } },
          fgColor: { rgb: whiteBgColor },
          name: fontName,
          sz: fontSize,
          bold: false,
          color: { rgb: textMainColor },
          alignment: {
            horizontal: "center",
            vertical: "center",
            indent: 0,
            wrapText: true,
          },
        },
      };
      ws["B" + row] = {
        t: "s",
        v: items[i].senderId === this.state.userid ? items[i].message : "",
        s: {
          bottom: { style: "thin", color: { rgb: borderColor } },
          left: { style: "thin", color: { rgb: borderColor } },
          right: { style: "thin", color: { rgb: borderColor } },
          fgColor: { rgb: whiteBgColor },
          name: fontName,
          sz: fontSize,
          bold: false,
          color: { rgb: textMainColor },
          alignment: {
            horizontal: "left",
            vertical: "center",
            indent: 0,
            wrapText: true,
          },
        },
      };
      ws["C" + row] = {
        t: "s",
        v: items[i].senderId === this.state.dealercode ? items[i].message : "",
        //r: '😘\u2728', //'U+1F601',
        s: {
          bottom: { style: "thin", color: { rgb: borderColor } },
          left: { style: "thin", color: { rgb: borderColor } },
          right: { style: "thin", color: { rgb: borderColor } },
          fgColor: { rgb: whiteBgColor },
          name: fontName,
          sz: fontSize,
          bold: false,
          color: { rgb: textMainColor },
          alignment: {
            horizontal: "left",
            vertical: "center",
            indent: 0,
            wrapText: true,
          },
        },
      };
      ws["D" + row] = {
        t: "s",
        v: this.dateToString(items[i].date),
        s: {
          bottom: { style: "thin", color: { rgb: borderColor } },
          left: { style: "thin", color: { rgb: borderColor } },
          right: { style: "thin", color: { rgb: borderColor } },
          fgColor: { rgb: whiteBgColor },
          name: fontName,
          sz: fontSize,
          bold: false,
          color: { rgb: textMainColor },
          alignment: {
            horizontal: "center",
            vertical: "center",
            indent: 0,
            wrapText: true,
          },
        },
      };
      // ws['A3'].l = {
      //   Target: this.displayLink(items[i]),
      //   Tooltip: 'Find us @ SheetJS.com!'
      // };
      ws["E" + row] = {
        t: "s",
        v: this.displayLinkText(items[i]),
        l: { Target: this.displayLink(items[i]), Tooltip: "Click!" },
        s: {
          bottom: { style: "thin", color: { rgb: borderColor } },
          left: { style: "thin", color: { rgb: borderColor } },
          right: { style: "thin", color: { rgb: borderColor } },
          fgColor: { rgb: whiteBgColor },
          name: fontName,
          sz: fontSize,
          bold: false,
          color: { rgb: textLinkColor },
          underline: true,
          alignment: {
            horizontal: "center",
            vertical: "center",
            indent: 0,
            wrapText: true,
          },
        },
      };
      ws["F" + row] = {
        t: "s",
        v: this.isUnsent(items[i].isUnsent, items[i].senderId),
        s: {
          bottom: { style: "thin", color: { rgb: borderColor } },
          left: { style: "thin", color: { rgb: borderColor } },
          right: { style: "thin", color: { rgb: borderColor } },
          fgColor: { rgb: whiteBgColor },
          name: fontName,
          sz: fontSize,
          bold: false,
          color: { rgb: textMainColor },
          alignment: {
            horizontal: "center",
            vertical: "center",
            indent: 0,
            wrapText: true,
          },
        },
      };
      ws["G" + row] = {
        t: "s",
        v: this.isDeleted(items[i].isDeleted),
        s: {
          bottom: { style: "thin", color: { rgb: borderColor } },
          left: { style: "thin", color: { rgb: borderColor } },
          right: { style: "thin", color: { rgb: borderColor } },
          fgColor: { rgb: whiteBgColor },
          name: fontName,
          sz: fontSize,
          bold: false,
          color: { rgb: textMainColor },
          alignment: {
            horizontal: "center",
            vertical: "center",
            indent: 0,
            wrapText: true,
          },
        },
      };
    }

    var currentDate = moment().tz("Asia/Bangkok").format("YYYYMMDD");
    //### Save xlsx file
    XLSX.writeFile(
      wb,
      "ChatHistory-" +
        this.state.dealercode +
        "-" +
        this.state.fullname +
        "-" +
        currentDate +
        ".xlsx",
      {
        cellStyles: true,
        //bookImages: true,
        //bookSST: true
      }
    );
  };

  showChatHistory = (chatHistoryItems) => {
    if (
      chatHistoryItems !== undefined &&
      chatHistoryItems != null &&
      chatHistoryItems.length !== 0
    ) {
      //const dataSet = this.dataForExportToExcel(items);
      return (
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <div className="d-flex justify-content-center">
              <CardBox
                styleName="col-lg-6 col-sm-6"
                // heading={<IntlMessages id="picker.date.date" />}
              >
                <center>
                  <Button
                    variant="contained"
                    className="jr-btn bg-green text-white"
                    onClick={() => {
                      this.exportToExcel(chatHistoryItems);
                      //this.createShapeToExcel();
                    }}
                  >
                    <i className="zmdi zmdi-assignment-returned" />
                    <span>
                      <IntlMessages id="pages.dealerlist.button.exporttoexcel" />
                    </span>
                  </Button>
                </center>
              </CardBox>
            </div>
          </div>
          <CardBox styleName="col-12" cardStyle="p-0">
            <div className="table-responsive-material">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" width="15%">
                      {<IntlMessages id="pages.chathistory.table.sendername" />}
                    </TableCell>
                    <TableCell align="center" width="30%">
                      {<IntlMessages id="pages.chathistory.table.customer" />}
                    </TableCell>
                    <TableCell align="center" width="30%">
                      {<IntlMessages id="pages.chathistory.table.dealer" />}
                    </TableCell>
                    <TableCell align="center" width="10%">
                      {<IntlMessages id="pages.chathistory.table.sentdate" />}
                    </TableCell>
                    <TableCell align="center" width="15%">
                      {<IntlMessages id="pages.chathistory.table.link" />}
                    </TableCell>
                    <TableCell align="center" width="10%">
                      {<IntlMessages id="pages.chathistory.table.isunsent" />}
                    </TableCell>
                    <TableCell align="center" width="10%">
                      {<IntlMessages id="pages.chathistory.table.isdeleted" />}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {chatHistoryItems.map((c) => {
                    return (
                      <TableRow key={c.id}>
                        <TableCell align="left">{c.senderName}</TableCell>
                        <TableCell align="left">
                          {c.senderId === this.state.userid ? c.message : ""}
                        </TableCell>
                        <TableCell align="left">
                          {c.senderId === this.state.dealercode ? c.message : ""}
                        </TableCell>
                        <TableCell align="center">
                          {this.dateToString(c.date)}
                        </TableCell>
                        <TableCell align="center">
                          {/* {this.displayLink(c)} */}
                          {/* <a href="https://firebasestorage.googleapis.com/v0/b/hello-suzuki.appspot.com/o/PictureMessagesThailand%2F100002%2FBroadcast%2F20200205131043.jpg?alt=media&token=26025a77-60a6-4d70-a4d4-8855d9b6b79a">
                            link text
                          </a> */}
                          {this.displayLink(c).length > 0 ? (
                            <a href={this.displayLink(c)} target="_blank">
                              {this.displayLinkText(c)}
                            </a>
                          ) : (
                            ""
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {this.isUnsent(c.isUnsent, c.senderId)}
                        </TableCell>
                        <TableCell align="center">
                          {this.isDeleted(c.isDeleted)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </CardBox>
        </div>
      );
    }
  };

  render() {
    let role = localStorage.getItem("role");
    if ((role !== 'smt') && (role !== 'superAdmin') && (role.length !== 6)) {
      return <Redirect to={'/app/customers/request-delete'} />;
    }

    const {
      loaderChatHistoryByChatroomid,
      chatHistoryItems,
      locale,
    } = this.props;
    return (
      <div className="app-wrapper">
        <ContainerHeader
          match={this.props.match}
          title={<IntlMessages id="pages.chatuserhistory" />}
        />
        <div className="module-box-topbar">
          <IconButton className="icon-btn" onClick={this.backPressed}>
            <i className="zmdi zmdi-arrow-back" />
          </IconButton>
        </div>
        {loaderChatHistoryByChatroomid ? (
          <div className="loader-view">
            <CircularProgress />
          </div>
        ) : (
          //this.showUsers(this.props.usersByDealerItems)
          //this.finishLoading(this.props.usersByDealerItems)
          this.showChatHistory(this.props.chatHistoryItems)
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ chats, settings }) => {
  const { loaderChatHistoryByChatroomid, chatHistoryItems } = chats;
  //const { locale } = settings;
  return {
    loaderChatHistoryByChatroomid,
    chatHistoryItems,
    locale: settings.locale,
  };
};

export default connect(mapStateToProps, {
  showLoaderChatHistoryByChatroomid,
  getChatHistoryByChatroomidRequest,
})(ChatUserHistory);
