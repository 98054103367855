import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';

const ChatHistory = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/dealers`} />
      <Route
        path={`${match.url}/dealers`}
        component={asyncComponent(() => import('./routes/Dealers'))}
      />
      {/* <Route
        path={`${match.url}/users-by-dealer`}
        component={asyncComponent(() =>
          import('./routes/Dealers/routes/ChatUsersByDealer')
        )}
      /> */}
      {/* <Route
        path={`${match.url}/chat-user-history:userId`}
        component={asyncComponent(() =>
          import(
            './routes/Dealers/routes/ChatUsersByDealer/routes/ChatUserHistory'
          )
        )}
      /> */}
      {/* <Route
        path={`${match.url}/friendsdealers`}
        component={asyncComponent(() => import('./routes/FriendsDealers'))}
      /> */}
      {/* <Route
        path={`${match.url}/users-by-dealer`}
        component={asyncComponent(() => import('./routes/Dealers/routes/UsersByDealer'))}
      /> */}
      <Route
        component={asyncComponent(() =>
          import('app/routes/extraPages/routes/404')
        )}
      />
    </Switch>
  </div>
);

export default ChatHistory;
