import {
  GET_RECENTS_BY_DEALER_SUCCESS,
  GET_CHAT_HISTORY_BY_CHATROOMID_SUCCESS,
  ON_SHOW_LOADER_RECENTS_BY_DEALER,
  ON_HIDE_LOADER_RECENTS_BY_DEALER,
  ON_SHOW_LOADER_CHAT_HISTORY_BY_CHATROOMID,
  ON_HIDE_LOADER_CHAT_HISTORY_BY_CHATROOMID
} from 'constants/ActionTypes';

const INIT_STATE = {
  loaderRecentsByDealer: false,
  loaderChatHistoryByChatroomid: false,
  recentItems: [],
  chatHistoryItems: []
};
//dealers
export default function chats(state = INIT_STATE, action) {
  switch (action.type) {
    case GET_RECENTS_BY_DEALER_SUCCESS: {
      return {
        ...state,
        loaderRecentsByDealer: false,
        recentItems: action.payload
      };
    }
    case GET_CHAT_HISTORY_BY_CHATROOMID_SUCCESS: {
      return {
        ...state,
        loaderChatHistoryByChatroomid: false,
        chatHistoryItems: action.payload
      };
    }

    case ON_SHOW_LOADER_RECENTS_BY_DEALER: {
      return {
        ...state,
        loaderRecentsByDealer: true
      };
    }
    case ON_HIDE_LOADER_RECENTS_BY_DEALER: {
      return {
        ...state,
        loaderRecentsByDealer: false
      };
    }
    case ON_SHOW_LOADER_CHAT_HISTORY_BY_CHATROOMID: {
      return {
        ...state,
        loaderChatHistoryByChatroomid: true
      };
    }
    case ON_HIDE_LOADER_CHAT_HISTORY_BY_CHATROOMID: {
      return {
        ...state,
        loaderChatHistoryByChatroomid: false
      };
    }
    default:
      return state;
  }
}
