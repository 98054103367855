import {
  GET_RECENTS_BY_DEALER_REQUEST,
  GET_RECENTS_BY_DEALER_SUCCESS,
  GET_CHAT_HISTORY_BY_CHATROOMID_REQUEST,
  GET_CHAT_HISTORY_BY_CHATROOMID_SUCCESS,
  ON_SHOW_LOADER_RECENTS_BY_DEALER,
  ON_HIDE_LOADER_RECENTS_BY_DEALER,
  ON_SHOW_LOADER_CHAT_HISTORY_BY_CHATROOMID,
  ON_HIDE_LOADER_CHAT_HISTORY_BY_CHATROOMID
} from 'constants/ActionTypes';

export const getRecentsByDealerRequest = dealerCode => ({
  type: GET_RECENTS_BY_DEALER_REQUEST,
  payload: {
    dealerCode: dealerCode
  }
});
export const getRecentsByDealerSuccess = items => ({
  type: GET_RECENTS_BY_DEALER_SUCCESS,
  payload: items
});

export const getChatHistoryByChatroomidRequest = (dealercode, userid) => ({
  type: GET_CHAT_HISTORY_BY_CHATROOMID_REQUEST,
  payload: {
    dealercode: dealercode,
    userid: userid
  }
});
export const getChatHistoryByChatroomidSuccess = items => ({
  type: GET_CHAT_HISTORY_BY_CHATROOMID_SUCCESS,
  payload: items
});

export const showLoaderRecentsByDealer = () => {
  return {
    type: ON_SHOW_LOADER_RECENTS_BY_DEALER
  };
};
export const hideLoaderRecentsByDealer = () => {
  return {
    type: ON_HIDE_LOADER_RECENTS_BY_DEALER
  };
};
export const showLoaderChatHistoryByChatroomid = () => {
  return {
    type: ON_SHOW_LOADER_CHAT_HISTORY_BY_CHATROOMID
  };
};
export const hideLoaderChatHistoryByChatroomid = () => {
  return {
    type: ON_HIDE_LOADER_CHAT_HISTORY_BY_CHATROOMID
  };
};
