import {
  GET_DEALERS_REQUEST,
  GET_DEALERS_SUCCESS,
  GET_USERS_DEFAULT_DEALERS_REQUEST,
  GET_USERS_DEFAULT_DEALERS_SUCCESS,
  CREATE_DEALER_ACCOUNT_REQUEST,
  CREATE_DEALER_ACCOUNT_SUCCESS,
  ADD_DEALER_DATA_REQUEST,
  ADD_DEALER_DATA_SUCCESS,
  ON_SHOW_LOADER,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER_CREATE_DEALER_ACCOUNT,
  ON_HIDE_LOADER_CREATE_DEALER_ACCOUNT,
  ON_SHOW_LOADER_ADD_DEALER_DATA,
  ON_HIDE_LOADER_ADD_DEALER_DATA,
  ON_SHOW_LOADER_USERS_DEFAULT,
  ON_HIDE_LOADER_USERS_DEFAULT,
} from 'constants/ActionTypes';

export const getDealersRequest = () => ({
  type: GET_DEALERS_REQUEST
});
export const getDealersSuccess = items => ({
  type: GET_DEALERS_SUCCESS,
  payload: items
});

export const getUsersDefaultDealersRequest = () => ({
  type: GET_USERS_DEFAULT_DEALERS_REQUEST
});
export const getUsersDefaultSuccess = items => ({
  type: GET_USERS_DEFAULT_DEALERS_SUCCESS,
  payload: items
});

export const createDealerAccountRequest = (email, password) => ({
  type: CREATE_DEALER_ACCOUNT_REQUEST,
  payload: {
    email: email,
    password: password
  }
});
export const createDealerAccountSuccess = objectid => ({
  type: CREATE_DEALER_ACCOUNT_SUCCESS,
  payload: objectid
});

export const addDealerDataRequest = dealer => ({
  type: ADD_DEALER_DATA_REQUEST,
  payload: dealer
});
export const addDealerDataSuccess = items => ({
  type: ADD_DEALER_DATA_SUCCESS,
  payload: items
});

export const showLoader = () => {
  return {
    type: ON_SHOW_LOADER
  };
};
export const hideLoader = () => {
  return {
    type: ON_HIDE_LOADER
  };
};
export const showLoaderCreateDealerAccount = () => {
  return {
    type: ON_SHOW_LOADER_CREATE_DEALER_ACCOUNT
  };
};
export const hideLoaderCreateDealerAccount = () => {
  return {
    type: ON_HIDE_LOADER_CREATE_DEALER_ACCOUNT
  };
};
export const showLoaderAddDealerData = () => {
  return {
    type: ON_SHOW_LOADER_ADD_DEALER_DATA
  };
};
export const hideLoaderAddDealerData = () => {
  return {
    type: ON_HIDE_LOADER_ADD_DEALER_DATA
  };
};
export const showLoaderUsersDefault = () => {
  return {
    type: ON_SHOW_LOADER_USERS_DEFAULT
  };
};
export const hideLoaderUsersDefault = () => {
  return {
    type: ON_HIDE_LOADER_USERS_DEFAULT
  };
};
