import {
    GET_USER_SECOND_HAND_CAR_SUCCESS,
    ON_SHOW_LOADER_GET_USER_SECOND_HAND_CAR,
    ON_HIDE_LOADER_GET_USER_SECOND_HAND_CAR,
    SECOND_HAND_CAR_SUCCESS,
    ON_SHOW_LOADER_SECOND_HAND_CAR,
    ON_HIDE_LOADER_SECOND_HAND_CAR,
    SHOW_MESSAGE_SECOND_HAND_CAR,
    HIDE_MESSAGE_SECOND_HAND_CAR,
    RESET_SECOND_HAND_CAR
} from 'constants/ActionTypes';

const INIT_STATE = {
    loaderGetUserSecondHandCar: false,
    loaderSecondHandCar: false,
    userSecondHandCarItem: null,
    isMessageSecondHandCar: false,
    secondHandCarCode: ''    
  };

  export default function secondHandCar(state = INIT_STATE, action) {
    switch (action.type) {
      case GET_USER_SECOND_HAND_CAR_SUCCESS: {
        return {
          ...state,
          loaderGetUserSecondHandCar: false,
          userSecondHandCarItem: action.payload
        };
      }
      case ON_SHOW_LOADER_GET_USER_SECOND_HAND_CAR: {
        return {
          ...state,
          loaderGetUserSecondHandCar: true
        };
      }
      case ON_HIDE_LOADER_GET_USER_SECOND_HAND_CAR: {
        return {
          ...state,
          loaderGetUserSecondHandCar: false
        };
      } 
      case SECOND_HAND_CAR_SUCCESS: {
        return {
          ...state,
          loaderGetUserSecondHandCar: false,
          loaderSecondHandCar: false,
          userSecondHandCarItem: null,
          isMessageSecondHandCar: false,
          secondHandCarCode: action.payload 
        };
      }
      case ON_SHOW_LOADER_SECOND_HAND_CAR: {
        return {
          ...state,
          loaderSecondHandCar: true
        };
      }
      case ON_HIDE_LOADER_SECOND_HAND_CAR: {
        return {
          ...state,
          loaderSecondHandCar: false
        };
      }
      case SHOW_MESSAGE_SECOND_HAND_CAR: {
        return {
          ...state,
          isMessageSecondHandCar: true,
          //changePhoneCode: action.payload
        }
      }
      case HIDE_MESSAGE_SECOND_HAND_CAR: {
        return {
          ...state,
          isMessageSecondHandCar: false,
          secondHandCarCode: ''
        }
      }
      case RESET_SECOND_HAND_CAR: {
        return {
          ...state,
          loaderGetUserSecondHandCar: false,
          loaderSecondHandCar: false,
          userSecondHandCarItem: null,
          isMessageSecondHandCar: false,
          secondHandCarCode: ''
        };
      }
      default:
        return state;
  }
}