import React from "react";
import ContainerHeader from "components/ContainerHeader/index";
import IntlMessages from "util/IntlMessages";
import { connect } from "react-redux";
import { showLoader, getDealersRequest } from "actions/Dealers";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CardBox from "components/CardBox";
import Button from "@material-ui/core/Button";
//import ReactExport from "react-data-export";
import XLSX from "@sheet/chart"; // Pro Basic + Pro Chart
import moment from "moment-timezone";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { TextField } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import {
  showLoaderGetDeleteUserData,
  showLoaderDeleteUserData,
  getDeleteUserDataRequest,
  createDeleteUserDataRequest,
  updateDeleteUserDataRequest,
} from "actions/DeleteUserData";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { Redirect } from 'react-router-dom';

class RequestDelete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reasonIndex: -1,
      reason: "",
      otherReason: "",
    };
    let userId = localStorage.getItem("user_id");
    this.props.showLoaderGetDeleteUserData();
    this.props.getDeleteUserDataRequest({ userId });
    //this.props.showLoader();
    //this.props.getDealersRequest();
  }

  getCurrentDateTime = () => {
    let dd = moment().tz("Asia/Bangkok").format("YYYYMMDD HH:mm:ss"); //LTS
    //console.log('time1:' + dd);
    let ee = dd.replace(/:/g, "");
    //console.log('time2:' + ee);
    ee = ee.replace(/ /g, "");
    let str = ee.trim();
    //console.log('final:' + str);
    return str;
  };

  handleReasonChange = (reasonIndex) => (event) => {
    event.preventDefault();

    this.setState({ reasonIndex: event.target.value });
  };

  displayOtherReason = () => {
    return (
      <TextField
        type="text"
        label={<IntlMessages id="pages.requestdelete.textfield.reason" />}
        onChange={(event) => this.setState({ otherReason: event.target.value })}
        fullWidth
        margin="normal"
        className="mt-0 mb-2"
        inputProps={{ maxLength: 170 }}
      />
    );
  };

  displayContent = (reasonArray, userData) => {
    //console.log("use:" + userData);
    if (userData === null) {
      return (
        <div className="d-flex justify-content-center">
          <CardBox styleName="col-7" cardStyle="p-5">
            <FormControl className="w-100 mb-2">
              <h4>
                {this.props.locale.locale === "th"
                  ? "กรุณาเลือกเหตุผลในการลบบัญชี"
                  : "Why do you want to delete account?"}
              </h4>

              <Select
                // options={_.uniqBy(reasonArray, (r) => (r.reasonIndex))}
                value={this.state.reasonIndex}
                onChange={this.handleReasonChange("reasonIndex")}
                input={<Input id="reasonIndex" />}
              >
                <MenuItem value="0">
                  <em></em>{" "}
                </MenuItem>
                {reasonArray.map((element) => (
                  <MenuItem
                    value={element.reasonIndex}
                    key={element.reasonIndex}
                  >
                    {this.props.locale.locale === "th"
                      ? element.reasonTh
                      : element.reasonEn}
                  </MenuItem>
                ))}
              </Select>
              {this.state.reasonIndex === 3 ? this.displayOtherReason() : null}
              {/* <TextField
                type="text"
                label={
                  <IntlMessages id="pages.requestdelete.textfield.reason" />
                }
                onChange={(event) =>
                  this.setState({ otherReason: event.target.value })
                }
                fullWidth
                margin="normal"
                className="mt-0 mb-2"
                inputProps={{ maxLength: 170 }}
              /> */}
              <section>
                <br /> <br />
                <Button
                  variant="contained"
                  onClick={(event) => {
                    if (event) event.preventDefault();

                    if (this.state.reasonIndex === 0) {
                      NotificationManager.warning(
                        <IntlMessages id="notification.warning" />,
                        <IntlMessages id="pages.requestdelete.message.reasonindexempty" />,
                        3000
                      );
                      return;
                    }

                    if (
                      this.state.reasonIndex === reasonArray.length &&
                      this.state.otherReason === ""
                    ) {
                      NotificationManager.warning(
                        <IntlMessages id="notification.warning" />,
                        <IntlMessages id="pages.requestdelete.message.otherreasonempty" />,
                        3000
                      );
                      return;
                    }
                    console.log(
                      "fdfdf:" +
                        this.state.reasonIndex +
                        " other:" +
                        this.state.otherReason
                    );

                    let userId = localStorage.getItem("user_id");
                    // let role = localStorage.getItem('role');
                    // let firstname = localStorage.getItem('shorth_name');
                    // let lastname = localStorage.getItem('name_en');

                    let reasonObj = reasonArray[this.state.reasonIndex - 1];
                    let reasonTh = reasonObj.reasonTh;
                    let reasonEn = reasonObj.reasonEn;
                    // let createData = {

                    // }

                    // console.log(
                    //   "reasonTh:" +
                    //     reasonTh +
                    //     " reasonEn:" +
                    //     reasonEn +
                    //     " otherReason:" +
                    //     this.state.otherReason
                    // );

                    this.props.showLoaderDeleteUserData();
                    this.props.createDeleteUserDataRequest(
                      userId,
                      reasonTh,
                      reasonEn,
                      this.state.otherReason
                    );
                  }}
                  color="primary"
                >
                  <IntlMessages id="pages.requestdelete.button.deleteaccount" />
                </Button>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </section>
            </FormControl>
          </CardBox>
        </div>
      );
    } else {
      return (
        <div className="d-flex justify-content-center">
          <CardBox styleName="col-7" cardStyle="p-5">
            <FormControl className="w-100 mb-2">
              <h4>
                {this.props.locale.locale === "th"
                  ? "คุณต้องการยกเลิกการแจ้งลบบัญชี?"
                  : "Do you want to cancel account deletion request?"}
              </h4>
              <section>
                <br />
                <Button
                  variant="contained"
                  onClick={(event) => {
                    if (event) event.preventDefault();

                    let userId = localStorage.getItem("user_id");
                    let updateData = {
                      userId: userId,
                      firstname: userData.firstname,
                      lastname: userData.lastname,
                      email: userData.email,
                      phone: userData.phone,
                      isHaveCar: userData.isHaveCar,
                      reasonTh: userData.reasonTh,
                      reasonEn: userData.reasonEn,
                      active: false,
                      otherReason: userData.otherReason,
                    };

                    this.props.showLoaderDeleteUserData();
                    this.props.updateDeleteUserDataRequest({ updateData });
                  }}
                  color="secondary"
                >
                  <IntlMessages id="pages.requestdelete.button.canceldeleteaccount" />
                </Button>
              </section>
            </FormControl>
          </CardBox>
        </div>
      );
    }
  };

  render() {
    let role = localStorage.getItem("role");
    if ((role === "smt") || (role === "superAdmin")|| (role.length === 6)){
      return <Redirect to={"/app/dashboard/register-users"} />;
    }

    const { reasonIndex, reason, otherReason } = this.state;
    const {
      loaderGetDeleteUserData,
      loaderDeleteUserData,
      userData,
      userId,
      locale,
    } = this.props;
    const reasonArray = [];
    let reason1 = {
      reasonIndex: 1,
      reasonTh: "ไม่ได้เป็นเจ้าของรถซูซูกิแล้ว",
      reasonEn: "No longer own Suzuki car",
    };
    reasonArray.push(reason1);
    let reason2 = {
      reasonIndex: 2,
      reasonTh: "มีแผนต้องการซื้อรถใหม่",
      reasonEn: "Plan to buy a new car",
    };
    reasonArray.push(reason2);
    let reason3 = {
      reasonIndex: 3,
      reasonTh: "อื่น ๆ",
      reasonEn: "Other",
    };
    reasonArray.push(reason3);

    return (
      <div className="app-wrapper">
        <ContainerHeader
          match={this.props.match}
          title={<IntlMessages id="pages.requestdelete" />}
        />
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            {/* { !loaderDeleteUserData ? console.log('userData:' + this.props.userData) : console.log('m')}
            { (this.props.userData !== null && (this.props.userData !== undefined)) ? this.displayCancel() : this.displayCreate(reasonArray)} */}

            {/* {console.log('loaderDeleteUserData:' + loaderDeleteUserData)} */}
            {loaderGetDeleteUserData ? (
              <div className="loader-view">
                <CircularProgress />
              </div>
            ) : (
              this.displayContent(reasonArray, this.props.userData)
            )}
            {/* { this.props.userData !== null ? this.displayContent(reasonArray, this.props.userData) : null } */}

            {loaderDeleteUserData && (
              <div className="loader-view">
                <CircularProgress />
              </div>
            )}

            <NotificationContainer />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ deleteUserData, settings }) => {
  const {
    loaderGetDeleteUserData,
    loaderDeleteUserData,
    userData,
    userId,
  } = deleteUserData;
  const { locale } = settings;
  return {
    loaderGetDeleteUserData,
    loaderDeleteUserData,
    userData,
    userId,
    locale: settings.locale,
  };
};

export default connect(mapStateToProps, {
  showLoaderGetDeleteUserData,
  showLoaderDeleteUserData,
  getDeleteUserDataRequest,
  createDeleteUserDataRequest,
  updateDeleteUserDataRequest,
})(RequestDelete);
