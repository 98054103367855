import {
  GET_REGISTER_USER_COUNT_SUCCESS,
  ON_SHOW_LOADER,
  ON_HIDE_LOADER
} from 'constants/ActionTypes';

//INIT_STATE
const INIT_STATE = {
  loader: false,
  alertMessage: '',
  showMessage: false,
  items: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_REGISTER_USER_COUNT_SUCCESS: {
      return {
        ...state,
        loader: false,
        items: action.payload
      };
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      };
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      };
    }
    // case SHOW_MESSAGE: {
    //   return {
    //     ...state,
    //     alertMessage: action.payload,
    //     showMessage: true,
    //     loader: false
    //   };
    // }
    // case HIDE_MESSAGE: {
    //   return {
    //     ...state,
    //     alertMessage: '',
    //     showMessage: false,
    //     loader: false
    //   };
    // }

    default:
      return state;
  }
};
