import React from "react";
import ContainerHeader from "components/ContainerHeader";
import IntlMessages from "util/IntlMessages";
import { connect } from "react-redux";
//import { showLoader, getDealersRequest } from 'actions/Dealers';
import {
  showLoaderFriendsByDealer,
  getFriendsByDealerRequest,
} from "actions/Users";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CardBox from "components/CardBox";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
//import ReactExport from "react-data-export";
import Moment from "react-moment";
import { Redirect } from "react-router-dom";

class FriendsByDealer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dealercode: this.props.match.params.dealercode,
    };
    // this.setState({
    //   dealercode: this.props.match.params.dealercode
    // });

    //console.log('CCCCCCC:' + this.state.dealercode);
    //console.log('DDDDDDD:' + this.props.match.params.dealercode);
    this.props.showLoaderFriendsByDealer();
    this.props.getFriendsByDealerRequest(this.props.match.params.dealercode);
  }

  backPressed = (e) => {
    e.preventDefault();

    this.props.history.goBack();
  };

  exportToExcel = (friendsByDealerItems) => {};

  showUsers = ({ friendsByDealerItems }) => {
    //const nodata = <div></div>;

    if (
      friendsByDealerItems !== undefined &&
      friendsByDealerItems != null &&
      friendsByDealerItems.length !== 0
    ) {
      //const dataSet = this.dataForExportToExcel(items);
      return (
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <div className="module-box-topbar">
              <IconButton className="icon-btn" onClick={this.backPressed}>
                <i className="zmdi zmdi-arrow-back" />
              </IconButton>
            </div>
            <div className="d-flex justify-content-center">
              <CardBox
                styleName="col-lg-6 col-sm-6"
                // heading={<IntlMessages id="picker.date.date" />}
              >
                <center>
                  <Button
                    variant="contained"
                    className="jr-btn bg-green text-white"
                    onClick={() => {
                      this.exportToExcel(friendsByDealerItems);
                      //this.createShapeToExcel();
                    }}
                  >
                    <i className="zmdi zmdi-assignment-returned" />
                    <span>
                      <IntlMessages id="pages.dealerlist.button.exporttoexcel" />
                    </span>
                  </Button>
                </center>
              </CardBox>
            </div>
          </div>
          <CardBox styleName="col-12" cardStyle="p-0">
            <div className="table-responsive-material">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      {<IntlMessages id="pages.dealerlist.table.dealercode" />}
                    </TableCell>
                    <TableCell align="center">
                      {
                        <IntlMessages id="pages.dealerlist.table.dealernameth" />
                      }
                    </TableCell>
                    <TableCell align="center">
                      {
                        <IntlMessages id="pages.dealerlist.table.dealernameen" />
                      }
                    </TableCell>
                    <TableCell align="center">
                      {<IntlMessages id="pages.dealerlist.friendscount" />}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {friendsByDealerItems.map((u) => {
                    return (
                      <TableRow key={u.id}>
                        <TableCell align="center">
                          <Avatar
                            alt={u.firendNameTh}
                            src={u.friendAvatar}
                            style={{
                              width: 32,
                              height: 32,
                              textDecoration: "none",
                            }}
                          />
                        </TableCell>
                        <TableCell>{u.friendNameTh}</TableCell>
                        <TableCell>{u.friendId}</TableCell>
                        <TableCell align="center">
                          {new Date(u.friendTimestamp.toDate()).toDateString()}
                          {/* <Moment date={u.friendTimestamp} /> */}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </CardBox>
        </div>
      );
    }
  };

  render() {
    let role = localStorage.getItem("role");
    if (role !== "smt" && role !== "superAdmin" && role.length !== 6) {
      return <Redirect to={"/app/customers/request-delete"} />;
    }

    const { loaderFriendsByDealer, friendsByDealerItems, locale } = this.props;
    return (
      <div className="app-wrapper">
        <ContainerHeader
          match={this.props.match}
          title={<IntlMessages id="pages.usersbydealer" />}
        />
        {loaderFriendsByDealer ? (
          <div className="loader-view">
            <CircularProgress />
          </div>
        ) : (
          this.showUsers(this.props)
        )}
      </div>
    );
  }
}
const mapStateToProps = ({ users, settings }) => {
  //console.log('BBBBBB:' + state.dealercode);
  const { loaderFriendsByDealer, friendsByDealerItems } = users;
  const { locale } = settings;
  return {
    loaderFriendsByDealer,
    friendsByDealerItems,
    locale: settings.locale,
  };
};

export default connect(mapStateToProps, {
  showLoaderFriendsByDealer,
  getFriendsByDealerRequest,
})(FriendsByDealer);
