import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';

const Privilege = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/reward-list`} />
      <Route
        path={`${match.url}/reward-list`}
        component={asyncComponent(() => import('./routes/RewardList'))}
      />
      <Route
        path={`${match.url}/redeem-history`}
        component={asyncComponent(() => import('./routes/RedeemHistory'))}
      />
      <Route
        path={`${match.url}/redeem-history-by-dealer`}
        component={asyncComponent(() =>
          import('./routes/RedeemHistoryByDealer')
        )}
      />
      <Route
        path={`${match.url}/create-reward`}
        component={asyncComponent(() => import('./routes/CreateReward'))}
      />
      <Route
        component={asyncComponent(() =>
          import('app/routes/extraPages/routes/404')
        )}
      />
    </Switch>
  </div>
);

export default Privilege;
