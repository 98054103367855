import {
    GET_CONTACT_US_REQUEST,
    GET_CONTACT_US_SUCCESS,
    ON_SHOW_LOADER_GET_CONTACT_US,
    ON_HIDE_LOADER_GET_CONTACT_US   
  } from 'constants/ActionTypes';

  export const getContactUsRequest = (startDate, endDate) => {
    return {
      type: GET_CONTACT_US_REQUEST,
      payload: {
        startDate: startDate,
        endDate: endDate
      }
    };
  };
  export const getContactUsSuccess = items => {
    return {
      type: GET_CONTACT_US_SUCCESS,
      payload: items
    };
  };
  export const showLoaderGetContactUs = () => {
    return {
      type: ON_SHOW_LOADER_GET_CONTACT_US
    };
  };
  export const hideLoaderGetContactUs = () => {
    return {
      type: ON_HIDE_LOADER_GET_CONTACT_US
    };
  };