import {
  GET_REGISTER_USER_COUNT_REQUEST,
  GET_REGISTER_USER_COUNT_SUCCESS,
  ON_SHOW_LOADER,
  ON_HIDE_LOADER
} from 'constants/ActionTypes';

export const getRegisterUserCountRequest = (startDate, endDate) => {
  return {
    type: GET_REGISTER_USER_COUNT_REQUEST,
    payload: {
      startDate: startDate,
      endDate: endDate
    }
  };
};
export const getRegisterUserCountSuccess = items => {
  return {
    type: GET_REGISTER_USER_COUNT_SUCCESS,
    payload: items
  };
};
export const showLoader = () => {
  return {
    type: ON_SHOW_LOADER
  };
};
export const hideLoader = () => {
  return {
    type: ON_HIDE_LOADER
  };
};

// export const showMessage = message => {
//   return {
//     type: SHOW_MESSAGE,
//     payload: message
//   };
// };

// // export const requestHelloWorld = () => ({ type: REQUEST_HELLO_WORLD });
// // export const receiveHelloWorld = text => ({ type: RECEIVE_HELLO_WORLD, text });

// export const setFirestore = useFirestore => ({
//   type: SET_FIRESTORE,
//   useFirestore
// });
