import {
  GET_USERS_BY_DATE_REQUEST,
  GET_USERS_BY_DATE_SUCCESS,
  GET_USERS_BY_DEALER_REQUEST,
  GET_USERS_BY_DEALER_SUCCESS,
  GET_USERS_BY_DEALER_BY_DATE_REQUEST,
  GET_USERS_BY_DEALER_BY_DATE_SUCCESS,
  GET_USERS_BY_DEFAULT_DEALER_BY_DATE_REQUEST,
  GET_USERS_BY_DEFAULT_DEALER_BY_DATE_SUCCESS,
  GET_FRIENDS_BY_DEALER_REQUEST,
  GET_FRIENDS_BY_DEALER_SUCCESS,
  ON_SHOW_LOADER_USERS_BY_DATE,
  ON_HIDE_LOADER_USERS_BY_DATE,
  ON_SHOW_LOADER_USERS_BY_DEALER,
  ON_HIDE_LOADER_USERS_BY_DEALER,
  ON_SHOW_LOADER_USERS_BY_DEALER_BY_DATE,
  ON_HIDE_LOADER_USERS_BY_DEALER_BY_DATE,
  ON_SHOW_LOADER_FRIENDS_BY_DEALER,
  ON_HIDE_LOADER_FRIENDS_BY_DEALER
} from 'constants/ActionTypes';

export const getUsersByDateRequest = (startDate, endDate) => ({
  type: GET_USERS_BY_DATE_REQUEST,
  payload: {
    startDate: startDate,
    endDate: endDate
  }
});
export const getUsersByDateSuccess = items => ({
  type: GET_USERS_BY_DATE_SUCCESS,
  payload: items
});

export const getUsersByDealerRequest = dealerCode => ({
  type: GET_USERS_BY_DEALER_REQUEST,
  payload: {
    dealerCode: dealerCode
  }
});
export const getUsersByDealerSuccess = items => ({
  type: GET_USERS_BY_DEALER_SUCCESS,
  payload: items
});

export const getUsersByDealerByDateRequest = (
  dealerCode,
  startDate,
  endDate
) => ({
  type: GET_USERS_BY_DEALER_BY_DATE_REQUEST,
  payload: {
    dealerCode: dealerCode,
    startDate: startDate,
    endDate: endDate
  }
});
export const getUsersByDealerByDateSuccess = items => ({
  type: GET_USERS_BY_DEALER_BY_DATE_SUCCESS,
  payload: items
});

export const getUsersByDefaultDealerByDateRequest = (
  dealerCode,
  startDate,
  endDate
) => ({
  type: GET_USERS_BY_DEFAULT_DEALER_BY_DATE_REQUEST,
  payload: {
    dealerCode: dealerCode,
    startDate: startDate,
    endDate: endDate
  }
});
export const getUsersByDefaultDealerByDateSuccess = items => ({
  type: GET_USERS_BY_DEFAULT_DEALER_BY_DATE_SUCCESS,
  payload: items
});

export const getFriendsByDealerRequest = dealerCode => ({
  type: GET_FRIENDS_BY_DEALER_REQUEST,
  payload: {
    dealerCode: dealerCode
  }
});
export const getFriendsByDealerSuccess = items => ({
  type: GET_FRIENDS_BY_DEALER_SUCCESS,
  payload: items
});

export const showLoaderUsersByDate = () => {
  return {
    type: ON_SHOW_LOADER_USERS_BY_DATE
  };
};
export const hideLoaderUsersByDate = () => {
  return {
    type: ON_HIDE_LOADER_USERS_BY_DATE
  };
};

export const showLoaderUsersByDealer = () => {
  return {
    type: ON_SHOW_LOADER_USERS_BY_DEALER
  };
};
export const hideLoaderUsersByDealer = () => {
  return {
    type: ON_HIDE_LOADER_USERS_BY_DEALER
  };
};

export const showLoaderUsersByDealerByDate = () => {
  return {
    type: ON_SHOW_LOADER_USERS_BY_DEALER_BY_DATE
  };
};
export const hideLoaderUsersByDealerByDate = () => {
  return {
    type: ON_HIDE_LOADER_USERS_BY_DEALER_BY_DATE
  };
};

export const showLoaderFriendsByDealer = () => {
  return {
    type: ON_SHOW_LOADER_FRIENDS_BY_DEALER
  };
};
export const hideLoaderFriendsByDealer = () => {
  return {
    type: ON_HIDE_LOADER_FRIENDS_BY_DEALER
  };
};
