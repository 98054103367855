import {
  GET_DEALERS_SUCCESS,
  CREATE_DEALER_ACCOUNT_SUCCESS,
  ADD_DEALER_DATA_SUCCESS,
  GET_USERS_DEFAULT_DEALERS_SUCCESS,
  ON_SHOW_LOADER,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER_CREATE_DEALER_ACCOUNT,
  ON_HIDE_LOADER_CREATE_DEALER_ACCOUNT,
  ON_SHOW_LOADER_ADD_DEALER_DATA,
  ON_HIDE_LOADER_ADD_DEALER_DATA,
  ON_SHOW_LOADER_USERS_DEFAULT,
  ON_HIDE_LOADER_USERS_DEFAULT,
} from 'constants/ActionTypes';

const INIT_STATE = {
  loader: false,
  loaderCreateDealerAccount: false,
  loaderAddDealerData: false,
  items: [],
  objectid: '',
  loaderUsersDefault: false,
  dataItems: [],
};

export default function dealers(state = INIT_STATE, action) {
  switch (action.type) {
    case GET_DEALERS_SUCCESS: {
      return {
        ...state,
        loader: false,
        items: action.payload
      };
    }
    case GET_USERS_DEFAULT_DEALERS_SUCCESS: {
      return {
        ...state,
        loaderUsersDefault: false,
        dataItems: action.payload
      };
    }
    case CREATE_DEALER_ACCOUNT_SUCCESS: {
      return {
        ...state,
        loaderCreateDealerAccount: false,
        objectid: action.payload
      };
    }
    case ADD_DEALER_DATA_SUCCESS: {
      return {
        ...state,
        loaderAddDealerData: false
        //items: action.payload
      };
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      };
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      };
    }
    case ON_SHOW_LOADER_CREATE_DEALER_ACCOUNT: {
      return {
        ...state,
        loaderCreateDealerAccount: true
      };
    }
    case ON_HIDE_LOADER_CREATE_DEALER_ACCOUNT: {
      return {
        ...state,
        loaderCreateDealerAccount: false
      };
    }
    case ON_SHOW_LOADER_ADD_DEALER_DATA: {
      return {
        ...state,
        loaderAddDealerData: true
      };
    }
    case ON_HIDE_LOADER_ADD_DEALER_DATA: {
      return {
        ...state,
        loaderAddDealerData: false
      };
    }
    case ON_SHOW_LOADER_USERS_DEFAULT: {
      return {
        ...state,
        loaderUsersDefault: true
      };
    }
    case ON_HIDE_LOADER_USERS_DEFAULT: {
      return {
        ...state,
        loaderUsersDefault: false
      };
    }
    default:
      return state;
  }
}
