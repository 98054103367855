import { applyMiddleware, compose, createStore } from 'redux';
import reducers from '../reducers/index';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas/index';
//me use
//import { createStoreWithFirebase } from '../firebase/firebase';
//end use
const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, routeMiddleware];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(initialState) {
  //For Production
  const store = createStore(
    reducers(history),
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  //me use
  // const store = createStoreWithFirebase(
  //   reducers(history),
  //   initialState,
  //   composeEnhancers(applyMiddleware(...middlewares))
  // );
  //end use

  //For Debug
  // const composeEnhancers =
  //   window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  // // const composeEnhancers =
  // //   typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  // //     ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
  // //         // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
  // //       })
  // //     : compose;

  // const enhancer = composeEnhancers(
  //   applyMiddleware(...middlewares)
  //   // other store enhancers if any
  // );

  // const store = createStore(reducers(history), initialState, enhancer);

  sagaMiddleware.run(rootSaga);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers/index', () => {
      const nextRootReducer = require('../reducers/index');
      store.replaceReducer(nextRootReducer);
    });
  }
  return store;
}
export { history };
