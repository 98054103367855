import {
    GET_ACTIVE_USERS_ALL_SUCCESS,
    ON_SHOW_LOADER_GET_ACTIVE_USERS_ALL,
    ON_HIDE_LOADER_GET_ACTIVE_USERS_ALL,
    GET_USERS_BY_DEFAULT_DEALER_SUCCESS,
    ON_SHOW_LOADER_GET_USERS_BY_DEFAULT_DEALER,
    ON_HIDE_LOADER_GET_USERS_BY_DEFAULT_DEALER,
    UPDATE_ACTIVE_USERS_SUCCESS,
    // GET_ACTIVE_USERS_BY_DATE_SUCCESS,
    // GET_INACTIVE_USERS_BY_DATE_SUCCESS,   
    // ON_SHOW_LOADER_GET_ACTIVE_USERS,
    // ON_SHOW_LOADER_GET_INACTIVE_USERS,
    // ON_HIDE_LOADER_GET_ACTIVE_USERS,
    // ON_HIDE_LOADER_GET_INACTIVE_USERS,   
  } from 'constants/ActionTypes';

  const INIT_STATE = {
    dataItems: [],
    loaderGetActiveUsersAll: false,
    userItems: [],
    loaderGetUsersByDefaultDealer: false,    
    // loaderGetActiveUsers: false,
    // loaderGetInactiveUsers: false,
    // activeUserItems: [],
    // inactiveUserItems: [],
  };

  export default function user(state = INIT_STATE, action) {
    switch (action.type) {
      case GET_ACTIVE_USERS_ALL_SUCCESS: {
        return {
          ...state,
          loaderGetActiveUsersAll: false,
          dataItems: action.payload
        };
      }
      case ON_SHOW_LOADER_GET_ACTIVE_USERS_ALL: {
        return {
          ...state,
          loaderGetActiveUsersAll: true
        };
      }
      case ON_HIDE_LOADER_GET_ACTIVE_USERS_ALL: {
        return {
          ...state,
          loaderGetActiveUsersAll: false
        };
      }
      case GET_USERS_BY_DEFAULT_DEALER_SUCCESS: {
        return {
          ...state,
          loaderGetUsersByDefaultDealer: false,
          userItems: action.payload
        };
      }
      case ON_SHOW_LOADER_GET_USERS_BY_DEFAULT_DEALER: {
        return {
          ...state,
          loaderGetUsersByDefaultDealer: true
        };
      }
      case ON_HIDE_LOADER_GET_USERS_BY_DEFAULT_DEALER: {
        return {
          ...state,
          loaderGetUsersByDefaultDealer: false
        };
      }
      case UPDATE_ACTIVE_USERS_SUCCESS: {
        return {
          ...state,
          //items: action.payload
        };
      }
      // case GET_ACTIVE_USERS_BY_DATE_SUCCESS: {
      //   return {
      //     ...state,
      //     loaderGetActiveUsers: false,
      //     activeUserItems: action.payload
      //   };
      // }
      // case GET_INACTIVE_USERS_BY_DATE_SUCCESS: {
      //   return {
      //     ...state,
      //     loaderGetActiveUsers: false,
      //     inactiveUserItems: action.payload
      //   };
      // }
      // case ON_SHOW_LOADER_GET_ACTIVE_USERS: {
      //   return {
      //     ...state,
      //     loaderGetActiveUsers: true
      //   };
      // }
      // case ON_SHOW_LOADER_GET_INACTIVE_USERS: {
      //   return {
      //     ...state,
      //     loaderGetInactiveUsers: true
      //   };
      // }
      // case ON_HIDE_LOADER_GET_ACTIVE_USERS: {
      //   return {
      //     ...state,
      //     loaderGetActiveUsers: false
      //   };
      // }
      // case ON_HIDE_LOADER_GET_INACTIVE_USERS: {
      //   return {
      //     ...state,
      //     loaderGetInactiveUsers: false
      //   };
      // }
      default:
      return state;
  }
}