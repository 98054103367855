import {
  GET_REWARDS_SUCCESS,
  GET_REWARD_BY_ID_SUCCESS,
  CREATE_REWARD_SUCCESS,
  UPDATE_REWARD_SUCCESS,
  DELETE_REWARD_SUCCESS,
  CLEAR_REWARD_ITEM,
  ON_SHOW_LOADER_GET_REWARDS,
  ON_HIDE_LOADER_GET_REWARDS,
  ON_SHOW_LOADER_GET_REWARD_BY_ID,
  ON_HIDE_LOADER_GET_REWARD_BY_ID,
  ON_SHOW_LOADER_CREATE_REWARD,
  ON_HIDE_LOADER_CREATE_REWARD,
  ON_SHOW_LOADER_UPDATE_REWARD,
  ON_HIDE_LOADER_UPDATE_REWARD,
  ON_SHOW_LOADER_DELETE_REWARD,
  ON_HIDE_LOADER_DELETE_REWARD,
  SHOW_MESSAGE_REWARD,
  HIDE_MESSAGE_REWARD,
  ON_SHOW_REFRESH_REWARD,
  ON_HIDE_REFRESH_REWARD
} from 'constants/ActionTypes';

const INIT_STATE = {
  loaderGetRewards: false,
  loaderGetRewardById: false,
  loaderCreateReward: false,
  loaderUpdateReward: false,
  loaderDeleteReward: false,
  isMessageReward: false,
  isRefresh: false,
  alertMessage: '',
  items: [],
  item: null,
  rewardid: ''
};

export default function reward(state = INIT_STATE, action) {
  switch (action.type) {
    case GET_REWARDS_SUCCESS: {
      return {
        ...state,
        loaderGetRewards: false,
        items: action.payload
      };
    }
    case GET_REWARD_BY_ID_SUCCESS: {
      return {
        ...state,
        loaderGetRewardById: false,
        item: action.payload
      };
    }
    case CREATE_REWARD_SUCCESS: {
      return {
        ...state,
        loaderCreateReward: false,
        rewardid: action.payload,
        isRefresh: true
      };
    }
    case UPDATE_REWARD_SUCCESS: {
      return {
        ...state,
        loaderUpdateReward: false
        //items: action.payload
      };
    }
    case DELETE_REWARD_SUCCESS: {
      return {
        ...state,
        loaderDeleteReward: false,
        isRefresh: true
        //items: action.payload
      };
    }
    case CLEAR_REWARD_ITEM: {
      return {
        ...state,
        item: null
      };
    }
    case ON_SHOW_LOADER_GET_REWARDS: {
      return {
        ...state,
        loaderGetRewards: true
      };
    }
    case ON_HIDE_LOADER_GET_REWARDS: {
      return {
        ...state,
        loaderGetRewards: false
      };
    }
    case ON_SHOW_LOADER_GET_REWARD_BY_ID: {
      return {
        ...state,
        loaderGetRewardById: true
      };
    }
    case ON_HIDE_LOADER_GET_REWARD_BY_ID: {
      return {
        ...state,
        loaderGetRewardById: false
      };
    }
    case ON_SHOW_LOADER_CREATE_REWARD: {
      return {
        ...state,
        loaderCreateReward: true
      };
    }
    case ON_HIDE_LOADER_CREATE_REWARD: {
      return {
        ...state,
        loaderCreateReward: false,
        isRefresh: true
      };
    }
    case ON_SHOW_LOADER_UPDATE_REWARD: {
      return {
        ...state,
        loaderUpdateReward: true
      };
    }
    case ON_HIDE_LOADER_UPDATE_REWARD: {
      return {
        ...state,
        loaderUpdateReward: false
      };
    }
    case ON_SHOW_LOADER_DELETE_REWARD: {
      return {
        ...state,
        loaderDeleteReward: true
      };
    }
    case ON_HIDE_LOADER_DELETE_REWARD: {
      return {
        ...state,
        loaderDeleteReward: false
        //isRefresh: true
      };
    }
    case SHOW_MESSAGE_REWARD: {
      return {
        ...state,
        alertMessage: action.payload,
        isMessageReward: true,
        loader: false,
        isRefresh: true
      };
    }
    case HIDE_MESSAGE_REWARD: {
      return {
        ...state,
        alertMessage: '',
        isMessageReward: false,
        loader: false,
        isRefresh: false
      };
    }
    case ON_SHOW_REFRESH_REWARD: {
      return {
        ...state,
        isRefresh: true
      };
    }
    case ON_HIDE_REFRESH_REWARD: {
      return {
        ...state,
        isRefresh: false
      };
    }
    default:
      return state;
  }
}
