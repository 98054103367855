import React from "react";
import ContainerHeader from "components/ContainerHeader";
import IntlMessages from "util/IntlMessages";
import { connect } from "react-redux";
//import { showLoader, getDealersRequest } from 'actions/Dealers';
import {
  showLoaderUsersByDealer,
  getUsersByDealerRequest,
  showLoaderUsersByDealerByDate,
  getUsersByDefaultDealerByDateRequest,
} from "actions/Users";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CardBox from "components/CardBox";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
//import ReactExport from 'react-data-export';
//import Moment from 'react-moment';
import XLSX from "@sheet/chart"; // Pro Basic + Pro Chart
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import { format } from "date-fns";
//import Input from '@material-ui/core/Input';
import { Input } from "@material-ui/core";
import moment from "moment-timezone";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { Redirect } from "react-router-dom";

class UsersByDefaultDealer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dealercode: this.props.match.params.dealercode,
      //startDate: new Date(moment('20200117').tz('Asia/Bangkok')),
      startDate: new Date(
        moment().tz("Asia/Bangkok").startOf("month").format("YYYY-MM-DD")
      ),
      endDate: new Date(moment().tz("Asia/Bangkok")),
      //startDateStr: '20200117',
      startDateStr: moment()
        .tz("Asia/Bangkok")
        .startOf("month")
        .format("YYYYMMDD"),
      endDateStr: moment().tz("Asia/Bangkok").format("YYYYMMDD"),
    };
    // this.setState({
    //   dealercode: this.props.match.params.dealercode
    // });

    //console.log('CCCCCCC:' + this.state.dealercode);
    //console.log('DDDDDDD:' + this.props.match.params.dealercode);
    //this.props.showLoaderUsersByDealer();
    //this.props.getUsersByDealerRequest(this.props.match.params.dealercode);

    this.props.showLoaderUsersByDealerByDate();
    this.props.getUsersByDefaultDealerByDateRequest(
      this.props.match.params.dealercode,
      this.state.startDateStr,
      this.state.endDateStr
    );

    this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
    this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
    this.handleGetData = this.handleGetData.bind(this);
    this.dateToString = this.dateToString.bind(this);
  }

  backPressed = (e) => {
    e.preventDefault();

    this.props.history.goBack();
  };

  handleChangeStartDate(date) {
    this.setState({
      startDate: date,
      startDateStr: format(date, "yyyyMMdd"),
      //startDateStr: format(date, 'yyyyMMdd') + '000000'
    });
  }

  handleChangeEndDate(date) {
    this.setState({
      endDate: date,
      endDateStr: format(date, "yyyyMMdd"),
      //endDateStr: format(date, 'yyyyMMdd') + '235959'
    });
  }

  handleGetData() {
    //this.props.locale.locale === 'en' ? 'Suzuki' : 'ซูซูกิ'
    if (this.state.endDateStr < this.state.startDateStr) {
      //console.log('Alert !!!!!');
      NotificationManager.warning(
        <IntlMessages id="notification.warning" />,
        <IntlMessages id="notification.warning.startdatemorethanenddate" />,
        3000
      );
    } else {
      this.props.showLoaderUsersByDealerByDate();
      this.props.getUsersByDefaultDealerByDateRequest(
        this.props.match.params.dealercode,
        this.state.startDateStr,
        this.state.endDateStr
      );
    }
  }

  dateToString = (dateData) => {
    let year = dateData.substring(0, 4);
    let month = dateData.substring(4, 6);
    let day = dateData.substring(6, 8);
    let hr = dateData.substring(8, 10);
    let mi = dateData.substring(10, 12);
    let sec = dateData.substring(12, 14);
    return day + "/" + month + "/" + year + " " + hr + ":" + mi + ":" + sec;
  };

  //exportToExcel = usersByDealerByDateItems => {};
  exportToExcel = (items) => {
    // var workbook = { SheetNames: [], Sheets: {} };
    // const wsname0 = workbook.SheetNames[0];
    // console.log('wsname:' + wsname0);
    // var ws0 = workbook.Sheets[wsname0];
    //console.log('itemCount:' + items.length);

    const fontName = "Calibri";
    const fontSize = "11";
    const borderColor = 0x808080;
    const csiBgColor = 0xe4efdc;
    const answerBgColor = 0xdfeaf5;
    const sumBgColor = 0xdadada;
    const totalBgColor = 0x595959;
    const whiteBgColor = 0xffffff;
    const textMainColor = 0x3f3f3f;
    const textWhiteColor = 0xffffff;
    const takumiBgColor = 0x003145;

    const textColor = "#7f7f7f";

    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.aoa_to_sheet([[]]);
    var wsname = "User List";
    XLSX.utils.book_append_sheet(wb, ws, wsname);

    //var ws_data = ['Dealer Code', 'Dealer Name Thai', 'Dealer Name English', 'Customers Count'];
    //var ws = XLSX.utils.aoa_to_sheet(ws_data);
    //var ws = workbook.Sheets[wsname];

    /* Set worksheet sheet to "normal" */
    ws["!margins"] = {
      left: 0.7,
      right: 0.7,
      top: 0.75,
      bottom: 0.75,
      header: 0.3,
      footer: 0.3,
    };

    var wscols = [
      { width: 40 },
      { width: 40 },
      { width: 30 },
      { width: 15 },
      { width: 20 },
      { width: 20 },
    ];
    ws["!cols"] = wscols;
    //ws['!rows']

    ws["!gridlines"] = false;

    ws["!freeze"] = "A2"; // Freeze first row               bottom pane starts at A2
    //ws["!freeze"] = "B1"; // Freeze first column             right pane starts at B1
    //ws["!freeze"] = "B2"; // Freeze row and column    bottom-right pane starts at B2

    // ws['!freeze'] = {
    //   xSplit: '1',
    //   ySplit: '1',
    //   topLeftCell: 'B2',
    //   activePane: 'bottomRight',
    //   state: 'frozen'
    // };

    var row = 1;

    var dataArray = ["", "", "", "", "", ""];
    XLSX.utils.sheet_add_aoa(ws, [dataArray], { origin: "A" + row });

    //Header
    ws["A" + 1] = {
      t: "s",
      v: this.props.locale.locale === "en" ? "First Name" : "ชื่อ",
      s: {
        top: { style: "thin", color: { rgb: borderColor } },
        bottom: { style: "thin", color: { rgb: borderColor } },
        left: { style: "thin", color: { rgb: borderColor } },
        right: { style: "thin", color: { rgb: borderColor } },
        fgColor: { rgb: takumiBgColor },
        name: fontName,
        sz: fontSize,
        bold: true,
        color: { rgb: textWhiteColor },
        alignment: {
          horizontal: "center",
          vertical: "center",
          indent: 0,
          wrapText: true,
        },
      },
    };
    ws["B" + 1] = {
      t: "s",
      v: this.props.locale.locale === "en" ? "Last Name" : "นามสกุล",
      s: {
        top: { style: "thin", color: { rgb: borderColor } },
        bottom: { style: "thin", color: { rgb: borderColor } },
        left: { style: "thin", color: { rgb: borderColor } },
        right: { style: "thin", color: { rgb: borderColor } },
        fgColor: { rgb: takumiBgColor },
        name: fontName,
        sz: fontSize,
        bold: true,
        color: { rgb: textWhiteColor },
        alignment: {
          horizontal: "center",
          vertical: "center",
          indent: 0,
          wrapText: true,
        },
      },
    };
    ws["C" + 1] = {
      t: "s",
      v: this.props.locale.locale === "en" ? "Email" : "อีเมล",
      s: {
        top: { style: "thin", color: { rgb: borderColor } },
        bottom: { style: "thin", color: { rgb: borderColor } },
        left: { style: "thin", color: { rgb: borderColor } },
        right: { style: "thin", color: { rgb: borderColor } },
        fgColor: { rgb: takumiBgColor },
        name: fontName,
        sz: fontSize,
        bold: true,
        color: { rgb: textWhiteColor },
        alignment: {
          horizontal: "center",
          vertical: "center",
          indent: 0,
          wrapText: true,
        },
      },
    };
    ws["D" + 1] = {
      t: "s",
      v: this.props.locale.locale === "en" ? "Phone" : "เบอร์โทรศัพท์",
      s: {
        top: { style: "thin", color: { rgb: borderColor } },
        bottom: { style: "thin", color: { rgb: borderColor } },
        left: { style: "thin", color: { rgb: borderColor } },
        right: { style: "thin", color: { rgb: borderColor } },
        fgColor: { rgb: takumiBgColor },
        name: fontName,
        sz: fontSize,
        bold: true,
        color: { rgb: textWhiteColor },
        alignment: {
          horizontal: "center",
          vertical: "center",
          indent: 0,
          wrapText: true,
        },
      },
    };
    ws["E" + 1] = {
      t: "s",
      v: this.props.locale.locale === "en" ? "Chassis Number" : "หมายเลขตัวถัง",
      s: {
        top: { style: "thin", color: { rgb: borderColor } },
        bottom: { style: "thin", color: { rgb: borderColor } },
        left: { style: "thin", color: { rgb: borderColor } },
        right: { style: "thin", color: { rgb: borderColor } },
        fgColor: { rgb: takumiBgColor },
        name: fontName,
        sz: fontSize,
        bold: true,
        color: { rgb: textWhiteColor },
        alignment: {
          horizontal: "center",
          vertical: "center",
          indent: 0,
          wrapText: true,
        },
      },
    };
    ws["F" + 1] = {
      t: "s",
      v: this.props.locale.locale === "en" ? "Register Date" : "วันที่ลงทะเบียน",
      s: {
        top: { style: "thin", color: { rgb: borderColor } },
        bottom: { style: "thin", color: { rgb: borderColor } },
        left: { style: "thin", color: { rgb: borderColor } },
        right: { style: "thin", color: { rgb: borderColor } },
        fgColor: { rgb: takumiBgColor },
        name: fontName,
        sz: fontSize,
        bold: true,
        color: { rgb: textWhiteColor },
        alignment: {
          horizontal: "center",
          vertical: "center",
          indent: 0,
          wrapText: true,
        },
      },
    };

    for (var i = 0; i < items.length; i++) {
      row++;
      var dataArray = ["", "", "", "", "", ""];
      XLSX.utils.sheet_add_aoa(ws, [dataArray], { origin: "A" + row });

      ws["A" + row] = {
        t: "s",
        v: items[i].firstname,
        s: {
          bottom: { style: "thin", color: { rgb: borderColor } },
          left: { style: "thin", color: { rgb: borderColor } },
          right: { style: "thin", color: { rgb: borderColor } },
          fgColor: { rgb: whiteBgColor },
          name: fontName,
          sz: fontSize,
          bold: false,
          color: { rgb: textMainColor },
          alignment: {
            horizontal: "left",
            vertical: "center",
            indent: 0,
            wrapText: true,
          },
        },
      };
      ws["B" + row] = {
        t: "s",
        v: items[i].lastname,
        s: {
          bottom: { style: "thin", color: { rgb: borderColor } },
          left: { style: "thin", color: { rgb: borderColor } },
          right: { style: "thin", color: { rgb: borderColor } },
          fgColor: { rgb: whiteBgColor },
          name: fontName,
          sz: fontSize,
          bold: false,
          color: { rgb: textMainColor },
          alignment: {
            horizontal: "left",
            vertical: "center",
            indent: 0,
            wrapText: true,
          },
        },
      };
      ws["C" + row] = {
        t: "s",
        v: items[i].email,
        s: {
          bottom: { style: "thin", color: { rgb: borderColor } },
          left: { style: "thin", color: { rgb: borderColor } },
          right: { style: "thin", color: { rgb: borderColor } },
          fgColor: { rgb: whiteBgColor },
          name: fontName,
          sz: fontSize,
          bold: false,
          color: { rgb: textMainColor },
          alignment: {
            horizontal: "left",
            vertical: "center",
            indent: 0,
            wrapText: true,
          },
        },
      };
      ws["D" + row] = {
        t: "s",
        v: "N/A", //items[i].phone,
        s: {
          bottom: { style: "thin", color: { rgb: borderColor } },
          left: { style: "thin", color: { rgb: borderColor } },
          right: { style: "thin", color: { rgb: borderColor } },
          fgColor: { rgb: whiteBgColor },
          name: fontName,
          sz: fontSize,
          bold: false,
          color: { rgb: textMainColor },
          alignment: {
            horizontal: "center",
            vertical: "center",
            indent: 0,
            wrapText: true,
          },
        },
      };
      ws["E" + row] = {
        t: "s",
        v: items[i].chassisNumber,
        s: {
          bottom: { style: "thin", color: { rgb: borderColor } },
          left: { style: "thin", color: { rgb: borderColor } },
          right: { style: "thin", color: { rgb: borderColor } },
          fgColor: { rgb: whiteBgColor },
          name: fontName,
          sz: fontSize,
          bold: false,
          color: { rgb: textMainColor },
          alignment: {
            horizontal: "center",
            vertical: "center",
            indent: 0,
            wrapText: true,
          },
        },
      };
      ws["F" + row] = {
        t: "s",
        v: this.dateToString(items[i].createdAt),
        s: {
          bottom: { style: "thin", color: { rgb: borderColor } },
          left: { style: "thin", color: { rgb: borderColor } },
          right: { style: "thin", color: { rgb: borderColor } },
          fgColor: { rgb: whiteBgColor },
          name: fontName,
          sz: fontSize,
          bold: false,
          color: { rgb: textMainColor },
          alignment: {
            horizontal: "center",
            vertical: "center",
            indent: 0,
            wrapText: true,
          },
        },
      };
    }

    var currentDate = moment().tz("Asia/Bangkok").format("YYYYMMDD");
    //### Save xlsx file
    // XLSX.writeFile(wb, 'UsersByDealer-' + currentDate + '.xlsx', {
    //   cellStyles: true,
    //   bookImages: true
    // });

    XLSX.writeFile(
      wb,
      "UsersByDealer-" +
        "-" +
        this.props.match.params.dealercode +
        "-" +
        this.state.startDateStr +
        "-" +
        this.state.endDateStr +
        ".xlsx",
      {
        cellStyles: true,
        bookImages: true,
      }
    );
  };

  showUsers = ({ usersByDealerByDateItems }) => {
    //const nodata = <div></div>;

    if (
      usersByDealerByDateItems !== undefined &&
      usersByDealerByDateItems != null &&
      usersByDealerByDateItems.length !== 0
    ) {
      //const dataSet = this.dataForExportToExcel(items);
      const heading =
        this.props.locale.locale === "en"
          ? "Total customers " + usersByDealerByDateItems.length
          : "จำนวนลูกค้า " + usersByDealerByDateItems.length + " คน";
      return (
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <div className="d-flex justify-content-center">
              <CardBox styleName="col-lg-6 col-sm-6" heading={heading}>
                <center>
                  <Button
                    variant="contained"
                    className="jr-btn bg-green text-white"
                    onClick={() => {
                      this.exportToExcel(usersByDealerByDateItems);
                      //this.createShapeToExcel();
                    }}
                  >
                    <i className="zmdi zmdi-assignment-returned" />
                    <span>
                      <IntlMessages id="pages.dealerlist.button.exporttoexcel" />
                    </span>
                  </Button>
                </center>
              </CardBox>
            </div>
          </div>
          <CardBox styleName="col-12" cardStyle="p-0">
            <div className="table-responsive-material">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      {<IntlMessages id="pages.userlist.table.firstname" />}
                    </TableCell>
                    <TableCell align="center">
                      {<IntlMessages id="pages.userlist.table.lastname" />}
                    </TableCell>
                    <TableCell align="center">
                      {<IntlMessages id="pages.userlist.table.email" />}
                    </TableCell>
                    <TableCell align="center">
                      {<IntlMessages id="pages.userlist.table.phone" />}
                    </TableCell>
                    <TableCell align="center">
                      {<IntlMessages id="pages.userlist.table.chassisnumber" />}
                    </TableCell>
                    <TableCell align="center">
                      {<IntlMessages id="pages.userlist.table.registerdate" />}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {usersByDealerByDateItems.map((u) => {
                    return (
                      <TableRow key={u.id}>
                        <TableCell align="left">{u.firstname}</TableCell>
                        <TableCell align="left">{u.lastname}</TableCell>
                        <TableCell align="left">{u.email}</TableCell>
                        <TableCell align="center">N/A</TableCell>
                        <TableCell align="center">{u.chassisNumber}</TableCell>
                        <TableCell align="center">
                          {this.dateToString(u.createdAt)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </CardBox>
        </div>
      );
    }
  };

  displayBackButton = () => {
    var role = localStorage.getItem("role");
    if (role === "superAdmin") {
      return (
        <div className="module-box-topbar">
          <IconButton className="icon-btn" onClick={this.backPressed}>
            <i className="zmdi zmdi-arrow-back" />
          </IconButton>
        </div>
      );
    } else if (role === "smt") {
      return (
        <div className="module-box-topbar">
          <IconButton className="icon-btn" onClick={this.backPressed}>
            <i className="zmdi zmdi-arrow-back" />
          </IconButton>
        </div>
      );
    } else {
      return null;
    }
  };

  render() {
    let role = localStorage.getItem("role");
    //console.log('roleB:' + role);
    if (role !== "smt" && role !== "superAdmin") {
      if (role.length === 6) {
        //return <Redirect to={"/app/dashboard/register-users"} />;
      } else {
        return <Redirect to={"/app/customers/request-delete"} />;
      }
    }    

    const {
      loaderUsersByDealer,
      usersByDealerItems,
      loaderUsersByDealerByDate,
      usersByDealerByDateItems,
      locale,
    } = this.props;
    return (
      <div className="app-wrapper">
        <ContainerHeader
          match={this.props.match}
          title={<IntlMessages id="pages.usersbydefaultdealer" />}
        />
        {this.displayBackButton()}
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
          <div className="d-flex justify-content-center">
            {/* <h1>
            <IntlMessages id="pages.samplePage.description" />
          </h1> */}
            <CardBox
              styleName="col-lg-6 col-sm-6"
              // heading={<IntlMessages id="picker.date.date" />}
            >
              {/* <DatePickers /> */}
              <div className="row">
                <div className="col-md-4">
                  <span className="text-primary">
                    {<IntlMessages id="pages.registeruser.startdate" />}{" "}
                  </span>
                  <DatePicker
                    customInput={<Input />}
                    selected={this.state.startDate}
                    onSelect={this.handleSelect}
                    onChange={this.handleChangeStartDate}
                    dateFormat="dd/MM/yyyy"
                    minDate={new Date("2020-01-17")}
                    maxDate={new Date(moment().tz("Asia/Bangkok"))}
                  />
                </div>
                {/* <div className="col-md-3">Selected Start Date: {startDate}</div> */}
                <div className="col-md-4">
                  <span className="text-primary">
                    {<IntlMessages id="pages.registeruser.enddate" />}{" "}
                  </span>
                  <DatePicker
                    customInput={<Input />}
                    selected={this.state.endDate}
                    onSelect={this.handleSelect}
                    onChange={this.handleChangeEndDate}
                    dateFormat="dd/MM/yyyy"
                    minDate={new Date("2020-01-17")}
                    maxDate={new Date(moment().tz("Asia/Bangkok"))}
                  />
                </div>
                <div className="col-md-3">
                  <center>
                    <Button
                      onClick={() => {
                        this.handleGetData();
                        // this.props.showLoader();
                        // this.props.getRegisterUserCountRequest(
                        //   this.state.startDateStr,
                        //   this.state.endDateStr
                        // );
                      }}
                      variant="contained"
                      color="primary"
                    >
                      {<IntlMessages id="pages.registeruser.button.getdata" />}
                    </Button>
                  </center>
                </div>
              </div>
            </CardBox>
          </div>
        </div>
        {loaderUsersByDealerByDate ? (
          <div className="loader-view">
            <CircularProgress />
          </div>
        ) : (
          this.showUsers(this.props)
        )}
        <NotificationContainer />
      </div>
    );
  }
}
const mapStateToProps = ({ users, settings }) => {
  //console.log('BBBBBB:' + state.dealercode);
  const {
    loaderUsersByDealer,
    usersByDealerItems,
    loaderUsersByDealerByDate,
    usersByDealerByDateItems,
  } = users;
  const { locale } = settings;
  return {
    loaderUsersByDealer,
    usersByDealerItems,
    loaderUsersByDealerByDate,
    usersByDealerByDateItems,
    locale: settings.locale,
  };
};

export default connect(mapStateToProps, {
  showLoaderUsersByDealer,
  getUsersByDealerRequest,
  showLoaderUsersByDealerByDate,
  getUsersByDefaultDealerByDateRequest,
})(UsersByDefaultDealer);
