import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';

const Users = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/user-list`} />
      <Route
        path={`${match.url}/user-list`}
        component={asyncComponent(() => import('./routes/UserList'))}
      />
      <Route
        path={`${match.url}/dealers`}
        component={asyncComponent(() => import('./routes/Dealers'))}
      />
      <Route
        path={`${match.url}/friendsdealers`}
        component={asyncComponent(() => import('./routes/FriendsDealers'))}
      />
      <Route
        path={`${match.url}/user-list-photo`}
        component={asyncComponent(() => import('./routes/UserListPhoto'))}
      />
      <Route
        path={`${match.url}/user-change-phone`}
        component={asyncComponent(() => import('./routes/ChangePhone'))}
      />
      <Route
        path={`${match.url}/second-hand-car`}
        component={asyncComponent(() => import('./routes/SecondHandCar'))}
      />
      <Route
        path={`${match.url}/search-user`}
        component={asyncComponent(() => import('./routes/SearchUser'))}
      />
      <Route
        path={`${match.url}/active-user`}
        component={asyncComponent(() => import('./routes/ActiveUsers'))}
      />
      {/* <Route
        path={`${match.url}/users-by-dealer`}
        component={asyncComponent(() => import('./routes/Dealers/routes/UsersByDealer'))}
      /> */}
      <Route
        component={asyncComponent(() =>
          import('app/routes/extraPages/routes/404')
        )}
      />
    </Switch>
  </div>
);

export default Users;
